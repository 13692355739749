/* eslint-disable @typescript-eslint/camelcase */
import { Arguments } from 'Library/arguments';
import { Resource } from 'Partials/showcase';

const getBoostedResourcesPixel = (resources: Resource[]): BoostedResourceData => {
    const resourceListDataPixel: BoostedResourceData = {
        day_7: [],
        day_7_14: [],
        p: [],
        gp: [],
    };

    resources.forEach((resource: Resource) => {
        const resourceId = resource.id;
        const pixel = resource.pixel;

        if (resourceId && pixel && resourceListDataPixel.hasOwnProperty(pixel)) {
            resourceListDataPixel[pixel].push(resourceId);
        }
    });

    return resourceListDataPixel;
};

export const getItemsIdForQueryString = (resources: Resource[] | false): string => {
    if (!resources) return '';

    const boostedResources = getBoostedResourcesPixel(resources);

    Object.keys(boostedResources).forEach((key: string) => {
        if (boostedResources[key].length > 0) {
            boostedResources[key] = boostedResources[key].join(',');
        } else {
            delete boostedResources[key];
        }
    });

    return Object.keys(boostedResources).length > 0 ? `&${Arguments.objectToString(boostedResources)}` : '';
};

export interface BoostedResourceData {
    day_7: string[];
    day_7_14: string[];
    p: string[]; // premium
    gp: string[]; // guesspayload
}
