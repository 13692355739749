import { q } from 'bobjoll/ts/library/dom';

document.addEventListener('keydown', (event: KeyboardEvent) => {
    if (!IS_MOBILE && event.key === 'k' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();
        const inputSelector = VIEW_TEMPLATE.match(/author/)
            ? '.author-search input'
            : VIEW_TEMPLATE.match(/home/)
            ? '#search-fake input'
            : '.search input';
        const inputElement = q(inputSelector) as HTMLFormElement | undefined;

        if (inputElement) {
            inputElement.focus();
        }
    }
});
