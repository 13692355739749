import { ww } from 'bobjoll/ts/library/dom';
import Masonry from 'masonry-layout';

export const initializeVerticalGrid = (showcase: HTMLElement, callback: () => void) => {
    ww.verticalGrid = new Masonry(showcase, {
        initLayout: false,
    });

    ww.verticalGrid.once('layoutComplete', function() {
        showcase.classList.add('showcase--completed');
        callback();
    });

    refreshVerticalGrid();
};

export const refreshVerticalGrid = () => {
    ww.verticalGrid.layout();
};
