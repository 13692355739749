import { FilterProperties } from '../FilterInputs';

const getFilterIdList = (filterObject: FilterProperties[], specificValue = '') => {
    const ids: string[] = [];

    filterObject && Object.keys(filterObject).forEach((name: string) => {
        const optionItemProperties = filterObject[name] as FilterProperties;
        if (optionItemProperties) {
            if (specificValue) {
                optionItemProperties.value === specificValue && ids.push(optionItemProperties.id);
            } else {
                ids.push(optionItemProperties.id);
            }
        }
    });

    return ids;
};

export default getFilterIdList;
