import { q } from 'bobjoll/ts/library/dom';
import { isAdBlockEnabled } from 'Library/helpers/adblock';
import Autopromo from 'Partials/autopromo';
import { refreshVerticalGrid } from 'Partials/showcase/showcase.vertical-grid';

import { AutopromoBannerID, AutopromoBannerIDforVerticalGrid } from '../autopromo';
import { showCampaignBanner } from '../campaign-php/campaign.helpers';
import { InsertOptions, PushAdItem, PushOptions } from './adServer.vm';
import { clearTargeting, setTargeting } from './components/adServer.components.targeting';

export const render = (options: PushOptions) => {
    const { ads, danger, disableClearTargeting = false, insert } = options;
    const promiseArr: Promise<Element>[] = [];

    if (!disableClearTargeting) {
        clearTargeting();
    }

    if (ads) {
        ads.forEach(ad => {
            const { target } = ad;
            const element = q(`.spr[data-id="${ad.id}"]`);

            if (target) {
                setTargeting(target);
            }

            if (!element && insert) {
                renderElement(ad.id, insert, danger);
            }

            const promise = refresh(ad);

            if (promise && promiseArr.indexOf(promise) < 0) {
                promiseArr.push(promise);
            }
        });
    }

    try {
        Promise.all(promiseArr).then(([...elements]) =>
            [...new Set(elements)].forEach(element => {
                if (element && element.dispatchEvent) {
                    element.dispatchEvent(new Event('resize'));
                }
            }),
        );
    } catch (err) { }
};

const renderElement = async (
    id: AutopromoBannerID | AutopromoBannerIDforVerticalGrid,
    insert: InsertOptions,
    danger: boolean,
): Promise<HTMLElement | void> => {
    const { selector, method, argument } = insert;
    const element = q(selector);
    const { default: template } = await import('Project/ts/templates/parts/ads.hbs');

    if (element) {
        element[method](
            argument,
            template({
                danger,
                fixedHeight: true,
                id,
            }) as string & Element,
        );
        const bannerElement = q(`.spr[data-id="${id}"], .detail__spr[data-id="${id}"]`);
        if (bannerElement) {
            return bannerElement;
        }
    }
};

const initializeAutopromo = (ad: PushAdItem, bannerElement: HTMLElement): void | Promise<Element> => {
    const { id, list } = ad;
    const forceOwnAd = Autopromo.get(list, id, true);
    const forceCampaign = showCampaignBanner() && IS_MOBILE && !FEATURE_MOBILE_ADS_BY_LANGUAGE;

    if (isAdBlockEnabled() || forceOwnAd || forceCampaign) {
        renderAutopromo(ad, bannerElement);
    }
};

export const renderAutopromo = (ad: PushAdItem, bannerElement: HTMLElement): void | Promise<Element> => {
    const banner = Autopromo.get(ad.list, ad.id);
    const bannerContainerElement = q(`.container-fluid`, bannerElement);

    if (banner && bannerContainerElement) {
        const { ga4Name } = banner;
        return new Promise(resolve => {
            const showcaseElement = bannerElement.parent('.showcase');
            const sponsorElement = bannerContainerElement.parent('.spr');
            const { callback, template, globalVariables } = banner;

            template().then((module: any) => {
                const { default: template = module } = module;

                bannerContainerElement.insertAdjacentHTML(
                    'beforebegin',
                    template({
                        ACCOUNTS_BASE_URL,
                        ESSENTIAL_COUNTRY,
                        LANGUAGE,
                        MY_SUBSCRIPTIONS_URL,
                        NUM_RESOURCES_PREMIUM,
                        PRICING_URL,
                        URL_STATIC_IMG,
                        ...globalVariables,
                        ...ad.target,
                    }),
                );

                if (ga4Name) {
                    bannerContainerElement.dataset.autopromoName = ga4Name;
                }

                if (sponsorElement) {
                    sponsorElement.classList.add('spr--internal');
                }

                if ('function' === typeof callback) {
                    callback();
                }

                if (showcaseElement) {
                    resolve(showcaseElement);
                }

                bannerContainerElement.classList.add('hide');
            });
        });
    } else if (bannerElement) {
        bannerElement.classList.add('hide');
        if (FEATURE_VERTICAL_GRID_BY_LANGUAGE) {
            bannerElement.remove();
            refreshVerticalGrid();
        }
    }
};

const refresh = (ad: PushAdItem) => {
    const { id } = ad;
    const bannerElement = q(`.spr[data-id="${id}"], .detail__spr[data-id="${id}"]`);

    if (bannerElement) {
        return initializeAutopromo(ad, bannerElement);
    }
};
