import { q } from "bobjoll/ts/library/dom";
import Scroll from "Project/ts/library/scroll";

import { isLandingPaid } from "./landing-paid-constants";

export const addScrollIntoLandingPaidView = () => {
    if (isLandingPaid) {
        const seePlansBtn = q('.landing-paid #see-plans-link');
        const elementheaderPlans = q('#header-plans');

        if (seePlansBtn && elementheaderPlans) {
            seePlansBtn.addEventListener('click', function () {
                try {
                    elementheaderPlans.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'start',
                    });
                } catch (error) {
                    Scroll.to(elementheaderPlans, 500, 'linear');
                }
            });
        }
    }
}