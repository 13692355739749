import { q, ww } from 'bobjoll/ts/library/dom';
import { isSearchByImageEnabled } from 'Partials/search-by-image/image/searchimage.get-image';
import { numberNormalizer } from 'Project/ts/library/helpers';

import concatUUIDSearchTracker from '../concatUUIDSearchTracker';
import FilterEvents from '../FilterEvents';
import { FilterKeys, ValidFilterKey } from '../FilterInputs';
import getFilterIdList from '../getFilterIdList';
import getFilterValue from '../getFilterValue';

class SearchPagination {
    private static fontsView = 'fontsView';
    private static pageExceptionsInURL = ['keyword', 'category', 'popular', 'author', SearchPagination.fontsView];
    private static exceptionSearchInAuthorPage = '/search';
    private static pageKeyName = 'page';
    private static inputElement: HTMLInputElement;
    private static buttonNextElement: HTMLElement;
    private static buttonPrevElement: HTMLElement;
    private static filterKeysStatus: FilterKeys;

    constructor({
        filterKeysStatus = ww.Filters,
        buttonNextSelector = '.pagination__next',
        buttonPrevSelector = '.pagination__prev',
        inputSelector = '.pagination__input input',
        paginationSelector = '.pagination__menu',
    }: SearchPaginationConfig = {}) {
        const tabElement = q(paginationSelector) as HTMLElement;
        if (!tabElement) return;

        const inputElement = q(inputSelector, tabElement) as HTMLInputElement | undefined;
        if (!inputElement) return;

        const buttonNextElement = q(buttonNextSelector) as HTMLElement | undefined;
        const buttonPrevElement = q(buttonPrevSelector) as HTMLElement | undefined;

        if (buttonNextElement) {
            SearchPagination.buttonNextElement = buttonNextElement;
        }

        if (buttonPrevElement) {
            SearchPagination.buttonPrevElement = buttonPrevElement;
        }

        SearchPagination.inputElement = inputElement;
        SearchPagination.filterKeysStatus = filterKeysStatus;

        this.addEvents();
    }

    private static get maxPagination() {
        return numberNormalizer(SearchPagination.inputElement.max);
    }

    private addEvents() {
        SearchPagination.inputElement.addEventListener('keyup', function(this: HTMLInputElement, event: KeyboardEvent) {
            if ('Enter' !== event.code) return;
            SearchPagination.changePageValue(this, numberNormalizer(this.value));
        });

        [SearchPagination.buttonNextElement, SearchPagination.buttonPrevElement].forEach((item: HTMLAnchorElement) => {
            if (!item) return;

            item.href = concatUUIDSearchTracker(item.href);

            if (isSearchByImageEnabled()) {
                item.addEventListener('click', function(this: HTMLInputElement, event: Event) {
                    event.preventDefault();

                    const { page } = getFilterValue('page');
                    let currentPage = parseInt(page);

                    if (item === SearchPagination.buttonNextElement) {
                        currentPage += 1;
                    } else {
                        currentPage -= 1;
                    }

                    if (currentPage > SearchPagination.maxPagination || currentPage <= 0) return;

                    SearchPagination.changePageValue(item, currentPage);
                });
            }
        });
    }

    private static changePageValue(element: HTMLElement, page: number) {
        if (page <= 0) page = 1;
        if (page > SearchPagination.maxPagination) page = SearchPagination.maxPagination;

        const pageKey = SearchPagination.pageKeyName as ValidFilterKey;
        const pageProperties = SearchPagination.filterKeysStatus[pageKey];
        const pageKeyId = pageProperties && getFilterIdList(pageProperties)[0];
        if (!pageKeyId) return;

        const inputPageFilter = document.getElementById(pageKeyId) as HTMLInputElement;

        if (inputPageFilter) {
            const currentPage = inputPageFilter.value || '1';
            inputPageFilter.value = page.toString();

            if (SearchPagination.exceptionInURL()) {
                const newPage = page > 1 ? `/${page}` : '';
                let path = window.location.pathname;

                if (parseInt(currentPage) > 1) {
                    path = path.replace(/\/[^\/]*$/, '');
                }

                const url = concatUUIDSearchTracker(`${window.location.origin}${path}${newPage}`);

                window.location.href =
                    VIEW_TEMPLATE === SearchPagination.fontsView ? SearchPagination.addQueryParamToFontsView(url) : url;
            } else {
                new FilterEvents({
                    filterKey: SearchPagination.pageKeyName as ValidFilterKey,
                    filterKeysStatus: SearchPagination.filterKeysStatus,
                    optionItem: element,
                    resetFilters: false,
                    concatUUIDSearch: true,
                });
            }
        }
    }

    private static exceptionInURL() {
        let returnToURL = false;

        if (VIEW_TEMPLATE === SearchPagination.fontsView) return true;

        SearchPagination.pageExceptionsInURL.forEach(landing => {
            if (document.body.dataset.hbsTemplate?.includes(landing)) returnToURL = true;
        });

        if (
            document.body.dataset.hbsTemplate === 'author' &&
            window.location.pathname.indexOf(SearchPagination.exceptionSearchInAuthorPage) >= 0
        ) {
            returnToURL = false;
        }

        return returnToURL;
    }

    private static addQueryParamToFontsView(url: string): string {
        const fontsType = q('.filter-sidebar .details-content input[checked]') as HTMLInputElement;

        if (fontsType && fontsType?.value !== 'all') {
            return (url += `?type=${fontsType.value}`);
        }

        return url;
    }
}

interface SearchPaginationConfig {
    buttonNextSelector?: string;
    buttonPrevSelector?: string;
    filterKeysStatus?: FilterKeys;
    inputSelector?: string;
    paginationSelector?: string;
}

export default SearchPagination;
