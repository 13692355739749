// import { detailGalleryCenter } from './detail/events';
import { q } from 'bobjoll/ts/library/dom';
import { Settings } from 'bobjoll/ts/library/settings';
import { isAdBlockEnabled } from 'Library/helpers/adblock';
import { triggerWindowResize } from 'Library/helpers/resize';
import Autopromo, {
    AutopromoBannerID,
    AutopromoBannerIDforVerticalGrid,
    AutopromoBannerSlot,
} from 'Partials/autopromo';
import ga from 'Partials/tracker';
import { UserHelpers } from 'Partials/user';
import template from 'Project/ts/templates/parts/ads.hbs';

declare let slots: any;

interface AdsBannerData {
    id: AutopromoBannerID | AutopromoBannerIDforVerticalGrid;
    list: AutopromoBannerSlot;
    target?: { [name: string]: string | number };
}

interface SettingsPushAds {
    ads: AdsBannerData[];
    danger: boolean;
    insert?: {
        selector: string;
        method: 'insertAdjacentHTML' | 'insertAdjacentElement';
        argument: 'beforebegin' | 'afterbegin' | 'beforeend' | 'afterend';
    };
    lazyload?: boolean;
}

export class Ads {
    private static initialized1 = false;
    private static initialized2 = false;
    private static retry = 0;
    private static retryMax = 10;
    private static template = template;

    private static initialize() {
        if (Ads.initialized1 && Ads.initialized2) return;
        try {
            if (!Ads.initialized1) {
                googletag.pubads().addEventListener('slotRenderEnded', e => Ads.slotRenderEnded(e.slot));
                Ads.initialized1 = true;
            }
            if (!Ads.initialized2) {
                pbjsYLHH.onEvent('bidWon', function(data) {
                    setTimeout(function() {
                        const adUnitCode = data.adUnitCode;
                        googletag
                            .pubads()
                            .getSlots()
                            .forEach(slot => {
                                if (slot.getAdUnitPath() !== adUnitCode) return;
                                Ads.slotRenderEnded(slot);
                            });
                    }, 50);
                });
                Ads.initialized2 = true;
            }
        } catch (err) {}
    }
    private static pushOwnAds(
        slot: AutopromoBannerSlot,
        id: AutopromoBannerID | AutopromoBannerIDforVerticalGrid,
        target?: { [name: string]: string | number },
    ) {
        const banner = document.getElementById(id);
        const bannerData = Autopromo.get(slot, id);
        if (banner) {
            const sponsor = (banner.parent('.spr') || banner.parent('.detail__spr')) as HTMLElement | undefined;
            const showcaseElement = banner.parent('.showcase');
            if (sponsor && !bannerData) {
                sponsor.classList.add('hide');
            }
            if (sponsor && bannerData) {
                const containerElement = q('.container-fluid', sponsor);
                if (containerElement) {
                    sponsor.dataset.name = bannerData.name;
                    bannerData.template().then((module: any) => {
                        const { default: template = module } = module;
                        containerElement.insertAdjacentHTML(
                            'beforeend',
                            template({
                                ACCOUNTS_BASE_URL,
                                ESSENTIAL_COUNTRY,
                                LANGUAGE,
                                MY_SUBSCRIPTIONS_URL,
                                NUM_RESOURCES_PREMIUM,
                                PRICING_URL,
                                URL_STATIC_IMG,
                                ...(bannerData.globalVariables || {}),
                                ...(target || {}),
                            }),
                        );
                        if (bannerData.event && bannerData.event.impression) {
                            ga.apply(null, bannerData.event.impression);
                        }
                        banner.classList.add('hide');
                        sponsor.classList.remove('hide');
                        sponsor.classList.add('spr--internal');
                        if (showcaseElement) {
                            showcaseElement.dispatchEvent(new Event('resize'));
                        }
                        if (bannerData.callback) bannerData.callback();
                    });
                }
            }
        }
    }
    private static insert(id: string, selector: string, method: string, argument: string): HTMLElement | null {
        const element = q(selector);
        if (element) {
            element[method](
                argument,
                Ads.template({
                    id: id,
                }),
            );
        }
        return document.getElementById(id);
    }
    private static slotRender(ad: AdsBannerData, danger = false) {
        if (UserHelpers.isPremium() || UserHelpers.isEssential()) return;

        const slot = slots[ad.list];
        const forceOwnAd = Autopromo.get(ad.list, ad.id, true);
        const element = document.getElementById(ad.id);

        if (element) {
            if (googletag && slot && !isAdBlockEnabled() && !danger && !forceOwnAd) {
                try {
                    googletag.pubads().clearTargeting();
                    if (ad.target) {
                        Object.keys(ad.target).forEach((key: string) => {
                            if (ad.target) {
                                const value = ad.target[key];

                                googletag.pubads().setTargeting(key, value.toString());
                            }
                        });
                    }
                    googletag.display(ad.id);
                    Ads.initialize();
                    googletag.pubads().refresh([slot]);
                } catch (err) {
                    Ads.pushOwnAds(ad.list, ad.id, ad.target);
                }
            } else {
                Ads.pushOwnAds(ad.list, ad.id, ad.target);
            }

            element.classList.add('spr--rendered');
        }
    }
    private static slotPreRender(settings: SettingsPushAds) {
        settings.ads.forEach(ad => {
            let element = document.getElementById(ad.id);
            if (!element && settings.insert) {
                element = Ads.insert(ad.id, settings.insert.selector, settings.insert.method, settings.insert.argument);
            }
        });
    }
    private static pushStandard(settings: SettingsPushAds) {
        Ads.retry = 0;

        settings.ads.forEach(ad => Ads.slotRender(ad, settings.danger));
        triggerWindowResize();
    }

    public static inview(element: HTMLElement): boolean | void {
        if (element) {
            const { top, bottom } = element.getBoundingClientRect();
            return top < window.innerHeight && bottom >= 0;
        }
    }
    public static slotRenderInView(settings: SettingsPushAds) {
        settings.ads.forEach(ad => {
            const element =
                document.getElementById(ad.id) || document.getElementById(ad.id.replace('div-gpt-ad', 'ad'));
            if (element && Ads.inview(element) && !element.classList.contains('spr--rendered')) {
                Ads.slotRender(ad, settings.danger);
            }
        });
    }
    public static slotRenderEnded(slot: googletag.Slot) {
        const slotElement = document.getElementById(slot.getSlotElementId() || '');
        if (slotElement) {
            const sponsorElement = slotElement.parent('.spr');
            const showcaseElement = slotElement.parent('.showcase');
            if (sponsorElement) {
                sponsorElement.classList.remove('hide');
            }
            if (showcaseElement) {
                showcaseElement.dispatchEvent(new Event('resize'));
            }
        }
    }
    public static push(settings: SettingsPushAds) {
        if (UserHelpers.isPremium() || (!FEATURE_MOBILE_ADS_BY_LANGUAGE && window.innerWidth < parseFloat(Settings.breakpoints.sm))) return;

        Ads.slotPreRender(settings);

        if ((!googletag || (googletag && !googletag.display)) && document.readyState !== 'complete') {
            Ads.retry++;
            if (Ads.retry <= Ads.retryMax) {
                setTimeout(() => Ads.push(settings), 250);
            } else {
                Ads.retry = 0;
            }
            return;
        }

        Ads.pushStandard(settings);
    }
}
