import { q, qq, ww } from 'bobjoll/ts/library/dom';

import FilterEvents from '../FilterEvents';
import { FilterKeys, ValidFilterKey } from '../FilterInputs';

class FilterTabCounters {
    private formatKeyName = 'format';
    private filterKeysStatus: FilterKeys;
    private tabInputElements: HTMLElement[];

    constructor({
        filterKeysStatus = ww.Filters,
        tabInputs = '.tab-search__info--link',
        tabSelector = '.tab-counters',
    }: FilterTabCountersConfig = {}) {
        const tabElement = q(tabSelector) as HTMLElement | undefined;
        if (!tabElement) return;

        const tabInputElements = qq(tabInputs, tabElement) as HTMLElement[] | undefined;
        if (!tabInputElements) return;

        this.tabInputElements = tabInputElements;
        this.filterKeysStatus = filterKeysStatus;

        this.addEvents();
    }

    private addEvents() {
        this.tabInputElements.forEach((item: HTMLElement) => {
            item.addEventListener('click', () => {
                new FilterEvents({
                    filterKey: this.formatKeyName as ValidFilterKey,
                    filterKeysStatus: this.filterKeysStatus,
                    optionItem: item,
                    newSearch: true,
                });
            });
        });
    }
}

interface FilterTabCountersConfig {
    filterKeysStatus?: FilterKeys;
    tabInputs?: string;
    tabSelector?: string;
}

export default FilterTabCounters;
