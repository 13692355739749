/* eslint-disable @typescript-eslint/camelcase */
import { q, qq, ww } from 'bobjoll/ts/library/dom';
import getFilterValue from 'Partials/filters-v2/getFilterValue';
import { UserHelpers } from 'Partials/user';
import { Adobe } from 'Project/ts/common/adobe';
import { triggerWindowResize } from 'Project/ts/library/helpers/resize';
import Scroll from 'Project/ts/library/scroll';
import * as AdobeTracker from 'Project/ts/library/trackerSend/trackerSend.adobestock';
import { SponsorAdsInsertSettings } from 'Project/ts/partials/sponsor-ads/sponsor-ads.types';

import { __ } from '../../language';

export default class NoResults {
    private static readonly limit = 80;
    private static getTemplate = () =>
        import('Project/ts/templates/parts/showcase_shutterstock_item.hbs').then(mod => mod.default);
    private static readonly getTemplateLoading = () =>
        import('Project/ts/templates/parts/showcase_shutterstock_loading.hbs').then(mod => mod.default);
    private static readonly defaults: SponsorAdsInsertSettings = {
        containerClass: 'shutterstock--no-title shutterstock--no-results',
        insert: {
            argument: 'beforeend',
            selector: '#main .no-results section',
        },
        override: {
            limit: NoResults.limit,
            referer_page: 'empty',
        },
        ignorePremium: true,
    };

    private static scrollLocked = true;
    private showcase: HTMLElement | null;
    private options: SponsorAdsInsertSettings;
    public static instance?: NoResults;
    public page = 1;

    constructor() {
        if (!NoResults.instance) {
            NoResults.instance = this;
            Scroll.add(NoResults.scroll);
            this.options = {
                forzeNoResults: true,
                randomClasses: false,
                hideRowClasses: false,
                ...NoResults.defaults,
                callback: () => NoResults.insertCallback(),
            };

            const getAllFilterValues = getFilterValue(Object.keys(ww.FiltersEnabled));

            this.options.override = {
                ...this.options.override,
                ...getAllFilterValues,
                page: this.page,
            };

            NoResults.setup();
        }
    }

    private static async data(options: SponsorAdsInsertSettings) {
        const { SponsorAds } = await import(
            /* webpackChunkName: "sponsor-invent" */ 'Project/ts/partials/sponsor-ads/sponsor-ads'
        );

        const response = await SponsorAds.getData(options);
        const globalVariables = SponsorAds.getGlobalVariables(options);
        return {
            ...response,
            ...globalVariables,
        };
    }

    private static sendTrackEvent() {
        const viewResources = qq('.shutterstock__item');
        const { query } = getFilterValue('query');

        const resources = qq('.shutterstock__item').filter(
            resourceElement => viewResources.indexOf(resourceElement) == -1,
        );

        const resourceIds = resources.map(resource => {
            const imgResource = q('img', resource);
            if (imgResource) {
                imgResource.getAttribute('alt');
            }
        });

        if (NoResults.instance && resourceIds.length > 0) {
            AdobeTracker.adobeRender({
                id_list: resourceIds.join(','),
                page: NoResults.instance.page,
                term: query || '',
            });
        }
    }

    private static async insert(element: HTMLElement) {
        if (!NoResults.instance || !NoResults.instance.showcase) return;
        const { query } = getFilterValue('query');
        const viewResources = qq('.shutterstock__item');
        let data: any = {};
        NoResults.scrollLocked = true;
        NoResults.instance.showcase.classList.add('shutterstock--loading');
        if (NoResults.instance.getPathValue('options.override.page')) {
            NoResults.instance.options.override!.page = NoResults.instance.page;
        }
        try {
            data = await NoResults.data(NoResults.instance.options);

            const template = await NoResults.getTemplate();

            element.insertAdjacentHTML(
                'afterend',
                template({
                    shutterstock: data,
                    user: {
                        id: UserHelpers.id(),
                    },
                    URL_STATIC_IMG: URL_STATIC_IMG,
                    ACCOUNTS_BASE_URL: ACCOUNTS_BASE_URL,
                }),
            );

            triggerWindowResize();
        } catch (error) {
            console.error(error);
        }

        if (!FEATURE_SPONSOR_SHUTTERSTOCK && !FEATURE_SPONSOR_ISTOCK) {
            const resources = qq('.shutterstock__item').filter(
                resourceElement => viewResources.indexOf(resourceElement) == -1,
            );

            resources.forEach(resourceElement =>
                ['click', 'auxclick'].forEach(eventType => {
                    resourceElement.addEventListener(eventType, () => {
                        const options = {
                            term: query || '',
                        };
                        const imgResource = q('img', resourceElement);
                        const itemId = (imgResource && imgResource.getAttribute('alt')) || 'nn';

                        options['adobe_item_id'] = itemId;

                        AdobeTracker.adobeItemClick(options);
                    });
                }),
            );
        }

        NoResults.instance.showcase.classList.remove('shutterstock--loading');
        NoResults.insertCallback();
        NoResults.instance.page++;
    }

    private static insertCallback() {
        NoResults.setClasses();

        NoResults.scrollLocked = false;

        setTimeout(() => Lazyload.run(), 250);
    }

    private static scroll() {
        if (NoResults.instance && !NoResults.instance.showcase) {
            NoResults.instance.showcase = q('#main .no-results .shutterstock');
        }

        const testABscrollEnabled = optimizeTests['abd404Scroll'] === 'b';
        const shutterstockItems =
            testABscrollEnabled && NoResults.instance && NoResults.instance.showcase
                ? qq('.shutterstock__item', NoResults.instance.showcase).length
                : 0;

        if (
            !NoResults.instance ||
            !NoResults.instance.showcase ||
            NoResults.scrollLocked ||
            NoResults.instance.page > 10 ||
            (testABscrollEnabled && shutterstockItems === 80)
        )
            return;

        const child = qq('.shutterstock__item', NoResults.instance.showcase).pop();

        if (child && child.style) {
            const childBond = child.getBoundingClientRect();
            if (childBond.top < window.innerHeight) {
                NoResults.insert(child);
            }
        }
    }

    private static async setup() {
        if (
            FEATURE_DISABLE_AFFILIATE_BY_COUNTRY ||
            FEATURE_DISABLE_AFFILIATE_404_BY_COUNTRY ||
            UserHelpers.isPremium() ||
            UserHelpers.isEssential()
        )
            return;

        !SHOW_CC_BANNER && !FEATURE_SPONSOR_SHUTTERSTOCK && !FEATURE_SPONSOR_ISTOCK && NoResults.sendTrackEvent();
        const { SponsorAds } = await import(
            /* webpackChunkName: "sponsor-invent" */ 'Project/ts/partials/sponsor-ads/sponsor-ads'
        );

        NoResults.instance &&
            SponsorAds.insert({
                ...NoResults.instance.options,
                forceCompleted: false,
                randomClasses: false,
                hideRowClasses: false,
                callback: async () => {
                    const child = qq('#main .shutterstock__item').pop();
                    const { query } = getFilterValue('query');
                    if (child) {
                        const template = await NoResults.getTemplateLoading();
                        child.insertAdjacentHTML(
                            'afterend',
                            template({
                                limit: NoResults.limit,
                                shutterstock: {
                                    classes: {
                                        loadingElement: 'shutterstock__loading',
                                    },
                                },
                            }),
                        );
                    }

                    if (!FEATURE_SPONSOR_SHUTTERSTOCK) {
                        const adobeSponsor = document.getElementById('adobe-sponsor');
                        adobeSponsor &&
                            adobeSponsor.addEventListener('click', (e: Event) => {
                                window.open(Adobe.helpers.get404Url(), '_blank');

                                const link =
                                    e.target instanceof HTMLElement && e.target.id === 'adobe-sponsor-logo'
                                        ? 'logo'
                                        : 'promo';
                                AdobeTracker.adobeEventClick({
                                    term: query || '',
                                    link,
                                });
                            });
                    }

                    NoResults.insertCallback();
                    if (NoResults.instance) {
                        NoResults.instance.showcase = q('#main .no-results .showcase-spr');
                        NoResults.instance.page++;
                    }

                    setTimeout(() => {
                        if (child && qq('#main .no-results .showcase-spr .shutterstock__item').length <= 6) {
                            NoResults.insert(child);
                        }
                    }, 500);
                },
            });
    }

    private static setClasses() {
        const noResultsElement = q('.no-results') as HTMLElement | undefined;

        if (noResultsElement) {
            noResultsElement.classList.add('sponsor--adobe');
        }
    }

    public destroy(keep = false) {
        Scroll.remove(NoResults.scroll);

        if (this.showcase && this.showcase.parentElement && !keep) {
            this.showcase.parentElement.removeChild(this.showcase);
        }

        delete NoResults.instance;
    }
}
