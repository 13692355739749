import { q, qq } from 'bobjoll/ts/library/dom';
import { UserHelpers } from 'Partials/user';
import xhr from 'Partials/xhr';

import { __ } from '../language';

const collectionTextButton = (active = false) => {
    return active
        ? FEATURE_NEW_COPIES_COLLECTIONS_BY_COUNTRY
            ? __('Added to collection')
            : __('Collected')
        : FEATURE_NEW_COPIES_COLLECTIONS_BY_COUNTRY
        ? __('Add to collection')
        : __('Collect');
};

export const getResourcesInCollection = (resources: string[]) => {
    return xhr('/xhr/user/resources_in_collection?resources=' + resources.join(), {
        withCredentials: true,
        headers: {
            'X-CSRF-TOKEN': CSRF_TOKEN,
            'X-Requested-With': 'XMLHttpRequest',
        },
        method: 'GET',
    });
};

export const setResourcesInCollection = async () => {
    if (await UserHelpers.isLogged(false)) {
        const request = await getResourcesInCollection(
            qq('.collection-add').reduce((acc, button) => {
                const element = <HTMLElement | undefined>button.parent('[data-id]');

                updateCollectionButtonState(
                    false,
                    button as HTMLButtonElement,
                    !!element?.classList.contains('detail'),
                );

                if (element && element.dataset.id && 0 > acc.indexOf(element.dataset.id)) {
                    acc.push(element.dataset.id);
                }

                return acc;
            }, <string[]>[]),
        );

        const { data: idList = [], success } = request;

        if (success) {
            idList.forEach((id: string) =>
                qq(`.detail[data-id="${id}"], .showcase__item[data-id="${id}"]`).forEach(resourceElement => {
                    const collectionAddButton = qq('.collection-add', resourceElement) as HTMLElement[];

                    collectionAddButton.length > 0 &&
                        collectionAddButton.forEach((button: HTMLElement) =>
                            updateCollectionButtonState(true, button, !!resourceElement?.classList.contains('detail')),
                        );
                }),
            );
        }
    }
};

const updateCollectionButtonState = (active: boolean, button: HTMLElement, isDetail: boolean) => {
    button.classList[active ? 'add' : 'remove']('active');
    let componentToChangeText = null;

    if (isDetail) {
        componentToChangeText = q('span', button);
    } else {
        button.classList[active ? 'add' : 'remove']('icon--create-folder-filled');
        button.classList[!active ? 'add' : 'remove']('icon--create-folder');

        componentToChangeText = q('.tooltip__content', button) || button;
    }

    if (componentToChangeText) {
        componentToChangeText.textContent = collectionTextButton(active);
    }
};
