import { Google,GoogleLegacy  } from 'Partials/adServer';

type GoogleModule = typeof GoogleLegacy | typeof Google;

let AdServer: GoogleModule;

if (FEATURE_ENABLE_SSEO) {
    AdServer = Google;
} else {
    AdServer = GoogleLegacy;
}

export default AdServer;
