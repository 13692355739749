/* eslint-disable @typescript-eslint/camelcase */
import { q, qq } from 'bobjoll/ts/library/dom';
import { Search } from 'Partials/search/controller';

import { ga4 } from './ga4.api';

const tagEvent = {
    event_name: 'related_tags',
    link_text: 'tag text',
    link_url: 'url',
    search_term: 'query',
};

export const tagEventsGA4 = () => {
    qq('#related-carousel li, #related-link')?.forEach((item: HTMLLIElement) => {
        const anchor = q('a', item) as HTMLAnchorElement || undefined;
        anchor?.addEventListener('click', () => {
            const options = tagEvent;
            options.event_name = item.classList.contains('link-type') ? 'type_resource_link' : 'related_tags';
            options.link_text = anchor.textContent?.trim() ?? '';
            options.link_url = anchor.href;
            options.search_term = Search.getValue();

            if (anchor.dataset.tagOrder) {
                options['tag_position'] = anchor.dataset.tagOrder;
            }

            ga4(options);
        });
    });
};
