import displaySection from '../displaySection';
import { FilterEventConfig } from '../FilterEvents';
import { FilterKeys, FilterProperties, ValidFilterKey } from '../FilterInputs';
import getFilterIdList from '../getFilterIdList';
import mapFiltersToUpdate from '../mapFiltersToUpdate';

class ConditionAI {
    private readonly aiKeyName = 'ai';
    private readonly aiModelKeyName = 'ai_model';
    private readonly promptKeyName = 'prompt';
    private filterKeyActivated: ValidFilterKey;
    private filterKeysStatus: FilterKeys;
    private optionItemActivated: HTMLElement;

    constructor(eventConfig: FilterEventConfig) {
        this.filterKeyActivated = eventConfig.filterKey;
        this.filterKeysStatus = eventConfig.filterKeysStatus;
        this.optionItemActivated = eventConfig.optionItem;

        this.apply();
    }

    private get aiFilters(): FilterProperties[] {
        return this.filterKeysStatus[this.aiKeyName];
    }

    private get aiModelFilters(): FilterProperties[] {
        return this.filterKeysStatus[this.aiModelKeyName];
    }

    private get promptFilters(): FilterProperties[] {
        return this.filterKeysStatus[this.promptKeyName];
    }

    private apply() {
        if (!FEATURE_FILTER_AI) return;

        const fieldName = this.filterKeyActivated.toString();
        const fieldValue = this.optionItemActivated.getAttribute('value') || '';
        const aiKeyIds = getFilterIdList(this.aiFilters);
        const aiModelKeyIds = getFilterIdList(this.aiModelFilters);
        const promptKeyIds = getFilterIdList(this.promptFilters);

        let disableFilters: string[] = [];
        let enableFilters: string[] = [];

        if (fieldName.match(/type/)) {
            if (!fieldValue || fieldValue.match(/photo/) || fieldValue.match(/vector/) || fieldValue.match(/psd/)) {
                displaySection(this.aiKeyName);
                displaySection(this.aiModelKeyName);
                displaySection(this.promptKeyName);
            } else {
                disableFilters = [...aiKeyIds, ...aiModelKeyIds, ...promptKeyIds];
                displaySection(this.aiKeyName, false);
                displaySection(this.aiModelKeyName, false);
                displaySection(this.promptKeyName, false);
            }
        }

        if (fieldName.match(/ai/)) {
            if (fieldValue.match(/exclude/)) {
                disableFilters = [...aiModelKeyIds, ...promptKeyIds];
            }
        }

        if (fieldName.match(/ai_model/) || fieldName.match(/prompt/)) {
            disableFilters = ['filter-ai-exclude'];
            enableFilters = ['filter-ai-only'];
        }

        mapFiltersToUpdate(disableFilters, false);
        mapFiltersToUpdate(enableFilters);
    }
}

export default ConditionAI;
