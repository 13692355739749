import { Autocomplete } from 'Project/ts/library/autocomplete';
import { qq } from 'bobjoll/ts/library/dom';
import { replaceQuery } from './searchBar.helpers';
import xhr from 'Service/xhr';
const eventHandlerSearchAutocompleteSource = async (query: string) => {
    let data;
    if (query.length > 1) {
        data = await xhr.get(`${BASE_URL}xhr/autocomplete?q=${query}`, {
            headers: {
                'X-CSRF-TOKEN': CSRF_TOKEN,
                'X-Requested-With': 'XMLHttpRequest',
            },
        });
        const { authors } = data;
        replaceQuery(data, 'keywords', query);
        if (authors) {
            replaceQuery(data, 'authors', query);
        }
        return data;
    }
};

const autocompleteConfig = {
    fields: qq('input[name="query"]') as HTMLInputElement[],
    delay: 400,
    minChars: 2,
    source: eventHandlerSearchAutocompleteSource,
};

export const searchAutocomplete = new Autocomplete(autocompleteConfig);
