import { qq, ww } from 'bobjoll/ts/library/dom';
import { isSearchByImageEnabled } from 'Partials/search-by-image/image/searchimage.get-image';

import FilterEvents from '../FilterEvents';
import { FilterKeys, FilterProperties, ValidFilterKey } from '../FilterInputs';
import getFilterIdList from '../getFilterIdList';
import mapFiltersToUpdate from '../mapFiltersToUpdate';

class SearchVerticalLink {
    private static readonly typeKeyName = 'type';
    private static verticalLinks: HTMLElement[];
    private static filterKeysStatus: FilterKeys;

    constructor({
        filterKeysStatus = ww.Filters,
        verticalLinkSelector = '.tag-slider--item.link-type > a',
    }: SearchVerticalLinkConfig = {}) {
        if (!isSearchByImageEnabled()) return;

        const verticalLinks = qq(verticalLinkSelector);

        if (verticalLinks.length === 0) return;

        SearchVerticalLink.verticalLinks = verticalLinks;
        SearchVerticalLink.filterKeysStatus = filterKeysStatus;

        this.addEvents();
    }

    private static get typeFilters(): FilterProperties[] {
        return SearchVerticalLink.filterKeysStatus[SearchVerticalLink.typeKeyName];
    }

    private addEvents() {
        SearchVerticalLink.verticalLinks.forEach(verticalLink => {
            verticalLink.addEventListener('click', function(this: HTMLInputElement, event: Event) {
                event.preventDefault();

                const typeKeyIds = getFilterIdList(SearchVerticalLink.typeFilters);
                const enableType = this.dataset.type;

                const disableFilters: string[] = typeKeyIds;
                const enableFilters: string[] = [`filter-type-${enableType}`];

                mapFiltersToUpdate(disableFilters, false);
                mapFiltersToUpdate(enableFilters);

                new FilterEvents({
                    filterKey: SearchVerticalLink.typeKeyName as ValidFilterKey,
                    filterKeysStatus: SearchVerticalLink.filterKeysStatus,
                    optionItem: verticalLink,
                    resetFilters: true,
                });
            });
        });
    }
}

interface SearchVerticalLinkConfig {
    verticalLinkSelector?: string;
    filterKeysStatus?: FilterKeys;
}

export default SearchVerticalLink;
