function initializeUserModules() {
    import('User/index').then(modules => {
        Object.keys(modules.User).forEach(moduleName => {
            modules.User[moduleName].initialize();
            gr.triggerReady();
        });
    });
}

function destroyUserModules() {
    import('User/index').then(modules => {
        Object.keys(modules.User).forEach(moduleName => {
            modules.User[moduleName].destroy();
        });
    });
}

if (gr.isLogged()) initializeUserModules();

gr.addEventListener('gr:login', initializeUserModules);
gr.addEventListener('gr:login-remember', initializeUserModules);
gr.addEventListener('gr:logout', destroyUserModules);
