import { q } from 'bobjoll/ts/library/dom';
import { isAdBlockEnabled } from 'Library/helpers/adblock';

import { hideAdServer } from './adServer.helpers';
import { render } from './adServer.model';
import { PushOptions } from './adServer.vm';

export const push = (options: PushOptions) => {
    if (hideAdServer()) return;

    if (FEATURE_DISABLE_ADS) {
        if (!isAdBlockEnabled()) return;

        options.ads.forEach(banner => q(`.detail__spr[data-id="${banner.id}"]`)?.classList.remove('spr--force-hide'));
    }

    render(options);
};
