import { globalMiddleware } from './abandonedCart.middleware';

export const getImpData = function() {
    const code = 'RTSEPFP';
    const percentage = 20;
    return {
        code,
        title: `%s${percentage}%s%% %soff%s for all %sFreepik plans%s`,
        url: `${PRICING_URL}?coupon=${code}&origin=freepik_web`,
        percentage,
    };
};

export const getData = function() {
    return globalMiddleware.getData(getImpData());
};
