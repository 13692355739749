import 'Variables';
import 'Project/ts/partials/events/gtm.initialize';

import { delegate, qq } from 'bobjoll/ts/library/dom';
import { q } from 'bobjoll/ts/library/gr/dom.q';
import accordion from 'bobjoll/ts/partials/accordion-v1.0';
import Copy from 'BobjollCopy';
import delay from 'Library/delay';
import { abandonedCart } from 'Partials/abandonedCart';
import globalCampaign from 'Partials/campaign-php';
import { initializeCollectionEvents } from 'Partials/collections/collections-pixel-events';
import { initializeMenu } from 'Partials/header/header.component';
import navigationHistory from 'Partials/navigation-history';
import notify from 'Partials/notify';
import { Search } from 'Partials/search/controller';
import Sponsor from 'Partials/sponsor';
import ga from 'Partials/tracker';
import { Favorite } from 'Partials/user/favorite';
import { UserHelpers } from 'Partials/user/index';
import { view as View } from 'Partials/view';
// Preload Recaptcha V2 for downloads only for php views
import { RecaptchaV2 } from 'Project/ts/library/recaptcha-v2';
import Tracker from 'Project/ts/library/tracker';

// Menu
initializeMenu();

// Copy
delegate('.copy__trigger', 'click', async function(this: HTMLButtonElement) {
    const text = this.dataset.copy || '';
    const wrapper = this.parent('.copy') as HTMLElement | undefined;

    if (wrapper) {
        let status: 'success' | 'error' = 'success';
        const { default: template } = await import('Project/ts/templates/parts/copy-confirmation.hbs');
        const copyTextElement = q('.copy__text span', wrapper);

        try {
            Copy(text);
        } catch (e) {
            status = 'error';
        }

        wrapper.insertAdjacentHTML(
            'beforeend',
            template({ status, reduced: wrapper.classList.contains('copy--reduced') }),
        );

        await delay(3000);

        const confirmationElement = q('.copy__confirmation', wrapper);

        if (confirmationElement) {
            confirmationElement.classList.add('animation--fade-out');

            await delay(250);

            if (confirmationElement.parentElement) {
                confirmationElement.parentElement.removeChild(confirmationElement);
            }

            if ('error' === status && copyTextElement) {
                if ((document as any).body.createTextRange) {
                    const range = (document.body as any).createTextRange();
                    range.moveToElementText(text);
                    range.select();
                } else if (window.getSelection) {
                    const selection = window.getSelection();
                    const range = document.createRange();
                    range.selectNodeContents(copyTextElement);
                    if (selection) {
                        selection.removeAllRanges();
                        selection.addRange(range);
                    }
                }
            }
        }
    }

    if (this.closest('.collections__modal')) {
        const collectionId = q('div.share')?.dataset.id;
        const args = ['collections', 'share-copylink', collectionId];
        Tracker.trackGAEventNoCache(args);
    }
});

// Tracker
delegate('.track', 'click', function(this: HTMLLinkElement) {
    const trackArguments = this.dataset.trackArguments;

    if (trackArguments && trackArguments.indexOf(',') >= 0) {
        const trackerArguments = trackArguments.split(',').reduce((acc: string[], str) => {
            let string = str.trim().toLowerCase();

            try {
                string = JSON.parse(string);
            } catch (err) {}

            acc.push(string);

            return acc;
        }, []);

        ga('send', ...trackerArguments);
    }
});

// Language selector
const language = document.getElementById('language') as HTMLSelectElement;

if (language) {
    language.addEventListener('change', () => window.location.replace(language.value));
}

[...View.getPages(), 'static', 'landing'].forEach(viewType => {
    window.addEventListener(`print${viewType}`, function(event: CustomEvent) {
        const { print } = event.detail;

        if (print && 'detail' !== viewType) {
            qq('.campaign').forEach(element => {
                const parent = element.parent('#main');
                if (!parent) {
                    element.parentElement?.removeChild(element);
                }
            });
        }

        accordion.refresh();
        notify.printQueue();

        abandonedCart.getCoupon();

        Sponsor.coupon();
        Search.focus();

        document.body.dataset.hbsTemplate = viewType;

        if ('detail' !== viewType) {
            const modalElement = document.getElementById('modal-detail');
            modalElement?.parentElement?.removeChild(modalElement);
        }

        Lazyload.run();

        viewType === 'search' && Favorite.updateState(true);
    });
});

// PrePrint
window.addEventListener('print', async function(e: CustomEvent) {
    const main = document.getElementById('main');
    const view = 'string' === typeof e.detail ? e.detail : '';

    const fieldAuthor = <HTMLInputElement>document.getElementById('author');

    if (view.match(/search/)) {
        if (fieldAuthor) {
            fieldAuthor.value = '';
        }

        Search.setFormat('search');
    }

    if (document.getElementsByClassName('category--landing').length < 2) {
        document.body.classList.remove('category--landing');

        ['vector', 'psd', 'icon', 'photo'].forEach(category => {
            document.body.classList.remove(`category--landing--${category}`);
        });
    }

    if (main) {
        main.removeAttribute('data-summary-total');
    }
});

delegate('.follow', 'click', function(this: HTMLElement) {
    this.addEventListener('mouseout', eventHandlerMouseOutFollow);
});

delegate('a[data-tag-order]', 'click', function(this: HTMLElement) {
    const tagOrderAttribute = this.dataset.tagOrder;

    if (tagOrderAttribute) {
        const order = +tagOrderAttribute + 1;
        const userID = gr && gr.user ? gr.user.id || 0 : 0;
        const searchValue = Search.getValue();
        const relatedTag = this.textContent?.trim();
        const origin = /\/search/.test(window.location.href) ? 'search' : 'tag';

        const trackingParams = [
            'term-related',
            `lng=${LANGUAGE_NAME}`,
            `ref=${searchValue}`,
            `rel=${relatedTag}`,
            `ori=${origin}`,
            `pos=${order}`,
            `uid=${userID}`,
        ];

        Tracker.send(`${TRACKER_URL}_ga?${trackingParams.join('&')}`);
    }
});

const eventHandlerMouseOutFollow = function(this: HTMLButtonElement) {
    this.blur();
    this.removeEventListener('mouseout', eventHandlerMouseOutFollow);
};
declare let RecaptchaDownloads: RecaptchaV2;

const pageUserDownloads = q('#main .user.user--downloads');
const pageDetail = q('#main .detail');
const pageDownloads = q('#main.download');

if (
    pageUserDownloads ||
    pageDetail ||
    pageDownloads ||
    (FEATURE_DOWNLOAD_FROM_THUMBNAIL_BY_COUNTRY && UserHelpers.isPremium())
) {
    const loadRecaptchaDownload = () =>
        new Promise(async resolve => {
            RecaptchaDownloads = new RecaptchaV2(`safe-download`);
            await RecaptchaDownloads.load();
            resolve(true);
        });

    loadRecaptchaDownload();
}

initializeCollectionEvents();

if (typeof GLOBAL_CAMPAIGN !== 'undefined') {
    globalCampaign.init();
}

accordion.refresh();
notify.printQueue();
abandonedCart.getCoupon();
Sponsor.coupon();

navigationHistory.addHistoryEntry(document.body.dataset.hbsTemplate || '');
