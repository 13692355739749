import { FilterEventConfig } from '../FilterEvents';
import { FilterKeys, FilterProperties } from '../FilterInputs';
import getFilterValue from '../getFilterValue';
import mapFiltersToUpdate from '../mapFiltersToUpdate';
import getFilterIdList from 'Partials/filters-v2/getFilterIdList';

class ConditionColor {
    private readonly queryKeyName = 'query';
    private readonly colorKeyName = 'color';
    private optionItemActivated: HTMLElement;
    private filterKeysStatus: FilterKeys;

    constructor(eventConfig: FilterEventConfig) {
        this.optionItemActivated = eventConfig.optionItem;
        this.filterKeysStatus = eventConfig.filterKeysStatus;

        this.apply();
    }

    private get colorFilters(): FilterProperties[] {
        return this.filterKeysStatus[this.colorKeyName];
    }

    private applyColorRemove(): string[] {
        return [...getFilterIdList(this.colorFilters)];
    }

    private apply() {
        const { query } = getFilterValue(this.queryKeyName);

        if (!query) return;

        const fieldValue = this.optionItemActivated.getAttribute('value') || '';
        if (fieldValue === 'all') {
            const disableFilters = [...this.applyColorRemove()];

            mapFiltersToUpdate(disableFilters, false);
        }
    }
}

export default ConditionColor;
