import { Options } from 'redaxios';
import { q, ww } from 'bobjoll/ts/library/dom';
import getFilterValue from 'Partials/filters-v2/getFilterValue';
import { Search } from 'Partials/search/controller';
import { view, ViewPrint } from 'Partials/view';
import Fetch from 'Partials/xhr';
import { Arguments } from 'Project/ts/library/arguments';
import Cache from 'Project/ts/library/cache';
import { numberNormalizer } from 'Project/ts/library/helpers';
import { maxLength } from 'Project/ts/library/helpers/text';

import { __ } from '../../language';

export class Collection {
    private static readonly fetchDefaultSettings: Options = {
        withCredentials: true,
        method: 'POST',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        },
    };

    private static readonly getCollectionAPI = '/xhr/user/one_collection/';

    public static async getData(options: ViewPrint) {
        const response = await Search.getData(options);
        return await Collection.parseData(options, response);
    }

    public static async getPopularData(options: ViewPrint) {
        const response = await Search.getData(options);
        return await Collection.parseData(options, response, true);
    }

    public static getMetas(options: ViewPrint) {
        return Search.getMetas(options);
    }

    public static setFilters(options: ViewPrint, viewType: string) {
        return Search.setFilters(options, viewType);
    }

    public static async setMetas(options: ViewPrint) {
        return await Search.setMetas(options);
    }

    public static async updateUserCollectionData() {
        const currentCollectionId = q('.collection')?.dataset.id;

        const getRequestSettings: Options = {
            method: "GET",
        };

        const fetchSettings = {
            ...Collection.fetchDefaultSettings,
            ...getRequestSettings,
        };

        const response = await Fetch(`${Collection.getCollectionAPI}${currentCollectionId}`, fetchSettings);

        if (response.data) {
            const numResources = response.data.collection.resources.length;

            const totalResources = q('.collection .collection__totalr');
            if (
                totalResources &&
                numResources && numResources > 0
            ) {
                const translationKey = numResources === 1 ? '%s Resource' : '%s Resources';
                const newNumResourcesText = __(translationKey, numResources);
                totalResources.innerText = newNumResourcesText;
                totalResources.classList.remove('hide');
            }
            return await Collection.parseUserCollectionData(response);
        }
    }

    private static async parseUserCollectionData(response: any) {
        const resources = response.getPathValue('data.collection.resources') || [];

        if (resources.length) {
            response.data.resources = resources;
            response.isUserCollection = true;

            const getAllFilterValues = getFilterValue(Object.keys(ww.FiltersEnabled));
            const cacheKey = `${Search.api}?${Arguments.objectToString({ ...getAllFilterValues, format: view.template })}`;
            const previousCache = Cache.get(cacheKey);
            previousCache.data.resources = resources;
            Cache.set({ key: cacheKey, value: previousCache, time: 1800 });
        }
        return response;
    }

    private static async parseData(options: ViewPrint, response: any, parsePaging = false) {
        if (options.url && options.location) {
            const location = new URL(options.location);
            const filter = Arguments.stringToObject(options.url);
            const url = `${BASE_URL}${location.pathname.split('/')[1]}`;

            if (0 < numberNormalizer(response.meta.summary.total || '0') && 0 === numberNormalizer(response.meta.summary.total_collections || '0')) {
                const optionsSearch = { ...options };
                optionsSearch.url = options.url.replace('format=collections', 'format=search');
                const request = await Search.getData(optionsSearch);
                const resources = request.getPathValue('data.resources') || [];
                if (resources.length) {
                    response.data.resources = resources;
                }
                if (request.paging.next && request.paging.next_params) {
                    if (!response.paging) {
                        response.paging = {};
                    }
                    response.paging.view_more = request.paging.next;
                    response.paging.view_more_params = request.paging.next_params;
                }
            }

            if (response.paging && response.paging.page && parsePaging) {
                if (response.paging.next) {
                    response.paging.next = `${url}/${response.paging.page + 1}`;
                    response.paging.nextDataFilter = `sort=popular&type=${filter.type}&format=popular&selection=1&page=${response.paging.page + 1}`;
                }

                if (response.paging.previous) {
                    response.paging.previous = `${url}/${response.paging.page - 1}`;
                    response.paging.previousDataFilter = `sort=popular&type=${filter.type}&format=popular&selection=1&page=${response.paging.page - 1}`;
                }
            }
        }

        return response;
    }

    public static pinterestShareSettings(options: PinterestShareSettings) {
        const hashtag =
            '%23' +
            options.collectionTitle
                .substr(0, options.collectionTitle.indexOf(','))
                .toLowerCase()
                .replace(/\s/gi, '');
        return {
            description: `${maxLength(options.collectionTitle, 40)} %23Freepik ${hashtag}`,
        };
    }
}

interface PinterestShareSettings {
    collectionTitle: string;
    collectionPremium: boolean;
}
