document.addEventListener('DOMContentLoaded', function () {
    const VALUE_CHANGE_STYLES_TO_SCROLL = 40;
    let isMobile = window.innerWidth <= 640;

    interface Elements {
        logoMenuSearch: HTMLElement | null;
        logoMenuTopBar: HTMLElement | null;
        searchForm: HTMLElement | null;
    }

    const getElements = (): Elements => ({
        logoMenuSearch: document.getElementById('logo-menu-search'),
        logoMenuTopBar: document.getElementById('logo-menu-bar'),
        searchForm: document.getElementById('search-form'),
    });

    const setTransitions = (elements: Elements) => {
        Object.values(elements).forEach((el) => {
            if (el) {
                (el as HTMLElement).style.transition = 'all ease-in-out 0.15s';
            }
        });
    };

    const applyStyles = (isScrollDown: boolean, elements: Elements) => {
        if (elements.logoMenuTopBar && elements.logoMenuSearch && elements.searchForm) {
            // Apply styles logoMenuTopBar
            elements.logoMenuTopBar.style.opacity = isScrollDown ? '0' : '1';
            elements.logoMenuTopBar.style.transform = isScrollDown
                ? 'translateY(50px)'
                : 'translateY(0)';

            // Apply styles logoMenuSearch
            elements.logoMenuSearch.style.opacity = isScrollDown ? '1' : '0';
            elements.logoMenuSearch.style.transform = isScrollDown
                ? 'translateY(0)'
                : 'translateY(-50px)';

            // Apply styles searchForm
            if (isMobile) {
                elements.searchForm.style.transform = isScrollDown
                    ? 'translateX(40px)'
                    : 'translateX(0)';
                elements.searchForm.style.width = isScrollDown
                    ? 'calc(100% - 40px)'
                    : '100%';
            } else {
                elements.searchForm.style.transform = isScrollDown
                    ? 'translateX(155px)'
                    : 'translateX(0)';
                elements.searchForm.style.width = isScrollDown
                    ? 'calc(100% - 155px)'
                    : '100%';
            }
        }
    };

    const handleScroll = () => {
        if (window.innerWidth < 320) return;
        const currentScrollY = window.scrollY;
        const isScrollDown = currentScrollY > VALUE_CHANGE_STYLES_TO_SCROLL;
        const elements = getElements();

        if (elements.logoMenuSearch && elements.logoMenuTopBar && elements.searchForm) {
            setTransitions(elements);
            applyStyles(isScrollDown, elements);
        } else {
            setTimeout(() => handleScroll(), 100); // Retry after 100ms
        }
    };

    const handleResize = () => {
        isMobile = window.innerWidth <= 640;
        handleScroll();
    };

    const initScrollPosition = (scrollEnable: boolean) => {
        if (!scrollEnable) return;

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    };

    // Call initScrollPosition with true or false to enable/disable scrolling effect
    initScrollPosition(true);
});
