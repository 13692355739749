import { q, ww } from 'bobjoll/ts/library/dom';
import { __ } from 'Partials/language';

export const initDiscoverRebrandingModal = async () => {
    const keyStorage = 'rb-freepik-modal';
    if (localStorage.getItem(keyStorage) === 'true') return;

    const { default: viewTemplate } = await import('Templates/parts/rebranding-modal.hbs');
    const modal = ww.ModalInstance.print({
        name: 'discover-rebranding',
        html: viewTemplate(),
    }) as HTMLElement;

    ww.ModalInstance.show(keyStorage);

    if (modal) {
        modal.classList.add('discover-rebranding');

        modal.addEventListener('hide', function eventHandler() {
            localStorage.setItem(keyStorage, 'true');
            modal.removeEventListener('hide', eventHandler);
            document.body.classList.remove('up-modal-show');
        });
        const buttonSubmit = q('[data-action="submit"]', modal);
        const buttonLater = q('[data-action="close"]', modal);

        buttonSubmit?.addEventListener('click', () => {
            ww.ModalInstance.hide();
        });
        buttonLater?.addEventListener('click', () => {
            ww.ModalInstance.hide();
        });
        document.body?.classList.add('up-modal-show');
    }
};
