import { q } from 'bobjoll/ts/library/dom';
import { FORM_ID } from 'Partials/search-by-image/searchimage.constants';

class SearchByImage {
    public static goToSearchByImage(actionPath: string = '/search?format=search&img=1') {
        const form = q(`#${FORM_ID}`) as HTMLFormElement;
        if (form) {
            form.action = actionPath;
            form.submit();
        }
    }
}

export default SearchByImage;
