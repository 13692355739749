import { Search } from 'Partials/search/controller';
import { view } from 'Partials/view';

view.addView({
    name: 'search',
    groups: ['search', 'favorites', 'shutterstock'],
    template: {
        history: true,
        template: () => import('Project/ts/templates/views/search.hbs'),
        insert: {
            method: 'innerHTML',
            element: '#main',
            bannerCampaign: 'search',
        },
        loader: true,
        print: true,
        push: true,
        pushTracker: true,
        delay: false,
        lock: true,
        metas: true,
        getXHR: Search.getData,
        getMetas: Search.getMetas,
        setMetas: Search.setMetas,
        callback: Search.setFilters,
        prePrint: Search.setFilters,
    },
});

view.addView({
    name: 'search-banner',
    groups: ['search'],
    template: {
        history: false,
        template: () => import('Project/ts/templates/parts/search-banner.hbs'),
        insert: {
            method: 'insertAdjacentHTML',
            methodArgument: 'beforeend',
            element: '#main',
        },
        getXHR: Search.getData,
        loader: false,
        print: true,
        push: false,
        pushTracker: true,
        delay: false,
        lock: false,
    },
});
