import { FilterEventConfig } from '../FilterEvents';
import { FilterKeys, FilterProperties, ValidFilterKey } from '../FilterInputs';
import getFilterIdList from '../getFilterIdList';
import mapFiltersToUpdate from '../mapFiltersToUpdate';

class ConditionRemove {
    private readonly peopleKeyName = 'people';
    private readonly clarifaiKeyName = 'demographic';
    private readonly styleKeyName = 'style';
    private readonly aiModelKeyName = 'ai_model';
    private readonly promptKeyName = 'prompt';
    private filterKeyActivated: ValidFilterKey;
    private filterKeysStatus: FilterKeys;
    private optionItemActivated: HTMLElement;

    constructor(eventConfig: FilterEventConfig) {
        this.filterKeyActivated = eventConfig.filterKey;
        this.filterKeysStatus = eventConfig.filterKeysStatus;
        this.optionItemActivated = eventConfig.optionItem;

        this.apply();
    }

    private get peopleFilters(): FilterProperties[] {
        return this.filterKeysStatus[this.peopleKeyName];
    }

    private get clarifaiFilters(): FilterProperties[] {
        return this.filterKeysStatus[this.clarifaiKeyName];
    }

    private get styleFilters(): FilterProperties[] {
        return this.filterKeysStatus[this.styleKeyName];
    }

    private get aiModelFilters(): FilterProperties[] {
        return this.filterKeysStatus[this.aiModelKeyName];
    }

    private get promptFilters(): FilterProperties[] {
        return this.filterKeysStatus[this.promptKeyName];
    }

    private applyClarifaiRemove(fieldName: string, fieldValue: string): string[] {
        let disableFilters: string[] = [];

        if (fieldName.match(/people/) || (fieldName.match(/type/) && fieldValue === 'photo')) {
            disableFilters = [...getFilterIdList(this.clarifaiFilters), ...getFilterIdList(this.peopleFilters)];
        }

        return disableFilters;
    }

    private applyGraphicStyleRemove(fieldName: string, fieldValue: string): string[] {
        let disableFilters: string[] = [];

        if (fieldName.match(/^type$/) && fieldValue.match(/^(?:vector|photo)$/)) {
            disableFilters = getFilterIdList(this.styleFilters);
        }

        return disableFilters;
    }

    private applyAIRemove(fieldName: string): string[] {
        let disableFilters: string[] = [];

        if (fieldName.match(/ai/)) {
            disableFilters = [...getFilterIdList(this.aiModelFilters), ...getFilterIdList(this.promptFilters)];
        }

        return disableFilters;
    }

    private apply() {
        const fieldName = this.filterKeyActivated.toString();
        const fieldValue = this.optionItemActivated.dataset.option || '';
        const disableFilters = [
            ...this.applyClarifaiRemove(fieldName, fieldValue),
            ...this.applyGraphicStyleRemove(fieldName, fieldValue),
            ...this.applyAIRemove(fieldName),
        ];

        mapFiltersToUpdate(disableFilters, false);
    }
}

export default ConditionRemove;
