import { FilterEventConfig } from '../FilterEvents';
import { FilterKeys, ValidFilterKey } from '../FilterInputs';
import getFilterValue from '../getFilterValue';
import mapFiltersToUpdate from '../mapFiltersToUpdate';

class ConditionType {
    private typeKeyName = 'type';
    private filterKeyActivated: ValidFilterKey;
    private filterKeysStatus: FilterKeys;

    constructor(eventConfig: FilterEventConfig) {
        const isAuthorSearch = document.body.dataset.hbsTemplate === 'author';

        if (!FEATURE_FILTER_PHOTO_EXCLUDE_AI_BY_LANGUAGE || isAuthorSearch) {
            return;
        }

        this.filterKeyActivated = eventConfig.filterKey;
        this.filterKeysStatus = eventConfig.filterKeysStatus;

        this.apply();
    }

    private apply() {
        const { type } = getFilterValue(this.typeKeyName, this.filterKeysStatus);
        const fieldName = this.filterKeyActivated.toString();
        const isPhotoSelected = fieldName.match(/type/) && type.match(/photo/);

        if (isPhotoSelected) {
            const enableFilters = ['filter-ai-exclude'];
            const disableFilters = ['filter-ai-only'];

            mapFiltersToUpdate(disableFilters, false);
            mapFiltersToUpdate(enableFilters);
        }
    }
}

export default ConditionType;
