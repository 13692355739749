import { q, qq } from 'bobjoll/ts/library/dom';
import triggerManualGA4Events from 'Partials/events/ga4.init-manual-events';

import { FilterValue } from '../filters-v2/getFilterValue/getFilterValue';

const sendSearchEventGA4 = (options: SearchEvents) => {
    const { eventName, totalResults, filters } = options;
    const extraInfo = options.extraInfo || {};

    if (filters['category']) extraInfo.q_type = filters['category'];

    triggerManualGA4Events({
        description: 'searchLandings',
        options: {
            event_name: eventName,
            search_term: filters['query'] || '',
            search_total_results: totalResults || 0,
            extraInfo,
        },
    });
};

export const sendHistorySearchEventGA4 = () => {
    qq('.autocomplete__popular--section li')?.forEach((item: HTMLAnchorElement) => {
        item.addEventListener('click', () => {
            const searchTerm = item.querySelector('.name')?.innerHTML;
            triggerManualGA4Events({
                description: 'popularSearchesTags',
                options: {
                    search_term: searchTerm,
                    location: 'search',
                },
            });
        });
    });
};

const sectionsIn404Landing = (filters: FilterValue) => {
    qq('.no-results--categories ul a')?.forEach((item: HTMLAnchorElement) =>
        item.addEventListener('click', () =>
            sendSearchEventGA4({
                eventName: '404_results_categories',
                filters,
                extraInfo: {
                    link_text: q('span', item)?.innerText || '',
                    link_url: item.href || '',
                },
            }),
        ),
    );

    qq('.no-results--popular ul a')?.forEach((item: HTMLAnchorElement) =>
        item.addEventListener('click', () =>
            sendSearchEventGA4({
                eventName: '404_results_popular',
                filters,
                extraInfo: {
                    link_text: item.innerText.trim() || '',
                    link_url: item.href || '',
                },
            }),
        ),
    );
};

export const searchEventsGA4 = (eventConfig: SearchEvents) => {
    const { totalResults, filters } = eventConfig;
    const eventName = totalResults === 0 ? 'view_search_results_404' : 'view_search_results';
    const extraInfo: SearchExtraInfo = {
        q_page: 1,
    };

    if (filters['format']) {
        extraInfo.q_format = filters['img'] === '1' ? 'search_by_image' : filters['format'];
    }

    if (filters['page']) extraInfo.q_page = filters['page'];

    if (q('.gtm-clean-last-filter')) extraInfo.search_logic_applied = 'clean_last_filter';
    if (q('.gtm-clean-all-filters')) extraInfo.search_logic_applied = 'clean_all_filters';
    if (q('.gtm-empty-collection-search')) extraInfo.search_logic_applied = 'show_resources_in_collections';

    sendSearchEventGA4({
        eventName,
        totalResults,
        filters,
        extraInfo,
    });

    sectionsIn404Landing(filters);
};

interface SearchEvents {
    eventName?: string;
    extraInfo?: SearchExtraInfo;
    filters: FilterValue;
    totalResults?: number;
}

interface SearchExtraInfo {
    link_text?: string;
    link_url?: string;
    q_format?: string;
    q_page?: number;
    q_type?: string;
    search_logic_applied?: string;
}
