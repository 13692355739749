import { capitalize } from 'Project/ts/library/helpers';
import { maxLength } from 'Project/ts/library/helpers/text';

export const pinterestShareSettings = function(settings: PinterestShareOptions) {
    const encodedHash = '%23';
    const license = settings.resourcePremium ? 'Premium' : 'Free';
    const type = capitalize(settings.resourceType);
    const keywords =
        settings.resourceKeywords.length > 0 && undefined != settings.resourceKeywords[0]
            ? (settings.resourceKeywords || [])
                  .slice(0, 4)
                  .map(keyword => encodedHash + keyword.toLowerCase())
                  .join(' ')
            : '';
    return {
        description:
            `${maxLength(settings.resourceTitle, 40)} | ${license} ${type}` +
            ` ${encodedHash}Freepik ${encodedHash +
                (settings.resourcePremium ? '' : 'free') +
                settings.resourceType} ${keywords}`,
    };
};

interface PinterestShareOptions {
    resourceType: string;
    resourceTitle: string;
    resourceKeywords: string[];
    resourcePremium: boolean;
}
