import { q, ww } from 'bobjoll/ts/library/dom';
import { sessionStorage } from 'bobjoll/ts/library/storage';
import { filterApplyEventGA4 } from 'Partials/events/ga4.filter';

import { STORAGE_NAME_FOR_URL_MOBILE } from '../constants';
import getSearchURL from '../getSearchURL';

class FilterApplyMobile {
    private applyButtonElement: HTMLElement;

    constructor({
        applySelector = '.filter-sidebar .apply-filters',
    }: FilterApplyMobileConfig = {}) {
        const applyButtonElement = q(applySelector) as HTMLElement;
        if (!applyButtonElement) return;

        this.applyButtonElement = applyButtonElement;

        this.addEvent();
    }

    private addEvent() {
        this.applyButtonElement.addEventListener('click', () => {
            const url = sessionStorage.getItem(STORAGE_NAME_FOR_URL_MOBILE.namespace, STORAGE_NAME_FOR_URL_MOBILE.key)
                || getSearchURL(ww.FiltersEnabled);
            filterApplyEventGA4(url);
            window.location.href = url;
        });
    }
}

interface FilterApplyMobileConfig {
    applySelector?: string;
}

export default FilterApplyMobile;
