import { delegate } from 'bobjoll/ts/library/dom';
import ga from 'Partials/tracker';

import { __ } from './language';

interface Networks {
    [name: string]: Network;
}

interface Network {
    name: string;
    class: string;
    url: string;
}

type SocialNetworks = keyof Networks;

interface NetworkData {
    network: SocialNetworks;
    url: string;
    title?: string;
    image?: string;
    description?: string;
    message?: string;
    video?: boolean;
    hashtags?: string[];
    origin?: string;
    ga: {
        name: string;
        myArguments: any[];
    };
    [name: string]: any;
}

class Share {
    private Networks: Networks;

    constructor() {
        this.Networks = {
            facebook: {
                name: 'Facebook',
                class: 'facebook',
                url: `https://www.facebook.com/dialog/share?app_id=${FACEBOOK_APP_ID}&display=popup&href={url}&quote={description}`,
            },
            twitter: {
                name: 'Twitter',
                class: 'twitter',
                url:
                    'https://twitter.com/intent/tweet?text={title}&text={description}&via={origin}&url={url}&hashtags={hashtags}',
            },
            google: {
                name: 'Google+',
                class: 'google',
                url: 'https://plus.google.com/share?url={url}',
            },
            pinterest: {
                name: 'Pinterest',
                class: 'pinterest',
                url:
                    'https://pinterest.com/pin/create/button/?media={image}&url={url}&is_video={video}&description={description}',
            },
            whatsapp: {
                name: 'Whatsapp',
                class: 'whatsapp',
                url: 'https://wa.me/?text={message}',
            },
            telegram: {
                name: 'Telegram',
                class: 'telegram',
                url: 'https://telegram.me/share/url?url=${url}&text={message}',
            },
            linkedin: {
                name: 'Telegram',
                class: 'telegram',
                url:
                    'https://www.linkedin.com/shareArticle?mini=true&url={url}&source={origin}&title={title}&summary={description}',
            },
        };
    }

    private generateURL(data: NetworkData): string | void {
        const properties = Object.keys(data);
        const network = this.Networks[data.network];

        if (data.url.indexOf('#') >= 0) {
            data.url = data.url.substr(0, data.url.indexOf('#'));
        }

        data.url = encodeURI(data.url);

        if (network) {
            let url = JSON.parse(JSON.stringify(network.url));

            properties.forEach(function (property) {
                let value = data[property];

                if (
                    'twitter' === network.class &&
                    'title' === property &&
                    'share-collection' !== data.ga.name
                ) {
                    value = __('Freepik - Free Graphic resources for everyone on %s', '@freepik');
                }

                if ('pinterest' === network.class) {
                    if ('url' === property) {
                        value = encodeURIComponent(value).replace('&', '%26');
                    }

                    if ('description' === property) {
                        value = value.replace('|', '%7C');
                    }
                } else {
                    value = encodeURIComponent(value)
                        .replace('&', '%26')
                        .replace('|', '%7C');
                }

                const arg = new RegExp('{' + property + '}', 'g');
                url = url.replace(arg, value);
            });

            return this.cleanURL(url);
        }
    }

    private cleanURL(fullURL: string) {
        fullURL = fullURL.replace(/\{([^{}]*)}/g, '');

        const params = fullURL.match(/[^\=\&\?]+=[^\=\&\?]+/g);
        let url = fullURL.split('?')[0];

        if (params && params.length > 0) {
            url += '?' + params.join('&');
        }

        return url;
    }

    public popup(settings: NetworkData, { width, height }: { width: number; height: number }) {
        const url = this.generateURL(settings);

        if (url) {
            const px = Math.floor(((screen.availWidth || 1024) - width) / 2);
            const py = Math.floor(((screen.availHeight || 700) - height) / 2);
            const options = `
                width=${width},
                height=${height},
                left=${px},
                top=${py},
                location=0,
                menubar=0,
                toolbar=0,
                status=0,
                scrollbars=1,
                resizable=1
            `;

            ga('send', 'event', settings.ga.name, settings.network, ...settings.ga.myArguments);

            window.open(url, 'social', options);
        }
    }
}

delegate('.button .button--popover', 'click', function (this: HTMLLinkElement, e: Event) {
    e.preventDefault();

    const parents = this.parents('.button');

    if (parents.length) {
        const item = <HTMLElement>parents[0];

        if (item) {
            const url = window.location.href;
            const title = item.querySelector('h2');
            const imageElement = <HTMLImageElement>item.querySelector('.detail__gallery img');
            const network = this.dataset.network;
            const preview = this.parent('.detail__preview');

            if (url && title && imageElement && network) {
                const image = imageElement.src;

                if (image) {
                    const settings = {
                        network: network,
                        title: title.innerText,
                        image: image,
                        url: url,
                        ga: {
                            name: preview ? 'share_buttons_detail-preview' : 'share_buttons_detail',
                            myArguments: [item.dataset.id || ''],
                        },
                    };

                    share.popup(settings, {
                        width: 800,
                        height: 500,
                    });
                }
            }
        }
    }
});

export var share = new Share();
