import { q } from 'bobjoll/ts/library/dom';

import { view } from '../partials/view';

const collectionElement = q('.collection');

const eventHandlerRenderUserCollection = function() {
    view.newPage({
        url: `${BASE_URL}xhr/search?page=1&sort=popular`,
        views: ['search-banner'],
    });

    window.addEventListener('printsearch-banner', () => {
        const showcases = Showcase.getElements(false, '#search-banner');
        Showcase.setLayout({ showcases });
    });
};

if (collectionElement) {
    const collectionAuthorId = collectionElement.dataset.authorId || '';

    if (
        gr.user &&
        gr.user.id.toString() == collectionAuthorId &&
        collectionElement.classList.contains('collection--empty')
    ) {
        eventHandlerRenderUserCollection();
    }
}
