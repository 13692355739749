import { q } from 'bobjoll/ts/library/dom';
import { Settings } from 'bobjoll/ts/library/settings';
import { isLandingPaid } from 'Partials/landing-paid/landing-paid-constants';
import navigationHistory from 'Partials/navigation-history';
import { UserHelpers } from 'Partials/user';
import { cookie } from 'Project/ts/library/cookie';

import { showCampaignBanner } from '../campaign-php/campaign.helpers';
import { globalMiddleware } from './abandonedCart.middleware';

export const showImpCoupon = function() {
    return showCampaignBanner() ||
        (getCouponCountBool('landing', 1) && getCouponCountBool('premium', 5)) ||
        UserHelpers.isPremium() ||
        window.innerWidth < parseFloat(Settings.breakpoints.sm)
        ? false
        : navigationHistory.getHistoryLength() > navigationHistory.abandonedCartHistoryLimit
        ? true
        : false;
};

export const showCoupon = function() {
    if (cookie.getItem('debug_abandoned_cart')) {
        return true;
    }
    // FIXME: el cupon autopromo no aparece para los usuarios de India mientras ChargeBee no tenga la implementación de cupones en prod
    return USER_COUNTRY_CODE !== 'IN' && globalMiddleware.showCoupon(showImpCoupon()) && !isLandingExcluded();
};

export const getImpEvents = function() {
    return {
        click: ['premium', 'retargeting-gopremium', 'click'],
        view: ['premium', 'retargeting', 'onload'],
    };
};

export const getEvents = function() {
    return globalMiddleware.getEvents(getImpEvents());
};

const getCouponCountBool = (getValue: 'landing' | 'premium', minCount: number) => {
    const cookieName = 'autopromo-visit';
    const cookiePremiumViews = cookie.getItem(cookieName);
    const premiumResources = cookiePremiumViews ? JSON.parse(cookiePremiumViews) : {};
    const cookiePrefix = 'landing' === getValue ? 'l' : 'p';

    let minutesDifference = 0;
    let countPremiumViews = 0;
    let lastUpdatePremiumViews = new Date();

    if (Object.keys(premiumResources).length) {
        if (premiumResources[`${cookiePrefix}_vt`])
            countPremiumViews = parseInt(premiumResources[`${cookiePrefix}_vt`]);
        if (premiumResources[`${cookiePrefix}_ud`])
            lastUpdatePremiumViews = new Date(premiumResources[`${cookiePrefix}_ud`]);

        const date = new Date();
        const msDifference = Math.round(Math.abs(date.getTime() - lastUpdatePremiumViews.getTime()));

        minutesDifference = msDifference / 60000;
    }

    return countPremiumViews < minCount || minutesDifference < 30;
};

const isLandingExcluded = () => {
    const isLandingTeams = q('.page-started-teams--pricing-tabs-teams');
    const isLandingMockupBaker = window.location.href.includes('mockup-baker');

    return isLandingPaid || isLandingTeams || isLandingMockupBaker || FEATURE_ABANDONED_CART_DISABLED;
};
