import { q } from 'bobjoll/ts/library/dom';
import { pixelScriptPinterest } from 'Project/ts/library/pixels/pixel.pinterest';
import { initDiscoverRebrandingModal } from '../partials/rebranding/rebranding.component';

if ('search' === document.body.dataset.hbsTemplate) {
    const searchField = q('#search-value') as HTMLInputElement;
    let term = '';
    if (searchField) {
        term = searchField.value;
    }

    if (FEATURE_POPUP_REBRANDING) {
        initDiscoverRebrandingModal();
    }

    pixelScriptPinterest.addEvent('search', { search_query: term });
}
