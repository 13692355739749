import { getUUIDSearchTracker } from 'Project/ts/library/getUUIDSearchTracker';

const concatUUIDSearchTracker = (url: string) => {
    const uuid = getUUIDSearchTracker();

    if (uuid === '') return url;

    const regex = /(#uuid=)[^&#]+/;
    if (regex.test(url)) {
        return url.replace(regex, `$1${uuid}`);
    }

    const separator = url.includes('#') ? '&' : '#';
    return `${url}${separator}uuid=${uuid}`;
};

export default concatUUIDSearchTracker;
