/* eslint-disable @typescript-eslint/camelcase */
import { delegate, q } from 'bobjoll/ts/library/dom';
import { getCacheResource } from 'Partials/detail-v2/api/detail.api.cache';
import { pinterestShareSettings } from 'Partials/detail-v2/helpers/detail.helpers.socialPinterest';
import { share } from 'Partials/share';
import { Spinner } from 'Partials/spinner';
import { RecaptchaV2 } from 'Project/ts/library/recaptcha-v2';

import { Arguments } from '../library/arguments';
import { view } from './view';

const DISNEY_AUTHOR_ID = '119092513';

const handlerPinterestShare = async function(this: HTMLButtonElement, event: Event) {
    event.preventDefault();

    const resourceElement = this.parent('.showcase__item') as HTMLElement | undefined;
    const showcase = this.parent('[data-cache-key]') as HTMLElement | undefined;

    if (resourceElement && showcase) {
        const linkElement = q('.showcase__link', resourceElement) as HTMLLinkElement | undefined;
        const viewKey = showcase.dataset.cacheKey;

        if (linkElement && viewKey) {
            const resourceId = resourceElement.dataset.id || '';
            const resourceCacheId = resourceElement.dataset.cacheId || '';
            const resourceUrl = linkElement.href;
            const resourceImage = (q('img', resourceElement) as HTMLImageElement).src || resourceElement.dataset.image;
            const network = 'pinterest';
            const resourceData = await getCacheResource({
                cacheId: resourceCacheId,
                cacheKey: viewKey,
                id: resourceId,
            });
            if (resourceUrl && resourceImage && network && resourceData) {
                const settings = {
                    network,
                    image: resourceImage,
                    url: resourceUrl,
                    ga: {
                        name: 'share_buttons_list',
                        myArguments: [resourceId],
                    },
                    ...pinterestShareSettings({
                        resourceType: resourceData.resource.type,
                        resourceKeywords: (resourceData.resource.keywords || resourceData.resource.keywordList).map(
                            (object: any) => object.s,
                        ),
                        resourcePremium: resourceData.resource.premium,
                        resourceTitle: resourceData.resource.title,
                    }),
                };

                share.popup(settings, {
                    width: 800,
                    height: 500,
                });
            }
        }
    }
};

const handlerDetailClick = function(this: HTMLLinkElement, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    const targetElement = event.target as HTMLElement | undefined;
    const showcase = this.parent('.showcase__item') as HTMLElement | undefined;
    const authorId = q('.name', showcase)?.dataset.userId;

    if (authorId === DISNEY_AUTHOR_ID && this.href) {
        window.location.href = this.href;
        return;
    }

    if (FEATURE_AI_VERTICAL_BY_LANGUAGE && this?.dataset?.ai && this.href) {
        window.location.href = this.href;
        return;
    }

    if (event.ctrlKey || event.metaKey) {
        window.open(this.href);
        return;
    }

    if (FEATURE_CREDITS_SYSTEM_BY_COUNTRY) {
        window.location.href = this.href;
        return;
    }

    if (
        targetElement &&
        targetElement.parent('.showcase__link') &&
        [].slice
            .call(targetElement.classList)
            .join(',')
            .match(/name|avatar|essential-landing/)
    ) {
        return;
    }

    const spinner = new Spinner(document.body);

    import('Partials/detail-v2')
        .then(({ detail }) => {
            (async () => {
                RecaptchaDownloads = new RecaptchaV2(`safe-download`);

                await RecaptchaDownloads.load();
            })();

            const { cacheId, id } = this.dataset;
            const cacheKey = detail.helpers.getCacheKey(this);
            const url = window.location.hash || window.location.search;
            const referrerArgs = Arguments.stringToObject(url.replace('#', '').replace('?', ''));
            const viewController = document.body.dataset.hbsTemplate || '';

            if (!cacheId || !cacheKey || !id) {
                detail.helpers.errorHandler(this.href, 'Resource was not found (load component detail-v2)');
            }

            view.newPage({
                views: ['detail'],
                url: this.href,
                key: cacheKey,
                dependency: {
                    cacheId,
                    cacheKey,
                    id,
                    referrerArgs,
                    viewController,
                },
            });
        })
        .finally(() => {
            spinner.done();
        });
};

const initializeDetail = function() {
    import('Partials/detail-v2').then(({ detail }) => {
        const { initializeDetailPHP } = detail.partials;
        initializeDetailPHP();
    });
};

delegate('.showcase__item .button--share', 'click', handlerPinterestShare);
delegate('.js-detail-data-link', 'click', handlerDetailClick);

if (view.template === 'detail') {
    initializeDetail();
}

delegate('.showcase__item .avatar, .showcase__item .name', 'click', function(this: HTMLElement) {
    const showcase = this.parent('.showcase__item') as HTMLElement | undefined;
    if (showcase) {
        const authorName = q('.name', showcase)?.dataset.authorSlug;
        if (authorName) {
            const authorLink = `${AUTHOR_URL}${authorName}`;
            window.location.href = authorLink;
        }
    }
});
