/* eslint-disable @typescript-eslint/camelcase */
import { delegate, q, qq, ww } from 'bobjoll/ts/library/dom';
import { isAdBlockEnabled } from 'Library/helpers/adblock';
import { AdvertisementSettings } from 'Partials/adServer/adServer.helpers';
import { setResourcesInCollection } from 'Partials/collections';
import { searchEventsGA4 } from 'Partials/events/ga4.searches';
import getFilterValue from 'Partials/filters-v2/getFilterValue';
import Ads from 'Partials/google';
import { hotjarSearchAITest } from 'Partials/hotjar/hotjar.ai';
import { Search } from 'Partials/search/controller';
import { searchAutocomplete } from 'Partials/searchBar/searchBar.autocomplete';
import Sponsor from 'Partials/sponsor';
import ga from 'Partials/tracker';
import { UserHelpers } from 'Partials/user';
import { ViewPrint } from 'Partials/view';
import { Arguments } from 'Project/ts/library/arguments';
import { getUUIDSearchTracker } from 'Project/ts/library/getUUIDSearchTracker';
import { getRandomRange, getResourceType, numberNormalizer } from 'Project/ts/library/helpers';
import Tracker from 'Project/ts/library/tracker';
import { AdobePopupOrigin } from 'Project/ts/library/trackerSend/trackerSend.adobestock';
import { SponsorAdsInsertSettings, SponsorAdsPreloadResponse } from 'Project/ts/partials/sponsor-ads/sponsor-ads.types';

import { Advertisement } from '../advertisement';
import { setCarousel } from './helpers';
import NoResults from './noresults/noresults';
import { getItemsIdForQueryString } from './pixel';

let searchNoResults: NoResults;

const eventHandlerPrintTags = function() {
    setCarousel();
};

const eventHandlerPrintSearchGroup = async function(type: string, event: CustomEvent) {
    const { SponsorAds } = await import(
        /* webpackChunkName: "sponsor-invent" */ 'Project/ts/partials/sponsor-ads/sponsor-ads'
    );
    const options = event.detail as ViewPrint;
    const query = options.getPathValue('xhr.meta.query');
    const fromQuery = options.getPathValue('xhr.meta.from_query');
    const results = numberNormalizer(
        options.getPathValue('xhr.meta.summary.total') ||
            qq('article.collection__item').length ||
            qq('.collections-noresults .showcase__item').length ||
            '0',
    );
    const showcases = Showcase.getElements(false, '#main');
    const shutterstock = options.getPathValue('xhr.shutterstock');
    const { page = 1 } = getFilterValue('page');

    if (fromQuery && options.location && options.url) {
        const nextSearchLocation = new URL(options.location);
        const locationQuery = nextSearchLocation.searchParams.get('query');

        if (locationQuery) {
            const nextSearchUrl = new URL(options.url);
            nextSearchUrl.searchParams.set('query', locationQuery);
            options.url = nextSearchUrl.toString();
        }
    }

    searchClasses(type);
    searchTrackers(type, options);
    setResourcesInCollection();

    if (searchNoResults) {
        searchNoResults.destroy();
    }

    if (results) {
        if (type === 'collections' && !options.url && !options.location) {
            options.url = window.location.href;
            options.location = window.location.href;
        }
        const shutterstockId = options.getPathValue('xhr.id') || options.getPathValue('xhr.shutterstock.id');
        const shutterstockClasses =
            options.getPathValue('xhr.classes') || options.getPathValue('xhr.shutterstock.classes');

        searchAdvertisement(options, type, shutterstock);

        if (shutterstockId && shutterstockClasses) {
            const banner = document.getElementById(shutterstockId);
            if (banner) {
                SponsorAds.setRowChildrenClasses(options.xhr, banner);
            }
        }

        if (options.print) {
            ww.ModalInstance.hide({ dispatch: false, all: true });
            Showcase.setLayout({ showcases: showcases });
            window.scrollTo(0, 0);
        } else if (!FEATURE_VERTICAL_GRID_BY_LANGUAGE) {
            showcases.forEach(showcase => Advertisement.set(showcase));
        }

        if (query) {
            const showAdobeModal = sessionStorage.getItem('show-adobe-modal');
            if (Sponsor.adobeModalTestABenabled) {
                showAdobeModal && Sponsor.showAdobeModal(query, showAdobeModal as AdobePopupOrigin, +page);
            }

            qq('.pagination').forEach(pagination => {
                [...qq('button', pagination), ...qq('a', pagination)].forEach(button =>
                    button.addEventListener('click', () => {
                        if (Sponsor.adobeModalTestABenabled) {
                            sessionStorage.setItem('show-adobe-modal', 'page-button');
                        } else {
                            const direction = button.className.indexOf('prev') >= 0 ? 'prev' : 'next';
                            Sponsor.popup(query, `page-button-${direction}` as AdobePopupOrigin);
                        }
                    }),
                );
                qq('input', pagination).forEach(input =>
                    input.addEventListener('change', () => {
                        if (Sponsor.adobeModalTestABenabled) {
                            sessionStorage.setItem('show-adobe-modal', 'page-input');
                        } else {
                            Sponsor.popup(query, 'page-input');
                        }
                    }),
                );
            });
        }

        Lazyload.run();

        setTimeout(() => window.dispatchEvent(new Event('resize')), 100);

        TRACKING_SEARCH === 'aitest' && hotjarSearchAITest();
    } else if (type.match(/search/gi)) {
        searchNoResults = new NoResults();
    }
};

export const addQueryArgumentsToResources = function(
    argumentsDictionary: { [name: string]: string },
    selector = '.showcase__link',
    fromView?: string,
) {
    const args: string[] = [];
    const staticView = q('main.home') ? 'home' : 'collections';
    const hbsTemplate = document.body.dataset.hbsTemplate;
    const baseView = fromView || hbsTemplate;
    const trackingSearch = TRACKING_SEARCH ? `&track=${TRACKING_SEARCH}` : '';
    const uuid = getUUIDSearchTracker();
    const uuidSearch = uuid ? `&uuid=${uuid}` : '';

    fromView = baseView === 'static' ? staticView : baseView;

    if ('undefined' !== typeof argumentsDictionary.page) {
        args.push(`page=${argumentsDictionary.page[0]}`);
    }

    if ('undefined' !== typeof argumentsDictionary.query) {
        args.push(`query=${argumentsDictionary.query[0]}`);
    }

    if ('undefined' !== typeof argumentsDictionary.from_query) {
        args.push(`from_query=${argumentsDictionary.from_query[0]}`);
    }

    (qq(selector) as HTMLLinkElement[]).forEach((linkElement, index) => {
        const { href } = linkElement;
        const queryParams = `${args.join('&')}&position=${index}&from_view=${fromView}${trackingSearch}${uuidSearch}`;
        linkElement.href = `${href}${getQueryStringPrefix(href)}${queryParams}`;

        if (FEATURE_ENABLE_SEARCH_IMPRESSIONS) {
            let resourceImage = q('img', linkElement) as HTMLImageElement;
            if (resourceImage?.src) resourceImage.src += `${getQueryStringPrefix(resourceImage.src)}${queryParams}`;
            if (resourceImage?.dataset?.src)
                resourceImage.dataset.src += `${getQueryStringPrefix(resourceImage.dataset.src)}${queryParams}`;
        }
    });
};

const getQueryStringPrefix = (url: string): string => {
    return url.indexOf('?') !== -1 ? '&' : '#';
};

const eventHandlerClickSearchLink = function(this: HTMLLinkElement, event: MouseEvent) {
    if (event.ctrlKey || event.metaKey) return;

    event.preventDefault();

    const params = this.dataset.filter;
    const location = this.href;

    if (params && location) {
        window.location.href = location;
        return;
    }
};

const searchTrackers = function(view: string, options: ViewPrint) {
    const resources = options.getPathValue('xhr.data.resources') || [];
    const { license } = getFilterValue('license');
    const filters =
        getFilterValue(Object.keys(ww.FiltersEnabled)) || (options.url ? Arguments.stringToObject(options.url) : '');
    const autocompleteSelected = searchAutocomplete.itemSelected();
    const itemsIdBoostedResources = getItemsIdForQueryString(resources);
    const isAiResource = filters['type'] && filters['type'] === 'ai';

    if (autocompleteSelected) filters['autocomplete'] = 1;
    if (license) filters['license'] = license;

    Tracker.search({
        filters: filters,
        isUserPremium: UserHelpers.isPremium() ? 1 : 0,
        itemsIdBoostedResources,
        method: options.getPathValue('global.method'),
        term: filters['query'] || 'none',
        userId: UserHelpers.id().toString(),
        searchResults: resources.length ?? 0,
        resourceType: isAiResource ? 'ai' : getResourceType(),
    });

    if (view.match(/search/)) {
        document.body.setAttribute('data-hbs-template', view);
    }

    if (filters['query'] && resources.length === 0) {
        ga('send', 'event', 'busquedas-vacias', `_${LANGUAGE_NAME}`, filters['query']);
    }

    searchEventsGA4({
        totalResults: resources.length,
        filters,
    });
};

const searchClasses = function(viewType: string) {
    document.body.classList.remove('home-anon');
    document.body.classList.remove('header--hide');

    qq('#main .header-sort').forEach(header => header.classList.remove('hide'));
    qq('#main .footer-pagination').forEach(footer => footer.classList.remove('hide'));

    Search.focus();

    if ('category' !== viewType || !q('header-category')) {
        [
            'category--landing',
            'category--landing--vector',
            'category--landing--photo',
            'category--landing--icon',
            'category--landing--premium',
        ].forEach(className => document.body.classList.remove(className));
    }
};

const searchAdvertisement = function(options: ViewPrint, type: string, sponsorAdsResponse: SponsorAdsPreloadResponse) {
    if ('author' === type || !options.url || !options.location) return;

    const filterDictionary = Arguments.stringToObject(options.url || '');
    const filterDictionaryPremium = filterDictionary.getPathValue('premium');

    if (!filterDictionaryPremium || filterDictionaryPremium === '0') {
        searchSetShutterstock({ ...options, ...sponsorAdsResponse }, type);
    }

    searchSetBanners(options, type);
};

const searchSetBanners = function(options: ViewPrint, type: string) {
    const banners: ((origin: string) => void)[] = []; // TODO: reinstante [Tutpad.banner, Flaticon.banner]
    const searchValue = options.getPathValue('xhr.meta.query');
    const settings = { ...AdvertisementSettings() };

    if (banners.length) {
        banners[getRandomRange(0, banners.length - 1)](type);
    }

    if (searchValue && searchValue !== '') {
        settings.ads = settings.ads.map(ad => ({
            ...ad,
            target: {
                query: searchValue,
            },
        }));
    }

    if (FEATURE_DISABLE_ADS && isAdBlockEnabled()) {
        settings.ads.forEach(banner => q(`.spr[data-id="${banner.id}"]`)?.classList.remove('spr--force-hide'));
    }

    Ads.push({
        ...settings,
        danger: options.getPathValue('xhr.meta.danger'),
    } as any);
};

const searchSetShutterstock = async function(options: ViewPrint, type: string) {
    if (!options.url) return;
    const { SponsorAds } = await import(
        /* webpackChunkName: "sponsor-invent" */ 'Project/ts/partials/sponsor-ads/sponsor-ads'
    );

    const sponsorContainer = '.astock';
    const settings: SponsorAdsInsertSettings = {
        id: options.id,
        insert: {
            argument: type.match(/search|collections/gi) ? 'afterbegin' : 'beforeend',
            selector: q(sponsorContainer) ? sponsorContainer : '#main',
        },
        override: {
            limit: 40,
            pixabay: false,
            referer_page: 'search',
        },
        hideHeaderElement: true,
        url: options.url,
        sendTracker: true,
    };

    if (type.match(/keyword|category|popular/gi)) {
        SponsorAds.insertLazyload(settings);
    } else {
        SponsorAds.insert(settings);
    }
};

['search', 'author', 'category', 'popular', 'collections', 'keyword'].forEach(type => {
    window.addEventListener(`print${type}`, async (options: CustomEvent) => {
        await eventHandlerPrintSearchGroup(type, options);
        eventHandlerPrintTags();

        window.dispatchEvent(new Event('resize'));
    });
});

window.addEventListener(`printgroup-favorites`, () => setResourcesInCollection());

delegate('.search__link', 'click', eventHandlerClickSearchLink);
