import 'Variables';

import { delegate, q, ww } from 'bobjoll/ts/library/dom';
import { getDetail } from 'Partials/detail-v2/helpers/detail.helpers.generic';
import {
    collectionCollectGA4Events,
    collectionQuickAccess,
    createCollectionGA4Events,
} from 'Partials/events/ga4.collections';
import { UserHelpers } from 'Partials/user';

import { Spinner } from '../spinner';
import { setResourcesInCollection } from './collections.api';

if (FEATURE_NEW_COPIES_COLLECTIONS_BY_COUNTRY) {
    delegate('.my-collections', 'click', function(this: HTMLElement) {
        collectionQuickAccess({
            link_text: 'My collections',
            link_url: ww.location.origin + this.getAttribute('href') || '',
            location: 'search_bar',
        });
    });

    delegate('.my-collections-navbar', 'click', function(this: HTMLElement) {
        collectionQuickAccess({
            link_text: 'My collections',
            link_url: ww.location.origin + this.getAttribute('href') || '',
            location: 'nav_bar',
        });
    });
}

delegate('.collection-add', 'click', async function(this: HTMLElement) {
    this.classList.add('button--loading');
    if (await UserHelpers.isLogged(true)) {
        try {
            const spinner = new Spinner(document.body);
            const { default: Collection } = await import('Partials/collections/collection.legacy');
            await Collection.eventHandlerSync.call(this, setResourcesInCollection);
            spinner.done();
        } catch (err) {
            console.error(err);
        }
    }

    this.classList.remove('button--loading');

    if (FEATURE_NEW_COPIES_COLLECTIONS_BY_COUNTRY) {
        const parent = (this.parent('.showcase__item') as HTMLElement) || (this.parent('.detail') as HTMLElement);
        const isDetail = getDetail();
        const collectionId = q('.detail.detail--modal, .detail.new-related')?.getAttribute('data-id');

        collectionCollectGA4Events({
            file_id: isDetail ? collectionId || '' : parent?.getAttribute('data-id') || (collectionId as string) || '',
            file_name:
                parent.dataset.title || q('.title', parent)?.textContent || q('.detail__title h1')?.textContent || '',
            location: isDetail ? 'resource_detail' : 'thumbnail',
        });
    }
});

delegate('.collection-create', 'click', async function(this: HTMLElement, event: Event) {
    event.preventDefault();
    const spinner = new Spinner(document.body);
    const { default: Collection } = await import('Partials/collections/collection.legacy');
    Collection.eventHandlerCreate.call(this, event);
    spinner.done();

    if (FEATURE_NEW_COPIES_COLLECTIONS_BY_COUNTRY) {
        createCollectionGA4Events({
            location: 'my_collections',
        });
    }
});

delegate('.collection-delete', 'click', async function(this: HTMLElement, event: Event) {
    event.preventDefault();
    const spinner = new Spinner(document.body);
    const { default: Collection } = await import('Partials/collections/collection.legacy');
    Collection.eventHandlerDelete.call(this, event);
    spinner.done();
});

delegate('.collection-description', 'click', async function(this: HTMLElement, event: Event) {
    event.preventDefault();
    const spinner = new Spinner(document.body);
    const { default: Collection } = await import('Partials/collections/collection.legacy');
    Collection.eventHandlerDescription.call(this, event);
    spinner.done();
});

delegate('.collection-follow', 'click', async function(this: HTMLElement, event: Event) {
    event.preventDefault();
    const spinner = new Spinner(document.body);
    const { default: Collection } = await import('Partials/collections/collection.legacy');
    Collection.eventHandlerFollow.call(this, event);
    spinner.done();
});

delegate('.collection-remove', 'click', async function(this: HTMLElement, event: Event) {
    event.preventDefault();
    const spinner = new Spinner(document.body);
    const { default: Collection } = await import('Partials/collections/collection.legacy');
    Collection.eventHandlerRemove.call(this, event);
    spinner.done();
});

delegate('.collection-rename', 'click', async function(this: HTMLElement, event: Event) {
    event.preventDefault();
    const spinner = new Spinner(document.body);
    const { default: Collection } = await import('Partials/collections/collection.legacy');
    Collection.eventHandlerRename.call(this, event);
    spinner.done();
});

delegate('.collection-share', 'click', async function(this: HTMLElement, event: Event) {
    event.preventDefault();
    const spinner = new Spinner(document.body);
    const { default: Collection } = await import('Partials/collections/collection.legacy');
    Collection.eventHandlerShare.call(this, event);
    spinner.done();
});

delegate('.collection-type', 'click', async function(this: HTMLElement, event: Event) {
    event.preventDefault();
    const spinner = new Spinner(document.body);
    const { default: Collection } = await import('Partials/collections/collection.legacy');
    Collection.eventHandlerType.call(this, event);
    spinner.done();
});
