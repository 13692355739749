import Notification from 'BobjollNotifications';
import { __ } from 'Partials/language';
import delay from 'Project/ts/library/delay';

const notification = new Notification();
const filterButton = document.querySelector('#header-sort .trigger__button[data-trigger="header-filter"]');

if (filterButton) {
    const filterButtonListener = () => {
        notification.hide('tutorial-filter', true);

        filterButton.removeEventListener('click', filterButtonListener);
    }

    filterButton.addEventListener('click', filterButtonListener);

    ['home', 'search'].forEach(function(view: string) {
        window.addEventListener(`print${view}`, async () => {
            await delay(1000);

            notification.insert({
                id: 'tutorial-filter',
                html: `
                    <h4 class="first mg-bottom-lv1">${__("Check out Freepik's new filters")}</h4>

                    <p class="font-sm mg-none">${__("We included new options to improve your photography results.")}</p>
                `,
                target: '#header button[data-trigger="header-filter"]',
                fixed: true,
                recurrent: true,
                recurrentMax: 3,
                position: 'bottom-right'
            });
        });
    });
}