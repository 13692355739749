import { isSearchByImageEnabled } from 'Partials/search-by-image/image/searchimage.get-image';

import displaySection from '../displaySection';
import { FilterEventConfig } from '../FilterEvents';
import { FilterKeys, FilterProperties, ValidFilterKey } from '../FilterInputs';
import getFilterIdList from '../getFilterIdList';
import mapFiltersToUpdate from '../mapFiltersToUpdate';
import getFilterValue from '../getFilterValue';

class ConditionClarifai {
    private readonly queryKeyName = 'query';
    private readonly typeKeyName = 'type';
    private readonly clarifaiKeyName = 'demographic';
    private readonly peopleKeyName = 'people';
    private filterKeyActivated: ValidFilterKey;
    private filterKeysStatus: FilterKeys;
    private optionItemActivated: HTMLElement;

    constructor(eventConfig: FilterEventConfig) {
        this.filterKeyActivated = eventConfig.filterKey;
        this.filterKeysStatus = eventConfig.filterKeysStatus;
        this.optionItemActivated = eventConfig.optionItem;

        this.apply();
    }

    private get typeFilters(): FilterProperties[] {
        return this.filterKeysStatus[this.typeKeyName];
    }

    private get clarifaiFilters(): FilterProperties[] {
        return this.filterKeysStatus[this.clarifaiKeyName];
    }

    private apply() {
        const { query } = getFilterValue(this.queryKeyName);

        if (isSearchByImageEnabled() || !query) return;

        const fieldName = this.filterKeyActivated.toString();
        const fieldValue = this.optionItemActivated.getAttribute('value') || '';
        const fieldId = this.optionItemActivated.id;
        const clarifaiKeyIds = getFilterIdList(this.clarifaiFilters);
        const typeKeyIds = getFilterIdList(this.typeFilters);

        let disableFilters: string[] = [];
        let enableFilters: string[] = [];

        if (fieldName.match(/type/)) {
            if (fieldValue.match(/photo/)) {
                displaySection(this.peopleKeyName);
            } else {
                disableFilters = [...clarifaiKeyIds, 'filter-people-exclude', 'filter-people-include'];

                displaySection(this.peopleKeyName, false);
            }
        }

        if (fieldName.match(/people/)) {
            if (fieldValue.match(/exclude/)) {
                disableFilters = clarifaiKeyIds;
            }

            if (fieldValue.match(/include|exclude/)) {
                disableFilters = [...disableFilters, ...typeKeyIds];

                enableFilters = ['filter-type-photo'];
            }
        }

        if (fieldName.match(/people/) && fieldId.match(/filter-range|filter-age|ethnicity|gender/)) {
            disableFilters = [...typeKeyIds, 'filter-people-exclude'];

            enableFilters = ['filter-type-photo', 'filter-people-include'];
        }

        mapFiltersToUpdate(disableFilters, false);
        mapFiltersToUpdate(enableFilters);
    }
}

export default ConditionClarifai;
