import { cookie } from '../library/cookie';

const eventHandlerLogin = function(event: StorageEvent) {
    const storage = event.storageArea || ({} as { [name: string]: any });
    const sessionActive = cookie.getItem('gr_session') && cookie.getItem('gr_session2');
    const sessionStatus = storage['session/status'];

    if (sessionActive && '1' == sessionStatus) {
        gr.updateUser();
        gr.updateUI();

        window.removeEventListener('storage', eventHandlerLogin);
        window.addEventListener('storage', eventHandlerLogout);
    }
};

const eventHandlerLogout = function(event: StorageEvent) {
    const storage = event.storageArea || ({} as { [name: string]: any });
    const sessionActive = cookie.getItem('gr_session') && cookie.getItem('gr_session2');
    const sessionStatus = storage['session/status'];

    if (!sessionActive && '0' == sessionStatus) {
        gr.updateUser();
        gr.updateUI();

        window.addEventListener('storage', eventHandlerLogin);
        window.removeEventListener('storage', eventHandlerLogout);
    }
};

window.addEventListener('storage', gr && gr.user ? eventHandlerLogout : eventHandlerLogin);
