(async () => {
    const { waitToBeLoaded } = await import('Project/ts/library/helpers');

    const loadGTMTracker = () => {
        if ('function' === typeof window.gtm) {
            window.gtm();

            dataLayer.push({
                country: USER_COUNTRY_CODE || 'nn',
            });
        }
    };

    if (USER_TYPE === 'anonymous') {
        loadGTMTracker();
        return;
    }

    await waitToBeLoaded('gr.user').then(() => loadGTMTracker());
})();
