import { q } from 'bobjoll/ts/library/dom';
import triggerManualGA4Events from 'Partials/events/ga4.init-manual-events';
import { __ } from 'Partials/language';
import { Optimize } from 'Project/ts/common/optimize';
import { globals } from 'Project/ts/library/helpers/globals';

import notify from '../notify';
import { getData } from './abandonedCart.api';
import { getEvents, showCoupon } from './abandonedCart.helpers';
import { globalMiddleware } from './abandonedCart.middleware';

export const getCoupon = async function() {
    if (showCoupon()) {
        await Optimize.isInitialized();

        const data = getData();
        const { view, click } = getEvents();
        const text = `${__('Finding the right visual is easy with %sPremium%s', '<strong>', '</strong>')}. ${__('%sNow with Flaticon exclusive content.%s', '', '')}`;
        const title = __('%s all Premium plans', '<span class="percent"><strong>20%</strong></span>');
        const button = __('Apply Discount');
        const buttonSize = 'sm';

        const template = await import(`Partials/microfunnels/banner.hbs`);

        const banner = {
            class: 'coupon more-flaticon',
            microfunnel: 'coupon',
            title,
            text,
            button,
            buttonSize,
            coupon: data.code,
        };

        notify.addNotification({
            class: 'notification--microfunnel',
            expires: () => new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
            html: template.default({
                ...globals,
                ...data,
                banner,
            }),
            id: `autopromo-coupon`,
            position: 'bottom-right',
            priority: 5,
            callback: (coupon: HTMLElement) => {
                const [clickCategory, clickLabel] = click;
                const [viewCategory, viewLabel] = view;

                if (coupon) {
                    globalMiddleware.postRender(coupon);

                    coupon.addEventListener('click', (event: Event) => {
                        if (notify.closed(event)) return;

                        q('.notification__close', coupon)?.click();

                        triggerManualGA4Events({
                            description: 'autopromoAbandonedCart',
                            options: {
                                category: clickCategory,
                                action: 'click',
                                label: clickLabel,
                                discount: data.percentage,
                            },
                        });
                    });
                }

                triggerManualGA4Events({
                    description: 'autopromoAbandonedCart',
                    options: {
                        category: viewCategory,
                        action: 'onload',
                        label: viewLabel,
                        discount: data.percentage,
                    },
                });
            },
        });

        notify.printQueue();
    }
};
