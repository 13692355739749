import { Collection } from 'Partials/search/collection/controller';
import { view } from 'Partials/view';

view.addView({
    name: 'collections',
    groups: ['search', 'favorites'],
    template: {
        history: true,
        template: () => import('Project/ts/templates/views/collection.hbs'),
        insert: {
            method: 'innerHTML',
            element: '#main',
            bannerCampaign: 'search',
        },
        loader: true,
        print: true,
        push: true,
        pushTracker: true,
        delay: true,
        lock: true,
        metas: true,
        getXHR: Collection.getData,
        getMetas: Collection.getMetas,
        setMetas: Collection.setMetas,
        callback: Collection.setFilters,
    },
});
