import Ads from 'Partials/google';
import { UserHelpers } from 'Partials/user';

import { AutopromoSlot, slotID, slotIDforVerticalGrid } from '../autopromo';
import { showCampaignBanner } from '../campaign-php/campaign.helpers';

export const AdvertisementSettings = () => {
    const slotGridVersion = FEATURE_VERTICAL_GRID_BY_LANGUAGE ? slotIDforVerticalGrid : slotID;
    return {
        ads: [
            { id: slotGridVersion[AutopromoSlot.LIST_1], list: AutopromoSlot.LIST_1 },
            { id: slotGridVersion[AutopromoSlot.LIST_2], list: AutopromoSlot.LIST_2 },
            { id: slotGridVersion[AutopromoSlot.LIST_3], list: AutopromoSlot.LIST_3 },
        ],
        insert: {
            selector: '#main > .showcase .row',
            method: 'insertAdjacentHTML',
            argument: 'beforeend',
        },
        lazyload: true,
    };
};

export const hideAdServer = () => {
    return UserHelpers.isPremium() || (IS_MOBILE && !FEATURE_MOBILE_ADS_BY_LANGUAGE && !showCampaignBanner());
};

export const initializeAds = () => {
    Ads.push({
        ...AdvertisementSettings(),
    } as any);
};
