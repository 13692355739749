import { cookie } from 'Library/cookie';

export const debugPerformance = () => {
    const option = cookie.getItem('debug_performance');
    if (option) {
        const typePerformance = {
            cls: 'layout-shift',
            lcp: 'largest-contentful-paint',
            fid: 'first-input',
            inp: 'event',
        };
        const observeType = typePerformance[option];

        try {
            console.log(`--- PERFORMANCE ${option} ---`);
            let hiddenTime = document.visibilityState === 'hidden' ? 0 : Infinity;
            let maxDuration = 0;

            document.addEventListener(
                'visibilitychange',
                event => {
                    hiddenTime = Math.min(hiddenTime, event.timeStamp);
                },
                { once: true }
            );

            new PerformanceObserver(entryList => {
                entryList.getEntries().forEach(entry => {
                    if (option === 'inp') {
                        if (entry.duration > maxDuration) {
                            maxDuration = entry.duration;
                            console.log(`[INP] duration: ${entry.duration}, type: ${entry.name}`, entry);
                        } else {
                            console.log(`[Interaction] duration: ${entry.duration}, type: ${entry.name}`, entry);
                        }
                    } else {
                        if (entry.startTime < hiddenTime) {
                            console.log(entry);
                        }
                    }
                });
            }).observe({
                type: observeType,
                buffered: true,
            });
        } catch (e) {
            console.error('Performance API or observe type not supported');
        }
    }
}
