import { q, qq, ww } from 'bobjoll/ts/library/dom';
import { isTablet } from 'Library/helpers/device';
import { Arguments } from 'Project/ts/library/arguments';

import { ga4 } from './ga4.api';

const categoryTypes = {
    vector: 'vectors',
    photo: 'photos',
    psd: 'psd',
    mockup: 'mockups',
    template: 'templates',
    icon: 'icons',
};

const sortTypes = {
    // without query
    popular: 'popular',
    recent: 'recent',
    // with query
    most_relevant: 'most_relevant',
};

const clarifaiTypes = {
    number1: '1 person',
    number2: '2 people',
    number3: '3 people',
    number4: '4+ people',
    masculine: 'male',
    feminine: 'female',
    southasian: 'south asian',
    middleeastern: 'middle eastern',
    eastasian: 'east asian',
};

const checkFilterEvents: FilterEvents[] = [
    {
        multiple: true,
        name: 'category',
        element: '.filter-sidebar details[data-key="type"] input',
        attribute: 'value',
        replaceType: categoryTypes,
    },
    {
        multiple: true,
        name: 'license',
        element: '.filter-sidebar details[data-key="license"] input',
        attribute: 'value',
    },
    {
        multiple: true,
        name: 'ai',
        element: '.filter-sidebar details[data-key="ai"] input',
        attribute: 'value',
    },
    {
        multiple: true,
        name: 'file_type',
        element: '.filter-sidebar details[data-key="file_type"] input',
        attribute: 'value',
    },
    {
        multiple: true,
        name: 'people',
        element: '.filter-sidebar details[data-key="people"] input',
        attribute: 'value',
    },
    {
        multiple: true,
        name: 'demographic',
        element: '.filter-sidebar input[data-key="demographic"]',
        attribute: 'value',
        replaceType: clarifaiTypes,
    },
    {
        multiple: true,
        name: 'color',
        element: '.filter-sidebar details[data-key="color"] input[data-option="all"]', // only for clear event
        attribute: 'value',
        removeType: 'color-',
    },
    {
        multiple: true,
        name: 'color',
        element: '.filter-sidebar details[data-key="color"] input:not([data-option="all"])',
        attribute: 'value',
    },
    {
        multiple: true,
        name: 'style',
        element: '.filter-sidebar details[data-key="style"] input',
        attribute: 'value',
    },
    {
        multiple: true,
        name: 'orientation',
        element: '.filter-sidebar details[data-key="orientation"] input',
        attribute: 'value',
    },
    {
        multiple: false,
        name: 'fp-choice',
        element: '.filter-sidebar details[data-key="freepik_choice"] input',
        attribute: 'checked',
    },
    {
        multiple: false,
        name: 'edit-online',
        element: '.filter-sidebar details[data-key="edit_online"] input',
        attribute: 'checked',
    },
    {
        multiple: false,
        name: 'undiscovered',
        element: '.filter-sidebar details[data-key="undiscovered"] input',
        attribute: 'checked',
    },
    {
        multiple: true,
        name: 'ai-model',
        element: '.filter-sidebar details[data-key="ai_model"] input',
        attribute: 'value',
    },
    {
        multiple: false,
        name: 'prompt',
        element: '.filter-sidebar details[data-key="prompt"] input',
        attribute: 'checked',
    },
];

export const filterApplyEventGA4 = (url: string): void => {
    const filtersToRemove = ['format', 'query', 'from_query', 'page', 'selection', 'premium', 'essential'];
    const getAllFilterValues = Arguments.stringToObject(url);
    const appliedFiltersKeys = Object.keys(getAllFilterValues);

    const filterNameParamArray = appliedFiltersKeys
        .filter(filter => filter !== '' && !filtersToRemove.includes(filter))
        .map(filter => {
            const filterValue = getAllFilterValues[filter]
                .toString()
                .split(',')
                .map((value: string) => value.substring(0, 3))
                .join(',');

            const filterNameDictionary = {
                type: 'cat',
                freepik_choice: 'cho',
            };

            const filterName = filterNameDictionary[filter] || filter.substring(0, 3);

            return `${filterName}:${filterValue}`;
        });

    const licenseFilter = licenseFilterForGA4Event(appliedFiltersKeys);
    licenseFilter && filterNameParamArray.push(licenseFilter);

    ga4({
        event_name: 'filter_apply',
        custom_parameter: 'filter_name',
        filter_name: filterNameParamArray.join(' | '),
    });
};

const licenseFilterForGA4Event = (appliedFiltersKeys: string[]) => {
    const licenseFiltersDictionary = {
        selection: 'free',
        premium: 'pre',
        essential: 'ess',
    };

    const licenseFilters = appliedFiltersKeys
        .filter(filter => Object.keys(licenseFiltersDictionary).includes(filter))
        .map(licenseFilter => licenseFiltersDictionary[licenseFilter]);

    return licenseFilters.length > 0 ? `lic:${licenseFilters.join(',')}` : null;
};

export const filterEventsGA4 = () => {
    checkFilterEvents.forEach(filterEvent => {
        if (filterEvent.multiple) {
            qq(filterEvent.element)?.forEach((label: HTMLElement) => {
                label.addEventListener(filterEvent.listener ?? 'click', () => {
                    if (!isTablet()) {
                        const value = label.getAttribute(filterEvent.attribute) ?? '';
                        const type = (filterEvent.replaceType && filterEvent.replaceType[value]) ?? value;
                        const finalValue = filterEvent.removeType ? type.replace(filterEvent.removeType, '') : type;
                        sendFilterEventGA4(filterEvent.name, finalValue);
                    }
                });
            });
        } else {
            q(filterEvent.element)?.addEventListener(filterEvent.listener ?? 'click', (event: Event) => {
                if (!isTablet() && event.target) {
                    const value = event.target[filterEvent.attribute] ? 'yes' : 'no';
                    sendFilterEventGA4(filterEvent.name, value);
                }
            });
        }
    });
};

export const filterSortByEventGA4 = (value: string) => {
    if (value === 'popular' && ww.FiltersEnabled['query']) {
        value = 'most_relevant';
    }
    ga4({
        event_name: 'sort_by',
        sort_option: sortTypes[value] || '',
    });
};

const sendFilterEventGA4 = (filterName: string, filterValue: string) => {
    ga4({
        event_name: 'filter_selection',
        custom_parameter: 'filter_name',
        filter_name: `${filterName}: ${filterValue}`,
        filter_category: filterName,
        filter_selected_value: filterValue,
    });
};

interface FilterEvents {
    attribute: string;
    element: string;
    listener?: 'click' | 'change';
    multiple: boolean;
    name: string;
    removeType?: string;
    replaceType?: {};
}
