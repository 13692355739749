import { Author } from 'Partials/search/author/controller';
import { view } from 'Partials/view';

view.addView({
    name: 'author',
    groups: ['search', 'favorites'],
    template: {
        history: true,
        insert: {
            method: 'innerHTML',
            element: '#main'
        },
        loader: true,
        delay: true,
        print: true,
        lock: true,
        push: true,
        pushTracker: true,
        metas: true,
        getMetas: Author.getMetas,
        setMetas: Author.setMetas,
        callback: Author.setFilters
    }
});
