import {
    GET_REAL_KEYS_FROM_DROPDOWN_SORT_EXCEPTION,
    QUERY_PARAMS_EXCEPTIONS_FOR_LAST_FILTER_CLICKED,
} from '../constants';
import { ValidFilterKey } from '../FilterInputs';
import getFilterValue from '../getFilterValue';

const getLastFilter = (
    enabledFilters: Record<string, string[]>,
    optionItem: HTMLElement,
    filterKey: ValidFilterKey,
) => {
    const { key, value } = getValueFromInputOrFromException(optionItem, filterKey.toString());

    const { key: lastKeyClicked, value: lastValueClicked } = transformExceptionWithRealKeys(key, value);

    if (!lastKeyClicked || !lastValueClicked) {
        delete enabledFilters['last_filter'];
        delete enabledFilters['last_value'];
        return;
    }

    enabledFilters['last_filter'] = [lastKeyClicked];
    enabledFilters['last_value'] = [lastValueClicked];
};

const getValueFromInputOrFromException = (optionItem: HTMLElement, key: string): GetLastFilterConfig => {
    const realKeyFromSortByDropdown = GET_REAL_KEYS_FROM_DROPDOWN_SORT_EXCEPTION[key];

    if (realKeyFromSortByDropdown) {
        const dropdownValue = (optionItem as HTMLSelectElement).value;
        const realKey = realKeyFromSortByDropdown[dropdownValue];
        const valueFromKey = getFilterValue(realKey);

        return {
            key: realKey,
            value: valueFromKey[Object.keys(valueFromKey)[0]],
        };
    }

    return {
        key: optionItem.dataset.key || '',
        value: optionItem.getAttribute('value') || '',
    };
};

const transformExceptionWithRealKeys = (lastKeyClicked: string, lastValueClicked: string): GetLastFilterConfig => {
    if (
        QUERY_PARAMS_EXCEPTIONS_FOR_LAST_FILTER_CLICKED[lastKeyClicked] &&
        QUERY_PARAMS_EXCEPTIONS_FOR_LAST_FILTER_CLICKED[lastKeyClicked][lastValueClicked]
    ) {
        return QUERY_PARAMS_EXCEPTIONS_FOR_LAST_FILTER_CLICKED[lastKeyClicked][lastValueClicked];
    }

    return {
        key: lastKeyClicked,
        value: lastValueClicked,
    };
};

interface GetLastFilterConfig {
    key: string;
    value: string;
}

export default getLastFilter;
