import { categoryLandingAutopromoEventsGa4 } from './ga4.category-landing';
import { filterEventsGA4 } from './ga4.filter';
import triggerAutomaticGA4Events from './ga4.init-automatic-events';
import { tagEventsGA4 } from './ga4.tag';

filterEventsGA4();
tagEventsGA4();
triggerAutomaticGA4Events();

!FEATURE_AD_MANAGER && categoryLandingAutopromoEventsGa4();
