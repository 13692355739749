const getSearchURL = (enabledFilters: Record<string, string[]>) => {
    const filterKeys = Object.keys(enabledFilters);
    const filters: string[] = [];

    filterKeys
        .sort((a, b) => {
            return a < b ? -1 : 1;
        })
        .forEach(filterKey => {
            const enabled = enabledFilters[filterKey];
            enabled && enabled.length > 0 && filters.push(encodeURL(filterKey, enabled));
        });

    return filters.join('&');
};

const encodeURL = (filterKey: string, enabled: string[]) => {
    return encodeURIComponent(filterKey) + '=' + encodeURIComponent(enabled.join(','));
};

export default getSearchURL;
