export const MODAL_ID = 'modal-advanced-search';
export const FORM_ID = 'form-advanced-search';
export const TRIGGER_MODAL_DATASET_ELEMENT = 'advanced-search';
export const ADVANCEDSEARCH_TERM_ID = 'advancedsearch-term';
export const ADVANCEDSEARCH_EXCLUDED_TERMS_ID = 'advancedsearch-excluded-terms';
export const ADVANCEDSEARCH_COUNTRY_ID = 'advancedsearch-country';
export const ADVANCEDSEARCH_SUBMIT_ID = 'advancedsearch-submit';
export const ADVANCEDSEARCH_QUERY_INPUT_ID = 'advancedsearch-query';
export const ADVANCEDSEARCH_REMOVE_COUNTRY_BTN_ID = 'remove_country_code_advanced_search_button';
export const ADVANCEDSEARCH_REMOVE_EXCLUDE_TERMS_BTN_ID = 'remove_exclude_terms_button';
