import { ww } from 'bobjoll/ts/library/dom';
import { sessionStorage } from 'bobjoll/ts/library/storage';

import { SEARCH_URL, STORAGE_NAME_SAVE_LAST_FILTERS } from '../constants';
import { FilterEventConfig } from '../FilterEvents';
import { FilterKeys, FilterProperties, ValidFilterKey } from '../FilterInputs';
import getFilterIdList from '../getFilterIdList';
import getFilterValue from '../getFilterValue';
import getSearchURL from '../getSearchURL';
import mapFiltersToUpdate from '../mapFiltersToUpdate';

class ConditionTabCounters {
    private readonly collectionKey = 'collections';
    private filterKeyActivated: ValidFilterKey;
    private optionItemActivated: HTMLElement;
    private static filterKeysStatus: FilterKeys;
    public static readonly removeFiltersKeyForCollections = [
        'display',
        'color',
        'orientation',
        'freepik_choice',
        'people',
        'demographic',
        'edit_online',
        'style',
        'file_type',
        'undiscovered',
        'ai',
        'ai_model',
        'prompt',
    ];
    public static readonly removeFilterTypeKeyForCollections = [
        'filter-type-video',
        'filter-type-template',
        'filter-type-mockup',
    ];

    constructor(eventConfig: FilterEventConfig) {
        this.filterKeyActivated = eventConfig.filterKey;
        ConditionTabCounters.filterKeysStatus = eventConfig.filterKeysStatus;
        this.optionItemActivated = eventConfig.optionItem;
    }

    private get formatFilters(): FilterProperties[] {
        return ConditionTabCounters.filterKeysStatus[this.filterKeyActivated.toString()];
    }

    private get page(): number {
        const { page } = getFilterValue('page', ConditionTabCounters.filterKeysStatus);
        return (page && parseInt(page)) || 1;
    }

    public static get disableFiltersForCollections(): string[] {
        let filters: string[] = [];
        ConditionTabCounters.removeFiltersKeyForCollections.forEach(key => {
            const keyStatus = ConditionTabCounters.filterKeysStatus || ww.Filters;
            const filterList = keyStatus[key];

            if (filterList) {
                filters = [...filters, ...getFilterIdList(filterList)];
            }
        });
        return filters;
    }

    private saveResourceFilters() {
        ww.FiltersEnabled &&
            sessionStorage.setItem(
                STORAGE_NAME_SAVE_LAST_FILTERS.namespace,
                STORAGE_NAME_SAVE_LAST_FILTERS.key,
                JSON.stringify(ww.FiltersEnabled),
            );
    }

    private returnSearchURLWithDeletedFilters() {
        const lastFiltersChecked = sessionStorage.getItem(
            STORAGE_NAME_SAVE_LAST_FILTERS.namespace,
            STORAGE_NAME_SAVE_LAST_FILTERS.key,
        );
        let redirect = null;

        if (lastFiltersChecked) {
            try {
                const lastFilters = JSON.parse(lastFiltersChecked);
                const enableFilters: Record<string, string[]> = {};

                ConditionTabCounters.removeFiltersKeyForCollections.forEach(key => {
                    if (lastFilters[key]) enableFilters[key] = lastFilters[key];
                });

                if (Object.keys(enableFilters).length === 0) return;

                ww.FiltersEnabled = {
                    ...ww.FiltersEnabled,
                    format: ['search'],
                    ...enableFilters,
                };

                redirect = `${SEARCH_URL}?${getSearchURL(ww.FiltersEnabled)}`;
            } catch (error) {
                console.error('Error trying to parse last filters checked from the resource tab');
            }

            sessionStorage.removeItem(STORAGE_NAME_SAVE_LAST_FILTERS.namespace, STORAGE_NAME_SAVE_LAST_FILTERS.key);
        }

        return redirect;
    }

    public apply() {
        const optionClicked = this.optionItemActivated.dataset.format;
        const isKeywordLanding = this.optionItemActivated.dataset.isKeyword;

        if (!optionClicked) return;

        const changeToCollections = optionClicked === this.collectionKey;
        changeToCollections && this.saveResourceFilters();

        if (isKeywordLanding) {
            const typeParam = changeToCollections ? `?format=${this.collectionKey}` : '';
            let path = window.location.pathname;

            if (this.page > 1) {
                path = path.replace(/\/[^\/]*$/, '');
            }

            return {
                redirect: `${window.location.origin}${path}${typeParam}`,
            };
        }

        const redirectWithOldFilters = !changeToCollections && this.returnSearchURLWithDeletedFilters();
        if (redirectWithOldFilters) {
            return {
                redirect: redirectWithOldFilters,
            };
        }

        const formatKeyIds = getFilterIdList(this.formatFilters);
        const enableFilters: string[] = [];
        let disableFilters: string[] = [];

        formatKeyIds.some(id => {
            const arrayToInsert = id.includes(optionClicked) ? enableFilters : disableFilters;
            arrayToInsert.push(id);
        });

        if (optionClicked === this.collectionKey) {
            disableFilters = [...disableFilters, ...ConditionTabCounters.disableFiltersForCollections];
        }

        mapFiltersToUpdate(disableFilters, false);
        mapFiltersToUpdate(enableFilters);

        return true;
    }
}

export default ConditionTabCounters;
