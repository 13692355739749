import { q, qq } from 'bobjoll/ts/library/dom';
import Carousel from 'Partials/slider-scroll';

export const setCarousel = function() {
    if (!q('.carousel')) {
        return;
    }

    qq('.carousel').forEach(element => {
        const carousel = new Carousel(element, { slideSelector: '.carousel__item' });

        if (carousel.active) {
            const lastElement = qq('.carousel__item', element).pop();
            const { right = element.clientWidth } = lastElement?.getBoundingClientRect() || {};
            const lastElementVisible = (right - 10) < element.clientWidth;
            const carouselPrev = document.getElementById('carousel-prev');
            const carouselNext = document.getElementById('carousel-next');

            if (carouselPrev) {
                if (element.scrollLeft > 0) {
                    carouselPrev.style.display = 'block';
                }
                carouselPrev.addEventListener('click', () => carousel.navigate('prev'));
            }

            if (carouselNext) {
                carouselNext.style.display = !lastElementVisible ? 'block' : 'none';
                carouselNext.addEventListener('click', () => carousel.navigate('next'));
            }

            element.addEventListener('scroll', () => {
                if (carouselPrev) {
                    carouselPrev.style.display = element.scrollLeft > 0 ? 'block' : 'none';
                }

                if (carouselNext) {
                    carouselNext.style.display = lastElementVisible ? 'none' : 'block';
                }
            });
        }
    });
};