import { initializeDropdown } from 'bobjoll/ts/partials/dropdown-v1.0';
import {
    ADVANCEDSEARCH_COUNTRY_ID,
    ADVANCEDSEARCH_EXCLUDED_TERMS_ID,
    ADVANCEDSEARCH_TERM_ID,
} from '../advancedsearch.constants';

const excludeTermsRegex = new RegExp(/^[\W\s]+.$/);

export const searchTermIsValid = (searchTearsm: string) => {
    const regex = new RegExp(/^[a-zA-Z0-9 ]+$/);
    return regex.test(searchTearsm);
};

export const excludeTermsIsValid = (excludeTerms: string) => {
    const regex = new RegExp(excludeTermsRegex);
    return !regex.test(excludeTerms) || excludeTerms === '';
};

export const regularizeExcludeTerms = (excludeTerms: string) => {
    if (!excludeTerms.length) return '';

    const terms: string[] = excludeTerms.split(',').map(term => {
        const t = term.trim();
        return t.replace(excludeTermsRegex, '');
    });
    const cleanExcludeTerms = terms.map(term => {
        if (term.includes(' ')) {
            return `-"${term}"`;
        }
        return `-${term}`;
    });

    return cleanExcludeTerms.join(' ');
};

export const getSearchedTermFromUrl = () => {
    const url = new URL(window.location.href);
    const query = url.searchParams.get('query');

    if (!query) return;

    const queryArray = query.split(' -');
    return queryArray[0];
};

export const getExcludeTermsFromUrl = () => {
    const url = new URL(window.location.href);
    const query = url.searchParams.get('query');

    if (!query) return;

    const queryArray = query.split(' -');

    if (queryArray.length <= 1) return '';

    const regularizedTerms = queryArray.reduce((acc, term, index) => {
        if (index === 0) return '';

        const cleanedTerm = term.replace('"', '').trim();

        if (index === 1) return cleanedTerm;
        return `${acc}, ${cleanedTerm}`;
    });
    return regularizedTerms;
};

export const getCountryCodeFromUrl = () => {
    const url = new URL(window.location.href);
    const country = url.searchParams.get('country_code');

    if (!country) return;

    return country;
};

export const resetAdvancedSearchForm = () => {
    const searchTerm = document.getElementById(ADVANCEDSEARCH_TERM_ID) as HTMLInputElement | undefined;
    const excludeTerm = document.getElementById(ADVANCEDSEARCH_EXCLUDED_TERMS_ID) as HTMLInputElement | undefined;
    const countrySelect = document.getElementById(ADVANCEDSEARCH_COUNTRY_ID) as HTMLSelectElement | undefined;

    if (searchTerm) searchTerm.value = '';
    if (excludeTerm) excludeTerm.value = '';
    if (countrySelect) {
        countrySelect.value = '';
        const parent = countrySelect.parentElement;
        parent?.classList.remove('loaded');
        parent?.getElementsByClassName('dropdown__container')[0]?.remove();

        initializeDropdown(true);
    }
};
