import 'Variables';

import { q } from 'bobjoll/ts/library/dom';

export class RecaptchaV2 {
    private static api = '//www.google.com/recaptcha/api.js?onload=onScriptLoad&render=explicit';
    public static grecaptcha: any;
    public defaultSettings = {
        recaptcha: navigator.userAgent.match(/Ghost Inspector/gi) ? false : true,
        recaptchaSitekey: RE_CAPTCHA_KEY_INVISIBLE,
    };
    public nameHandler = '';
    public loaded: RecaptchaActive = {};
    public disable: RecaptchaActive = {};
    public handlers: Handlers = {};
    public widgets: Widgets = {};

    constructor(name: string) {
        this.nameHandler = name;
        this.loaded[name] = false;
        this.handlers[name] = null;
        this.disable[name] = false;
        this.widgets[name] = null;
    }

    public async load(): Promise<{}> {
        if (q(`#recaptcha-sdk-${this.nameHandler}`)) return false;

        const fjs = document.getElementsByTagName('script')[0];
        const js = document.createElement('script') as HTMLScriptElement;

        let unexpectedError = false;

        js.id = 'recaptcha-sdk-' + this.nameHandler;
        js.src = RecaptchaV2.api;

        if (FEATURE_CHECK_RECAPTCHA_STATUS_BY_COUNTRY) {
            js.onerror = (event, source, lineno, colno, error) => {
                if (typeof event === 'object' && event.type === 'error') {
                    unexpectedError = true;

                    const errorInfo = `${source} ${lineno}:${colno}`;
                    const errorMessage = error?.message || error || 'Log: Failed loading recaptcha by onerror event';
                    console.error(`${errorMessage} - ${errorInfo}`);
                }
            };
        }

        if (fjs.parentNode) fjs.parentNode.insertBefore(js, fjs);

        return new Promise((resolve, reject) => {
            (window as any).onScriptLoad = resolve;

            this.loaded[this.nameHandler] = true;

            setTimeout(() => {
                if (FEATURE_CHECK_RECAPTCHA_STATUS_BY_COUNTRY && unexpectedError) {
                    console.error(`Log: Can't load recaptcha file from ${RecaptchaV2.api}`);
                }
                reject('Failed loading recaptcha!');
            }, 15000);
        });
    }

    public render(captchaElement: HTMLElement | null, callback: Function, show = false) {
        if (captchaElement) {
            const captchaOptions: CaptchaOptions = {
                sitekey: this.defaultSettings.recaptchaSitekey,
                callback,
            };

            if (!show) captchaOptions.size = 'invisible';

            if (this.widgets[this.nameHandler]) {
                grecaptcha.reset(this.widgets[this.nameHandler]);
            }

            try {
                this.widgets[this.nameHandler] = grecaptcha.render(captchaElement, captchaOptions, true);
            } catch (err) {
                console.error(err);
            }
        }
        return this.widgets[this.nameHandler];
    }
}

interface Handlers {
    [key: string]: null | FormHandlers;
}

interface RecaptchaActive {
    [key: string]: boolean;
}

interface Widgets {
    [key: string]: null | string;
}

interface CaptchaOptions {
    callback: Function;
    sitekey: string;
    size?: string;
}

type FormHandlers = (this: HTMLElement, event: Event) => void;
