import { q, qq, ww } from 'bobjoll/ts/library/dom';

import ConditionAI from '../ConditionAI';
import ConditionClarifai from '../ConditionClarifai';
import ConditionEditOnline from '../ConditionEditOnline';
import ConditionGraphicStyles from '../ConditionGraphicStyles';
import ConditionTabCounters from '../ConditionTabCounters';
import displaySection from '../displaySection';
import FilterInputs, { FilterKeys, FilterProperties, ValidFilterKey } from '../FilterInputs';
import getFilterIdList from '../getFilterIdList';
import mapFiltersToUpdate from '../mapFiltersToUpdate';
import ConditionColor from '../ConditionColor';
import displayInput from '../displayInput';

class SearchDropdown {
    private readonly typeKeyName = 'type';
    private forValueReference = {
        'format-search': {
            key: 'format',
            id: 'filter-format-search',
        },
        'format-collections': {
            key: 'format',
            id: 'filter-format-collections',
        },
        'format-models': {
            key: 'format',
            id: 'filter-format-models',
        },
        'selection-1': {
            key: 'license',
            id: 'filter-license-free',
        },
        'essential-1': {
            key: 'license',
            id: 'filter-license-essential',
        },
        'premium-1': {
            key: 'license',
            id: 'filter-license-premium',
        },
        'ai-only': {
            key: 'ai',
            id: 'filter-ai-only',
        },
        'type-vector': {
            key: 'type',
            id: 'filter-type-vector',
        },
        'type-photo': {
            key: 'type',
            id: 'filter-type-photo',
        },
        'type-video': {
            key: 'type',
            id: 'filter-type-video',
        },
        'type-psd': {
            // remove this block after consolidate the FF mockups_templates_filters
            key: 'type',
            id: 'filter-type-psd',
        },
        'type-icon': {
            key: 'type',
            id: 'filter-type-icon',
        },
        'type-template': {
            key: 'type',
            id: 'filter-type-template',
        },
        'type-mockup': {
            key: 'type',
            id: 'filter-type-mockup',
        },
    };
    private inputElements: HTMLElement[];
    private filterKeysStatus: FilterKeys;

    constructor({
        filterKeysStatus = ww.Filters,
        inputSelector = '[data-field-name]',
        dropdownSelector = '.search__dropdown',
    }: SearchDropdownConfig = {}) {
        const dropdownElement = qq(dropdownSelector) as HTMLElement[];
        if (dropdownElement.length === 0) return;

        let inputElements: HTMLElement[] = [];
        dropdownElement.forEach((dropdown: HTMLElement) => {
            const inputs = qq(inputSelector, dropdown) as HTMLElement[];
            inputElements = [...inputElements, ...inputs];
        });

        if (inputElements.length === 0) return;

        this.inputElements = inputElements;
        this.filterKeysStatus = filterKeysStatus;

        this.addEvents();
    }

    private addEvents() {
        this.inputElements.forEach((label: HTMLLabelElement) => {
            const { id, key, realFilterElement } = this.getRealFilter(label);

            label.addEventListener('click', () => {
                const isEnabled = label.classList.contains('active');
                const preventUpdate = isEnabled && label.classList.contains('radio');
                !preventUpdate && mapFiltersToUpdate([id], !isEnabled);

                key === 'type' && realFilterElement && this.typeException(key, realFilterElement);

                key === 'format' && this.displaySectionsByFormat(id.match(/collections/));
            });
        });
    }

    private getRealFilter(label: HTMLLabelElement) {
        const labelReference = label.getAttribute('for');
        const realFilter = labelReference && this.forValueReference[labelReference];
        const { key = '', id = '' } = realFilter;
        const realFilterElement = document.getElementById(id) as HTMLInputElement;
        return {
            id,
            key,
            realFilterElement,
        };
    }

    private get typeFilters(): FilterProperties[] {
        return this.filterKeysStatus[this.typeKeyName];
    }

    private typeException(key: string, realFilterElement: HTMLInputElement) {
        const formatCollectionElement = document.getElementById('filter-format-collections') as
            | HTMLInputElement
            | undefined;

        if (formatCollectionElement && formatCollectionElement.checked) {
            const typeKeyIds = getFilterIdList(this.typeFilters);
            const fieldId = realFilterElement.id;

            const disableFilters = [...typeKeyIds];
            const enableFilters = [fieldId];

            mapFiltersToUpdate(disableFilters, false);
            mapFiltersToUpdate(enableFilters);

            return;
        }

        const eventConfig = {
            filterKey: key as ValidFilterKey,
            filterKeysStatus: this.filterKeysStatus,
            optionItem: realFilterElement,
        };

        FilterInputs.convertCheckboxToRadioButton(realFilterElement, `filter-${key}`);

        new ConditionClarifai(eventConfig);
        new ConditionAI(eventConfig);
        new ConditionGraphicStyles(eventConfig);
        new ConditionEditOnline(eventConfig);
        new ConditionColor(eventConfig);
    }

    private displaySectionsByFormat(isCollectionFormat: boolean) {
        const typeFilterElement = q('input[name="filter-type"]:checked') as HTMLInputElement | undefined;
        const show = !isCollectionFormat;

        ConditionTabCounters.removeFiltersKeyForCollections.forEach(keyName => displaySection(keyName, show));

        typeFilterElement && this.typeException('type', typeFilterElement);

        displayInput(ConditionTabCounters.removeFilterTypeKeyForCollections, show);
    }
}

interface SearchDropdownConfig {
    dropdownSelector?: string;
    filterKeysStatus?: FilterKeys;
    inputSelector?: string;
}

export default SearchDropdown;
