import { qq } from 'bobjoll/ts/library/dom';
import { isSearchByImageEnabled } from 'Partials/search-by-image/image/searchimage.get-image';

import concatUUIDSearchTracker from '../concatUUIDSearchTracker';
import { MODEL_SEARCH_URL, SEARCH_URL } from '../constants';
import { FilterKeys, FilterProperties, ValidFilterKey } from '../FilterInputs';
import getEnabledFilters from '../getEnabledFilters';
import getFilterIdList from '../getFilterIdList';
import getFilterValue from '../getFilterValue';
import getLastFilter from '../getLastFilter';
import getSearchURL from '../getSearchURL';
import mapFiltersToUpdate from '../mapFiltersToUpdate';
import redirectToUrl from '../redirectToUrl';
import SearchByImage from '../SearchByImage';

class FilterSearch {
    private sortKeyName = 'sort';
    private queryKeyName = 'query';
    private filterKeysStatus: FilterKeys;
    private resetFilters: boolean;
    private newSearch: boolean;
    private modelSearch: boolean;
    private filterKeyActivated: ValidFilterKey;
    private optionItemActivated: HTMLElement;
    private concatUUIDSearch: boolean;

    constructor({
        filterKeysStatus,
        resetFilters = true,
        newSearch = false,
        isModelSearch = false,
        optionItemActivated,
        filterKeyActivated,
        concatUUIDSearch = false,
    }: FilterSearchConfig) {
        this.filterKeysStatus = filterKeysStatus;
        this.resetFilters = resetFilters;
        this.newSearch = newSearch;
        this.modelSearch = isModelSearch;
        this.filterKeyActivated = filterKeyActivated;
        this.filterKeysStatus = filterKeysStatus;
        this.optionItemActivated = optionItemActivated;
        this.concatUUIDSearch = concatUUIDSearch;
    }

    private get sortFilters(): FilterProperties[] {
        return this.filterKeysStatus[this.sortKeyName];
    }

    public goToSearchURL(callback?: Function) {
        const { enabledFilters, searchURL } = this.setFilterStatusAndGetEnabled();

        if (callback) callback(enabledFilters);

        const url = `${this.modelSearch ? MODEL_SEARCH_URL : SEARCH_URL}?${searchURL}`;
        const finalUrl = this.concatUUIDSearch ? concatUUIDSearchTracker(url) : url;

        isSearchByImageEnabled() && 'img' in enabledFilters
            ? SearchByImage.goToSearchByImage(finalUrl)
            : redirectToUrl(finalUrl, this.newSearch);
    }

    private setFilterStatusAndGetEnabled(): FilterSearchResponse {
        this.resetFilters && this.setPaginationToOne();

        this.setSortByToPopularAfterSearch();

        const enabledFilters = getEnabledFilters(this.filterKeysStatus, true);

        if (isSearchByImageEnabled() && 'query' in enabledFilters) {
            delete enabledFilters['img'];
        }

        FEATURE_REDUCE_404_RESULTS_REMOVING_LAST_PARAM &&
            getLastFilter(enabledFilters, this.optionItemActivated, this.filterKeyActivated);

        const searchURL = getSearchURL(enabledFilters);

        if (IS_MOBILE) {
            const parent = this.optionItemActivated.closest('details');
            if (parent) {
                qq('input[data-option]', parent).forEach((input: HTMLInputElement) => {
                    input.classList.remove('active');

                    input.name === (this.optionItemActivated as HTMLInputElement).name &&
                        this.optionItemActivated.classList.add('active');
                });
            }
        }

        return {
            enabledFilters,
            searchURL,
        };
    }

    private setPaginationToOne() {
        const pageKeyId = getFilterIdList(this.filterKeysStatus['page'])[0];
        if (!pageKeyId) return;

        const inputPageFilter = document.getElementById(pageKeyId) as HTMLInputElement;
        inputPageFilter.value = '1';
    }

    private setSortByToPopularAfterSearch() {
        const { sort } = getFilterValue(this.sortKeyName);
        const recentFilterValue = 'recent';

        if (this.filterKeyActivated === this.queryKeyName && sort === recentFilterValue) {
            const sortKeyIds = getFilterIdList(this.sortFilters);
            const disableFilters: string[] = [...sortKeyIds];
            const enableFilters: string[] = ['filter-sort-popular'];

            mapFiltersToUpdate(disableFilters, false);
            mapFiltersToUpdate(enableFilters);
        }
    }
}

interface FilterSearchConfig {
    concatUUIDSearch: boolean;
    filterKeyActivated: ValidFilterKey;
    filterKeysStatus: FilterKeys;
    isModelSearch: boolean;
    newSearch: boolean;
    optionItemActivated: HTMLElement;
    resetFilters: boolean;
}

interface FilterSearchResponse {
    enabledFilters: Record<string, string[]>;
    searchURL: string;
}

export default FilterSearch;
