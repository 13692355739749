import displaySection from '../displaySection';
import { FilterEventConfig } from '../FilterEvents';
import { ValidFilterKey } from '../FilterInputs';
import mapFiltersToUpdate from '../mapFiltersToUpdate';

class ConditionEditOnline {
    private readonly editKeyName = 'edit_online';
    private filterKeyActivated: ValidFilterKey;
    private optionItemActivated: HTMLElement;

    constructor(eventConfig: FilterEventConfig) {
        this.filterKeyActivated = eventConfig.filterKey;
        this.optionItemActivated = eventConfig.optionItem;

        this.apply();
    }

    private apply() {
        const fieldName = this.filterKeyActivated.toString();
        const fieldValue = this.optionItemActivated.getAttribute('value') || '';
        const iconType = fieldName.match(/type/) && fieldValue.match(/icon/);
        const disableFilters: string[] = iconType ? ['filter-edit_online-1'] : [];

        mapFiltersToUpdate(disableFilters, false);
        displaySection(this.editKeyName, !iconType);
    }
}

export default ConditionEditOnline;
