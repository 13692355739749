import { bytesToSize } from 'Project/ts/library/helpers';

export const TRIGGER_MODAL_DATASET_ELEMENT = 'search-by-image';
export const MODAL_ID = 'modal-search-by-image';
export const FORM_ID = 'form-search-by-image';
export const BASE_64_INPUT_ID = 'form-search-by-image--base-64';
export const FORMAT_INPUT_ID = 'form-search-by-image--format';
export const IMAGE_PREVIEW_ID = 'form-search-by-image--preview';
export const UPLOADING_TEXT_ID = 'form-search-by-image--uploading-text';
export const FILE_ID = 'form-search-by-image--file';

export const UPLOADING_TEXTS = [
    'Hang tight while the browser makes its magic.',
    'Sending pixels on a virtual journey.',
    'Finding a match. One pixel at a time.',
];
export const UPDATE_TEXT_EVERY_MS = 2000;
export const TEXT_VALID_TYPES = ['JPG', 'PNG'];
export const ERROR_VALID_TYPES = 'This format isn’t supported. Please upload an image in %s or %s formats.';
export const ERROR_SIZE = 'The image is too big. Please upload a file under %s to continue.';
export const ERROR_MIN_DIMENSION =
    'The image dimensions are too small. Please upload an image equal to or larger than %s x %spx.';
export const ERROR_UNKNOWN = 'Something went wrong when uploading your image.';

export const FILE_VALID_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];
export const FILE_MAX_WIDTH_HEIGHT = 1220;
export const FILE_MIN_WIDTH_HEIGHT = 224;
export const FILE_MAX_SIZE = 60 * 1024 * 1024; // 60 MB
export const FILE_MAX_SIZE_WITH_UNITS = bytesToSize(FILE_MAX_SIZE);
export const FILE_QUALITY_CANVAS = 0.5;
