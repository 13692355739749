import { q, ww } from 'bobjoll/ts/library/dom';
import { filterSortByEventGA4 } from '../../events/ga4.filter';

import FilterEvents from '../FilterEvents';
import { FilterKeys, FilterProperties, ValidFilterKey } from '../FilterInputs';
import getFilterIdList from '../getFilterIdList';
import mapFiltersToUpdate from '../mapFiltersToUpdate';

class FilterSortBy {
    private static sortKeyName = 'sort';
    private static datesKeyName = 'dates';
    private static filterKeysStatus: FilterKeys;
    private sortSelectElement: HTMLElement;

    constructor({ filterKeysStatus = ww.Filters, sortSelector = '.tab-search #filter-sort' }: FilterSortByConfig = {}) {
        const sortSelectElement = q(sortSelector) as HTMLElement;
        if (!sortSelectElement) return;

        FilterSortBy.filterKeysStatus = filterKeysStatus;
        this.sortSelectElement = sortSelectElement;

        this.addEvent();
    }

    private static get sortFilters(): FilterProperties[] {
        return FilterSortBy.filterKeysStatus[FilterSortBy.sortKeyName];
    }

    private static get datesFilters(): FilterProperties[] {
        return FilterSortBy.filterKeysStatus[FilterSortBy.datesKeyName];
    }

    private addEvent() {
        this.sortSelectElement.addEventListener('change', function(this: HTMLSelectElement) {
            const optionSelected = this.value;
            const sortKeyIds = getFilterIdList(FilterSortBy.sortFilters);
            const datesKeyIds = getFilterIdList(FilterSortBy.datesFilters);
            const disableFilters: string[] = [...sortKeyIds, ...datesKeyIds];
            const enableFilters: string[] = [];

            [...sortKeyIds, ...datesKeyIds].some(id => {
                id.includes(optionSelected) && enableFilters.push(id);
            });

            mapFiltersToUpdate(disableFilters, false);
            mapFiltersToUpdate(enableFilters);

            filterSortByEventGA4(optionSelected);

            new FilterEvents({
                filterKey: FilterSortBy.sortKeyName as ValidFilterKey,
                filterKeysStatus: FilterSortBy.filterKeysStatus,
                optionItem: this,
                newSearch: true,
            });
        });
    }
}

interface FilterSortByConfig {
    filterKeysStatus?: FilterKeys;
    sortSelector?: string;
}

export default FilterSortBy;
