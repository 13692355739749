import { Cookie } from 'bobjoll/ts/library/cookie';
import { q, qq } from 'bobjoll/ts/library/dom';
import { changeDesktopToTablet, isTablet } from 'Library/helpers/device';

import FilterEvents from '../FilterEvents';

const FILTER_KEYS = [
    'format',
    'query',
    'from_query',
    'author',
    'authorSlug',
    'page',
    'sort',
    'type',
    'license',
    'img',
    'ai',
    'freepik_choice',
    'edit_online',
    'style',
    'dates',
    'people',
    'color',
    'file_type',
    'orientation',
    'demographic',
    'ai_model',
    'prompt',
] as const;

class FilterInputs {
    private filterGlobalElement: HTMLElement;
    private filterElement: HTMLElement | undefined;
    private filterKeysStatus: FilterKeys;
    private filterSectionSelector: string;
    private filterOptionsSelector: string;
    private filtersConfigCookieName = 'filters-configs';
    private filtersConfigByDefault =
        '{"group":[{"name":"type","show":true},{"name":"license","show":true}],"show":true}';

    constructor({
        filterGlobalSelector = '#search',
        filterOptionsSelector = '[data-option]',
        filterSectionSelector = 'details',
        filterSelector = '.filter-menu',
    }: FilterConfig = {}) {
        this.filterOptionsSelector = filterOptionsSelector;
        this.filterSectionSelector = filterSectionSelector;
        this.filterElement = q(filterSelector) as HTMLElement | undefined;
        this.filterGlobalElement = q(filterGlobalSelector) as HTMLElement;
        this.filterKeysStatus = {};
    }

    public initialiceFilters(): FilterKeys {
        this.mapFilters();
        typeof Showcase !== 'undefined' && Showcase.setButton();
        this.isMobileFilter();
        this.disableClickOnTooltips();
        this.setConfigCookieWithOpenedFiltersByDefault();

        return this.filterKeysStatus;
    }

    private isMobileFilter() {
        this.applyMobileFilters(isTablet({ screenWidth: true }));

        changeDesktopToTablet();

        window.addEventListener('desktop:change', (e: CustomEvent) => {
            const isMobile = e.detail.isMobile || false;
            this.applyMobileFilters(isMobile);
        });
    }

    private applyMobileFilters(activate: boolean) {
        activate && this.openAllSectionsInMobile();
    }

    private openAllSectionsInMobile() {
        this.filterElement &&
            qq(this.filterSectionSelector, this.filterElement)?.forEach(
                (section: HTMLDetailsElement) => (section.open = true),
            );
    }

    private disableClickOnTooltips() {
        this.filterElement &&
            qq('.tooltip', this.filterElement)?.forEach((tooltip: HTMLElement) => {
                tooltip.addEventListener('click', event => {
                    event.preventDefault();
                });
            });
    }

    private setConfigCookieWithOpenedFiltersByDefault() {
        if (!Cookie.getItem(this.filtersConfigCookieName))
            Cookie.setItem(this.filtersConfigCookieName, this.filtersConfigByDefault);
    }

    private mapFilters() {
        this.mapAllFilterKeys(this.filterGlobalElement);
        this.mapAllFilterKeys(this.filterElement);
    }

    private mapAllFilterKeys(filterElement: HTMLElement | undefined) {
        filterElement &&
            qq(this.filterSectionSelector, filterElement)?.forEach((filterGroup: HTMLDetailsElement) => {
                const filterKey = filterGroup.dataset.key as ValidFilterKey;

                filterKey && this.getOption(filterGroup, filterKey);
            });
    }

    private getOption(filterItem: HTMLElement, filterKey: ValidFilterKey) {
        this.filterKeysStatus[filterKey] = [];

        const isSelect = filterItem.dataset.isSelect ? true : false;
        isSelect && this.setEvent(filterKey, filterItem);

        qq(this.filterOptionsSelector, filterItem).forEach((optionItem: HTMLElement) => {
            const optionKey = optionItem.dataset.option;

            optionKey &&
                this.getOptionStatus({
                    filterKey,
                    isSelect,
                    optionItem,
                    optionKey,
                });

            !isSelect && this.setEvent(filterKey, optionItem);
        });
    }

    private getOptionStatus(options: FilterStatus) {
        const { optionItem, optionKey, isSelect, filterKey } = options;

        if (!optionItem) return;

        const keyGroup = optionItem.dataset.key || filterKey?.toString() || '';

        const filterProperties = this.getElementStatus({
            elementSelect: isSelect ? (optionItem as HTMLOptionElement) : undefined,
            elementInput: !isSelect ? (optionItem as HTMLInputElement) : undefined,
            isTextType: !isSelect ? (optionItem as HTMLInputElement).type === 'text' : false,
            optionKey,
        });

        if (filterProperties) {
            if (!this.filterKeysStatus[keyGroup]) this.filterKeysStatus[keyGroup] = [];

            this.filterKeysStatus[keyGroup][optionKey] = filterProperties;
        }
    }

    private getElementStatus(options: FilterStatus) {
        const { elementInput, elementSelect, isTextType, optionKey } = options;

        if (!elementInput && !elementSelect) return;

        const optionProperties: FilterProperties = {
            checked: isTextType || (elementInput && elementInput.checked) || (elementSelect && elementSelect.selected),
            element: elementInput,
            elementSelect: elementSelect,
            id: (elementInput && elementInput.id) || (elementSelect && elementSelect.id) || '',
            isAllButton: optionKey === 'all',
            isTextType: isTextType,
            value: (elementInput && elementInput.value) || (elementSelect && elementSelect.value) || '',
        };

        return optionProperties;
    }

    private setEvent(filterKey: ValidFilterKey, optionItem: HTMLElement) {
        optionItem.dataset.asRadiobutton && this.setEventToConvertInRadioButton(optionItem);

        optionItem.addEventListener(
            'change',
            () =>
                new FilterEvents({
                    filterKey,
                    filterKeysStatus: this.filterKeysStatus,
                    optionItem,
                }),
        );
    }

    private setEventToConvertInRadioButton(input: HTMLElement) {
        input.addEventListener('click', function(this: HTMLInputElement) {
            FilterInputs.convertCheckboxToRadioButton(this);
        });
    }

    public static convertCheckboxToRadioButton(input: HTMLInputElement, forceName?: string) {
        const filterGroup = qq(`input[data-option][name="${forceName || input.name}"]`) as
            | HTMLInputElement[]
            | undefined;
        if (filterGroup)
            filterGroup.forEach(option => {
                if (option !== input) option.checked = false;
            });
    }
}

interface FilterConfig {
    filterGlobalSelector?: string;
    filterOptionsSelector?: string;
    filterSectionSelector?: string;
    filterSelector?: string;
}

interface FilterStatus {
    elementInput?: HTMLInputElement;
    elementSelect?: HTMLOptionElement;
    filterKey?: ValidFilterKey;
    isSelect?: boolean;
    isTextType?: boolean;
    optionItem?: HTMLElement;
    optionKey: string;
}

export interface FilterProperties {
    checked?: boolean;
    element?: HTMLInputElement;
    elementSelect?: HTMLOptionElement;
    id: string;
    isAllButton?: boolean;
    isTextType?: boolean;
    value: string;
}

type FilterKeyNames = typeof FILTER_KEYS;
export type ValidFilterKey = keyof FilterKeyNames;
export type FilterKeys = {
    [key in ValidFilterKey]?: FilterProperties[];
};

export default FilterInputs;
