import ConditionTabCounters from '../ConditionTabCounters';
import { FilterEventConfig } from '../FilterEvents';
import { FilterKeys, FilterProperties } from '../FilterInputs';
import getFilterIdList from '../getFilterIdList';
import getFilterValue from '../getFilterValue';
import mapFiltersToUpdate from '../mapFiltersToUpdate';

class ConditionSearch {
    private readonly authorKeyName = 'author';
    private readonly authorSlugKeyName = 'authorSlug';
    private readonly nextSearchKeyName = 'from_query';
    private readonly removeFiltersKeyFromAuthor = ['sort', 'type', 'license', 'format', 'dates', 'color', 'orientation', 'people', 'demographic', 'edit_online', 'freepik_choice', 'style', 'file_type', 'undiscovered'];
    private filterKeysStatus: FilterKeys;
    private optionItemActivated: HTMLElement;

    constructor(eventConfig: FilterEventConfig) {
        this.filterKeysStatus = eventConfig.filterKeysStatus;
        this.optionItemActivated = eventConfig.optionItem;

        this.apply();
    }

    private get nextSearchFilter(): FilterProperties[] {
        return this.filterKeysStatus[this.nextSearchKeyName];
    }

    private get authorFilter(): FilterProperties[] {
        return this.filterKeysStatus[this.authorKeyName];
    }

    private get authorSlugFilter(): FilterProperties[] {
        return this.filterKeysStatus[this.authorSlugKeyName];
    }

    private get disableFiltersForAuthorGlobalSearch(): FilterProperties[] {
        let filters: FilterProperties[] = [];
        this.removeFiltersKeyFromAuthor.forEach(key => {
            filters = [
                ...filters,
                ...this.filterKeysStatus[key],
            ];
        });
        return filters;
    }

    private apply() {
        const { format } = getFilterValue('format');
        const nextSearchKeyId = getFilterIdList(this.nextSearchFilter);
        const isAuthorSearch = document.body.dataset.hbsTemplate === 'author';
        const conditionRemoveFilters: string[] = format === 'collections' ? ConditionTabCounters.disableFiltersForCollections : [];
        let authorKeyId: string[] = [];
        let authorSlugKeyId: string[] = [];
        let authorGlobalRemoveFilters: string[] = [];
        let enableFilters: string[] = [];

        if (!this.optionItemActivated.dataset.isAuthor) {
            authorKeyId = getFilterIdList(this.authorFilter);
            authorSlugKeyId = getFilterIdList(this.authorSlugFilter);

            if (isAuthorSearch) {
                authorGlobalRemoveFilters = getFilterIdList(this.disableFiltersForAuthorGlobalSearch);
                enableFilters = ['filter-format-search'];
            }
        }

        const disableFilters: string[] = [
            ...nextSearchKeyId,
            ...conditionRemoveFilters,
            ...authorKeyId,
            ...authorSlugKeyId,
            ...authorGlobalRemoveFilters,
        ];

        mapFiltersToUpdate(disableFilters, false);
        mapFiltersToUpdate(enableFilters);
    }
}

export default ConditionSearch;
