import displayInput from '../displayInput';
import displaySection from '../displaySection';
import { FilterEventConfig } from '../FilterEvents';
import { FilterKeys, FilterProperties, ValidFilterKey } from '../FilterInputs';
import getFilterValue from '../getFilterValue';
import mapFiltersToUpdate from '../mapFiltersToUpdate';

class ConditionFileType {
    private readonly typeKeyName = 'type';
    private readonly fileTypeKeyName = 'file_type';
    private readonly fileTypeKeysByCategory = {
        vector: ['ai', 'eps', 'jpg', 'svg'],
        psd: ['psd', 'jpg'],
        photo: ['jpg'],
        icon: [],
        video: [],
    };
    private readonly showFileTypeSectionByCategory = {
        vector: true,
        psd: false, // TODO: set to true when "PSD files" is ready
    };
    private filterKeyActivated: ValidFilterKey;
    private filterKeysStatus: FilterKeys;
    private optionItemActivated: HTMLElement;

    constructor(eventConfig: FilterEventConfig) {
        this.filterKeyActivated = eventConfig.filterKey;
        this.filterKeysStatus = eventConfig.filterKeysStatus;
        this.optionItemActivated = eventConfig.optionItem;

        this.apply();
    }

    private get fileTypeFilters(): FilterProperties[] {
        return this.filterKeysStatus[this.fileTypeKeyName];
    }

    private get fileTypeValueFilters(): string[] {
        return Object.keys(this.fileTypeFilters);
    }

    private uncheckFileTypeByCategoryThatHaveOneFilterValid(type: string): string | boolean {
        return this.fileTypeKeysByCategory[type].length === 1 ? this.fileTypeKeysByCategory[type][0] : false;
    }

    private getInvalidFileTypeFiltersByCategory(type: string): string[] {
        const invalidFileTypeKey = this.fileTypeValueFilters.filter(
            fileType => !this.fileTypeKeysByCategory[type].includes(fileType),
        );

        const uncheckUniqueFilterValue = this.uncheckFileTypeByCategoryThatHaveOneFilterValid(type);
        const addCleanFilter = uncheckUniqueFilterValue ? `filter-file_type-${uncheckUniqueFilterValue}` : '';

        return (
            (invalidFileTypeKey.length > 0 && [
                ...invalidFileTypeKey.map(key => `filter-file_type-${key}`),
                addCleanFilter,
            ]) ||
            []
        );
    }

    private getValidFileTypeFiltersByCategory(type: string): string[] {
        const validFileTypeKey = this.fileTypeValueFilters.filter(fileType =>
            this.fileTypeKeysByCategory[type].includes(fileType),
        );

        return (validFileTypeKey.length > 0 && validFileTypeKey.map(key => `filter-file_type-${key}`)) || [];
    }

    private setFileTypeStatus(typeSelected: string) {
        const disableFilters = this.getInvalidFileTypeFiltersByCategory(typeSelected);
        const showFileTypeSection = this.showFileTypeSectionByCategory[typeSelected] || false;

        if (disableFilters) {
            mapFiltersToUpdate(disableFilters, false);
            displayInput(disableFilters, false);
        }

        displaySection(this.fileTypeKeyName, showFileTypeSection);

        showFileTypeSection && displayInput(this.getValidFileTypeFiltersByCategory(typeSelected));
    }

    private setCategoryStatus(typeSelected: string) {
        const { file_type } = getFilterValue(this.fileTypeKeyName, this.filterKeysStatus);

        if (file_type === '') return;

        const fileTypeSelectedValues = file_type.split(',');

        if (typeSelected && fileTypeSelectedValues.length > 0) {
            const allFileTypesAreValid = fileTypeSelectedValues.every((fileType: string) =>
                this.fileTypeKeysByCategory[typeSelected].includes(fileType),
            );

            !allFileTypesAreValid && mapFiltersToUpdate([`filter-type-${typeSelected}`], false);
        }
    }

    private apply() {
        const fieldName = this.filterKeyActivated.toString();

        const typeSelected =
            fieldName === this.typeKeyName
                ? this.optionItemActivated.getAttribute('value') || ''
                : getFilterValue(this.typeKeyName, this.filterKeysStatus)?.type;

        if (!this.fileTypeKeysByCategory[typeSelected]) return;

        return fieldName === this.fileTypeKeyName
            ? this.setCategoryStatus(typeSelected)
            : this.setFileTypeStatus(typeSelected);
    }
}

export default ConditionFileType;
