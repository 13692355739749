import { ww } from 'bobjoll/ts/library/dom';
import { UserHelpers } from 'Partials/user';
import { view as View, ViewPrint as Page } from 'Partials/view';
import Cache from 'Project/ts/library/cache';

class UserPage {
    active: Page;

    constructor() {
        if (View.template.match(/user/)) {
            View.templates['user'] = {
                history: true,
                loader: false,
                print: false,
                push: true,
                pushTracker: true,
                delay: false,
                lock: false,
            };

            this.setData();
        }
    }

    setData() {
        const showcase = document.querySelector('#main .showcase') as HTMLElement;

        if (showcase) {
            this.active = {
                views: ['user'],
                key: window.location.href,
                url: window.location.href,
                location: window.location.href,
            };

            if (this.active.views && this.active.key && this.active.url && this.active.location) {
                const resources = Showcase.getData(showcase, this.active.key);

                if (showcase) {
                    const xhr = {
                        data: {
                            resources: resources,
                        },
                    };

                    Cache.set({ key: this.active.key, value: xhr, time: 3600 });

                    showcase.dataset.cacheKey = this.active.key;
                }

                Array.prototype.slice
                    .call(document.querySelectorAll('.showcase__item'))
                    .forEach((item: HTMLElement, index: number) => {
                        const resource = resources[index];
                        const downloadLink = item.querySelector('.download-button');
                        const attributeLink = item.querySelector('.modal__trigger[data-modal="modal-attribute"]');

                        if ('Premium' !== UserHelpers.type() && 'Essential' !== UserHelpers.type()) {
                            if (attributeLink) {
                                attributeLink.addEventListener('click', () => {
                                    View.newPage({
                                        url: resource['url_source'],
                                        views: ['attribute'],
                                        xhr: resource,
                                    });
                                });
                            }

                            if (downloadLink && !resource.getPathValue('premium')) {
                                downloadLink.addEventListener('click', e => {
                                    e.preventDefault();

                                    View.newPage({
                                        url: resource['url_source'],
                                        views: ['download', 'free-download'],
                                        xhr: resource,
                                    });

                                    ww.ModalInstance.show('modal-download');
                                });
                            }
                        }
                    });
            }
        }
    }
}

export const Static = new UserPage();
