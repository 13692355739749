import { ww } from 'bobjoll/ts/library/dom';

ww.ssmCustomTargetings = ww.ssmCustomTargetings || {};

export const setTargeting = (targets: AdServerTargets) => {
    ww.ssmCustomTargetings = {
        ...ww.ssmCustomTargetings,
        ...targets,
    };
};

export const clearTargeting = () => {
    ww.ssmCustomTargetings = {};
};

interface AdServerTargets {
    [key: string]: string | number;
}
