import { debounce } from 'lodash';
import { q, qq } from 'bobjoll/ts/library/dom';
import { Settings } from 'bobjoll/ts/library/settings';
import { sessionStorage } from 'bobjoll/ts/library/storage';
import { hotjarMenuTopSurvey } from 'Partials/hotjar/hotjar.menu';

const PIXEL_SPACING_LIMIT = 100;
const PIXEL_LINK_SEPARATION = 20;
const MENU_COLLAPSE_BREAKPOINT = 1160;
const MENU_OPEN_DELAY = 50;

let menuCloseTimer: NodeJS.Timer;
let menuOpenTimer: NodeJS.Timer;
let menuImagesOpenTimer: NodeJS.Timer;
let currentScreenWidth = window.innerWidth;

const MENU_TOP_ITEMS = '#nav-main .has-submenu:not(.topmenu__content) > a:not(.link-description)';
const MOBILE_MENU_ITEMS =
    '#nav-main .menu__list .menu-fixed > .has-submenu:not(.topmenu__content) > a.link-description';
const MORE_ITEMS_DROPDOWN = '#nav-main #menuTopMoreToolsBasic .has-submenu:not(.topmenu__content) > a.link-description';
const MULTI_ITEMS_DROPDOWN = '#nav-main .submenu--multi .has-submenu--basic:not(.topmenu__content) > a';

export const initializeMenu = () => {
    const menuItemsSelectors = [MENU_TOP_ITEMS, MORE_ITEMS_DROPDOWN, MULTI_ITEMS_DROPDOWN, MOBILE_MENU_ITEMS];

    menuItemsSelectors.forEach(selector => {
        initializeMenuItemsEvents(selector, 'click');
        initializeMenuItemsEvents(selector, 'mouseover');
    });

    qq(MENU_TOP_ITEMS).forEach(linkElement => {
        linkElement.addEventListener('mouseleave', function() {
            clearTimeout(menuOpenTimer);
        });
    });

    q('#nav-main [data-trigger="nav-main"]')?.addEventListener('click', () => {
        const datasetNavMain = 'nav-main';
        const navOpened = document.body.dataset.trigger === datasetNavMain;
        document.body.dataset.trigger = navOpened ? '' : datasetNavMain;
    });

    const onAddEventListenerMouseleave = (event: MouseEvent) => {
        const submenuElement = event.target as HTMLElement;
        menuCloseTimer = setTimeout(() => {
            qq('#nav-main .has-submenu:not(.topmenu__content) a.active').forEach(menu =>
                menu.classList.remove('active'),
            );
        }, MENU_OPEN_DELAY);

        submenuElement?.addEventListener('mouseover', () => {
            clearTimeout(menuCloseTimer);
        });
    };

    const resize = () => {
        if (!isMenuMobileEnabled()) {
            qq(
                '.menu__list .menu-fixed > .has-submenu:not(.topmenu__content) .submenu, .menu__list .menu-fixed > .menu__content',
            ).forEach(submenuElement => {
                submenuElement.addEventListener('mouseleave', onAddEventListenerMouseleave);
            });

            qq('.menu__list > li:not(.has-submenu) > a').forEach(linkElement => {
                linkElement.addEventListener('mouseover', () => {
                    qq('#nav-main .has-submenu a.active').forEach(menu => menu.classList.remove('active'));
                });
            });

            qq('.menu-dropdown__list--basic > li:not(.has-submenu) > a').forEach(linkElement => {
                linkElement.addEventListener('mouseover', () => {
                    qq('.menu-dropdown__list--basic .has-submenu:not(.topmenu__content) a.active').forEach(menu =>
                        menu.classList.remove('active'),
                    );
                });
            });
        } else {
            qq(
                '.menu__list .menu-fixed > .has-submenu:not(.topmenu__content) .submenu, .menu__list .menu-fixed > .menu__content',
            ).forEach(submenuElement => {
                submenuElement.removeEventListener('mouseleave', onAddEventListenerMouseleave);
            });
        }
    };

    resize();

    window.addEventListener('mouseup', (e: Event) => {
        const target = e.target;
        if (target instanceof HTMLElement) {
            const menu = target.parents('.has-submenu');
            const dataType = target.parents('ul[data-type]');
            if (!menu.length || dataType.length) {
                qq('#nav-main .has-submenu:not(.topmenu__content) a.active').forEach(menu =>
                    menu.classList.remove('active'),
                );
            }
        }
    });

    initTopmenu();
    resizeHeader();

    window.addEventListener('resize', () => {
        resizeHeader();
        resize();
        closeMenuMobile();
    });

    triggerHotjarSurvey();
};

const initializeMenuItemsEvents = (selector: string, userEvent: 'click' | 'mouseover') => {
    qq(selector).forEach(linkElement => {
        linkElement.addEventListener(userEvent, function(event: Event) {
            if (isMenuMobileEnabled() && userEvent === 'mouseover') return;
            if (userEvent === 'click') {
                event.preventDefault();
            }

            const isMobileActive = linkElement.parentElement!.classList.contains('has-submenu--mobile');
            const activeLinkArr = qq(`${selector}.active`);

            if (currentScreenWidth > Settings['breakpoints']['lg'] || isMobileActive) {
                if (!linkElement.classList.contains('active')) {
                    linkElement.parentElement && restartTopmenu(linkElement.parentElement);
                    clearTimeout(menuOpenTimer);
                    if (userEvent === 'mouseover' && selector === MENU_TOP_ITEMS) {
                        menuOpenTimer = setTimeout(() => {
                            linkElement.classList.add('active');
                        }, MENU_OPEN_DELAY);
                    } else {
                        linkElement.classList.add('active');
                    }
                }

                if (isMenuMobileEnabled()) {
                    event.preventDefault();
                }
            }

            activeLinkArr.forEach(menu => {
                if (isMenuMobileEnabled() || !menu.isEqualNode(linkElement)) {
                    menu.classList.remove('active');
                }
            });

            clearTimeout(menuCloseTimer);
        });
    });
};

const closeMenuMobile = () => {
    const datasetNavMain = 'nav-main';
    const navOpened = document.body.dataset.trigger === datasetNavMain;

    if (navOpened) {
        document.body.dataset.trigger = '';
    }
};

const resizeHeader = debounce((forceShow = false) => {
    const newWidth = window.innerWidth;

    const isIncreasingWidth = currentScreenWidth < newWidth;

    const moreItemMenu = q('#menuTopMoreTools');
    let headerLinks = qq('.menu__list > li') || [];

    if (newWidth < 1160) {
        headerLinks = qq('.menu__list .menu-fixed > li') || [];
        toogleMoreMenu('basic');
    } else {
        headerLinks = qq('.menu__list > li') || [];
        toogleMoreMenu('complete');
    }

    if (headerLinks.length === 0 || !moreItemMenu) return;

    const [pricingItemMenu, ...menuItems] = [...headerLinks].reverse();

    const distanceBetweenMoreAndContributors =
        pricingItemMenu.getBoundingClientRect().left - moreItemMenu.getBoundingClientRect().right;

    if (distanceBetweenMoreAndContributors < PIXEL_SPACING_LIMIT && !isIncreasingWidth && !isMenuMobileEnabled()) {
        hideMenuItems(menuItems);
        resizeHeader();
    } else if (isIncreasingWidth || forceShow || isMenuMobileEnabled()) {
        showHiddenMenuItems(menuItems, distanceBetweenMoreAndContributors);
    }

    if (isMenuMobileEnabled()) {
        toogleMoreMenu('basic');
        q('#menuTopMoreTools > a')?.classList.add('more-mobile');
    } else {
        q('#menuTopMoreTools > a')?.classList.remove('more-mobile');
    }
    if (isMenuMobileEnabled()) {
        let elements = document.querySelectorAll('#toolSubmenu > li > a');
        elements.forEach(element => element.classList.add('link-description__submenu'));
        q('#menuTopTools > div > div > ul')?.classList.add('submenu--sublevel');
        q('#titleTools')?.classList.add('hide-mobile');
    } else {
        let elements = document.querySelectorAll('#toolSubmenu > li > a');
        elements.forEach(element => element.classList.remove('link-description__submenu'));
        q('#menuTopTools > div > div > ul')?.classList.remove('submenu--sublevel');
        q('#titleTools')?.classList.remove('hide-mobile');
    }

    currentScreenWidth = newWidth;
}, 100);

const hideMenuItems = (menuItems: HTMLElement[]) => {
    const previousMoreElement = menuItems.filter(menu => !menu.classList.contains('hide'))[0];

    if (previousMoreElement) {
        previousMoreElement?.classList.add('hide');

        const previousMoreElementOption = q('a', previousMoreElement)?.dataset.option || '';
        q(`#menuTopMoreToolsBasic [data-option="${previousMoreElementOption}"]`)?.parentElement?.classList.remove(
            'hide',
        );

        toogleMoreMenu('basic');
    }
};

const showHiddenMenuItems = (menuItems: HTMLElement[], distanceBetweenMoreAndContributors: number) => {
    const hiddenMenuItems = menuItems.filter(menu => menu.classList.contains('hide'));
    const previousMoreElement = hiddenMenuItems[hiddenMenuItems.length - 1];

    if (hiddenMenuItems.length === 0) {
        toogleMoreMenu('complete');
        return;
    }

    if (!previousMoreElement) return;

    const hiddenItemWidth = previousMoreElement.getBoundingClientRect().width;

    if (
        distanceBetweenMoreAndContributors - hiddenItemWidth - PIXEL_LINK_SEPARATION > PIXEL_SPACING_LIMIT ||
        isMenuMobileEnabled()
    ) {
        previousMoreElement?.classList.remove('hide');

        const previousMoreElementOption = q('a', previousMoreElement)?.dataset.option || '';
        q(`#menuTopMoreToolsBasic [data-option="${previousMoreElementOption}"]`)?.parentElement?.classList.add('hide');

        if (hiddenMenuItems.length === 1 && !isMenuMobileEnabled()) {
            toogleMoreMenu('complete');
        } else {
            resizeHeader(true);
        }
    }
};

const isMenuMobileEnabled = () => window.innerWidth < MENU_COLLAPSE_BREAKPOINT; //MENU_COLLAPSE_BREAKPOINT=1660px

const toogleMoreMenu = (mode: 'basic' | 'complete') => {
    if (mode === 'basic') {
        q('#menuTopMoreToolsBasic')?.classList.remove('hide');
        q('#menuTopMoreToolsComplete')?.classList.add('hide');
    } else {
        q('#menuTopMoreToolsBasic')?.classList.add('hide');
        q('#menuTopMoreToolsComplete')?.classList.remove('hide');
    }
};

const triggerHotjarSurvey = () => {
    const topMenuInteraction = parseInt(sessionStorage.getItem('hotjar', 'topmenu') || '0');

    if (topMenuInteraction >= 2) {
        hotjarMenuTopSurvey();
        return;
    }

    qq('#nav-main [data-gtm-event="main_menu"]')?.forEach(link =>
        link.addEventListener('click', () => {
            sessionStorage.setItem('hotjar', 'topmenu', (topMenuInteraction + 1).toString());
        }),
    );
};

export const initTopmenu = () => {
    qq('.topmenu .topmenu__container .topmenu__title').forEach((listElement, index) => {
        if (index !== 3) {
            listElement.addEventListener('mouseover', function() {
                if (menuImagesOpenTimer) {
                    clearTimeout(menuImagesOpenTimer);
                }

                if (listElement && !listElement.classList.contains('active')) {
                    q('.topmenu__content.active')?.classList.remove('active');
                    listElement.parentElement?.classList.add('active');
                }

                listElement?.addEventListener('mouseleave', () => {
                    clearTimeout(menuImagesOpenTimer);
                });
            });
        }
    });
};

export const restartTopmenu = (element: HTMLElement) => {
    const secondElement = element?.classList.contains('topmenu')
        ? q('.topmenu__container .topmenu__content:nth-child(2)', element)
        : null;
    if (!secondElement || secondElement.classList.contains('active')) return;

    q('.topmenu__content.active', element)?.classList.remove('active');
    secondElement.classList.add('active');
};
