const mapFiltersToUpdate = (filterKeys: string[], enable = true) => {
    filterKeys.length > 0 && filterKeys.forEach(
        key => key && updateFilterById(key, enable)
    );
};

const updateFilterById = (id: string, enabled: boolean) => {
    const field = document.getElementById(id) as HTMLInputElement | undefined;

    if (!field) return;

    if (field.type === 'text') {
        field.value = '';
    } else if (field.nodeName === 'OPTION') {
        const isOptionField = field as unknown;
        const option = isOptionField as HTMLOptionElement;
        option.selected = enabled;
    } else {
        field.checked = enabled;
    }
};

export default mapFiltersToUpdate;
