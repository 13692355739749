import { q, qq } from 'bobjoll/ts/library/dom';
import { checkChildrenHasTheClass } from 'Library/helpers/element';

class TagSlider {
    private roundPixels = 2;
    private containerElement: HTMLElement;
    private listElement: HTMLUListElement;
    private prevElement: HTMLButtonElement;
    private nextElement: HTMLButtonElement;
    private scrollSize: number;
    private listSize: number;

    constructor(element: HTMLElement) {
        this.containerElement = element;
        this.listElement = q('.tag-slider--list', element) as HTMLUListElement;
        this.prevElement = q('.tag-slider--nav-prev', element) as HTMLButtonElement;
        this.nextElement = q('.tag-slider--nav-next', element) as HTMLButtonElement;

        this.setSlider();
        this.setEvents();
    }

    private setEvents() {
        window.addEventListener('resize', () => this.setSlider());
        document.addEventListener('reload', () => {
            this.setSlider();
            this.classControl();
        });

        this.listElement.addEventListener('scroll', () => this.classControl());
        this.prevElement.addEventListener('click', () => this.scrollTo(-this.scrollSize));
        this.nextElement.addEventListener('click', () => this.scrollTo(this.scrollSize));
    }

    private setSlider() {
        this.listSize = 0;

        qq('li', this.listElement).forEach(element => (this.listSize += element.offsetWidth));

        this.containerElement.classList[this.listSize > this.listElement.offsetWidth ? 'add' : 'remove'](
            'tag-slider--has-next',
        );
        this.scrollSize = this.listElement.offsetWidth / 2;
    }

    private scrollTo(offset: number) {
        this.listElement.scroll({
            top: 0,
            left: this.listElement.scrollLeft + offset,
            behavior: 'smooth',
        });
    }

    private classControl() {
        this.containerElement.classList[this.listElement.scrollLeft <= 0 ? 'remove' : 'add']('tag-slider--has-prev');
        this.containerElement.classList[
            this.listElement.scrollLeft + this.roundPixels >= this.listSize - this.listElement.offsetWidth
                ? 'remove'
                : 'add'
        ]('tag-slider--has-next');
    }
}

export const initializeSlider = () => {
    const sliderElements = qq('.tag-slider');

    sliderElements &&
        sliderElements.forEach(element => {
            if (element.dataset.sliderResource) {
                checkChildrenHasTheClass({
                    approximateChildrensInView: 5,
                    elementContainer: element,
                    childrenElements: qq('img', element),
                    classNameChanged: 'loaded',
                    callback: () => new TagSlider(element),
                });
                return;
            }

            element.dataset.slider === 'true' && new TagSlider(element);
        });
};

initializeSlider();
