import xhr from 'Service/xhr';
import {popularSearch, userSearch} from "Partials/searchBar/searchHistory/searchBar.history.types";

const ajaxRequestHeaders = {
    headers: {
        'X-CSRF-TOKEN': CSRF_TOKEN,
        'X-Requested-With': 'XMLHttpRequest',
    },
};

export const getUserSearches = () => {
    return xhr.get(`${BASE_URL}xhr/user-searches`, ajaxRequestHeaders);
};

export const saveUserSearches = (search: string) => {
    return xhr.get(`${BASE_URL}xhr/user-searches/save/${search}`, ajaxRequestHeaders);
};

export const deleteAllUserSearches = () => {
    return xhr.get(`${BASE_URL}xhr/user-searches/remove`, ajaxRequestHeaders).then(() => {
        const removedEvent = new CustomEvent('searchHistory:clear');
        document.dispatchEvent(removedEvent);
    });
};

export const mapUserSearchesAsAutocomplete = (userSearches: userSearch[], popularSearches: popularSearch[]|string) => {
    const mappedUserSearches = userSearches.map((search: {search_text:string}) => {
        return { text: search.search_text };
    });
    return { keywords: mappedUserSearches, authors: [], popular: popularSearches, source: 'trends', history: true };
};

