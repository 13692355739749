import './campaign.style';

import { initPHPCampaignBanners, refreshCountdown } from './campaign.events';

export const globalCampaign = {
    init: () => initPHPCampaignBanners(),
    refreshCountdown: () => refreshCountdown(),
};

export default globalCampaign;
