import { qq } from 'bobjoll/ts/library/dom';
import { Settings } from 'bobjoll/ts/library/settings';
import { cookie } from 'Library/cookie';
import { triggerWindowResize } from 'Library/helpers/resize';

class FilterCollapse {
    private static readonly cookieName = 'filters-configs';
    private static readonly defaultConfig: FilterConfig = {
        group: [],
        show: true,
    };
    private collapseElements: HTMLElement[] | undefined;
    private groupElements: HTMLElement[] | undefined;
    private static bodyScrollPositionMobile = 0;
    private static breakpointMobileFilter = Settings.breakpoints.sm;

    constructor({
        collapseSelector = `.filter-collapse,
            .trigger__button[data-trigger="header-filter"],
            #header-filter .trigger__close`,
        groupSelector = '.filter-sidebar details summary',
    }: FilterCollapseConfig = {}) {
        this.collapseElements = qq(collapseSelector) as HTMLElement[] | undefined;
        this.groupElements = qq(groupSelector) as HTMLElement[] | undefined;

        IS_MOBILE && this.removeShowForceClass();
        this.addEvents();
    }

    private static get config(): FilterConfig {
        const configRaw = cookie.getItem(FilterCollapse.cookieName);
        const configData = configRaw ? JSON.parse(configRaw) : null;
        return configData && 'boolean' === typeof configData.show && Array.isArray(configData.group)
            ? configData
            : FilterCollapse.defaultConfig;
    }

    private static setConfigCookie(config: FilterConfig) {
        cookie.setItem(FilterCollapse.cookieName, JSON.stringify(config));
    }

    private removeShowForceClass() {
        const bodyClasslist = document.body.classList;
        const forceClass = 'show-filter-force';
        bodyClasslist.contains(forceClass) && bodyClasslist.remove(forceClass);
    }

    private addEvents() {
        this.collapseElements?.forEach((collapseButton: HTMLElement) => {
            collapseButton.addEventListener('click', FilterCollapse.clickCollapseButton);
        });
        this.groupElements?.forEach((detailsElement: HTMLElement) => {
            detailsElement.addEventListener('click', function (this: HTMLDetailsElement) {
                FilterCollapse.clickDetailGroup(this);
            });
        });
    }

    private static clickCollapseButton() {
        const config = FilterCollapse.config;
        config.show = !config.show;

        FilterCollapse.setConfigCookie(config);
        FilterCollapse.toggleSidebar();
    }

    private static clickDetailGroup(summary: HTMLElement) {
        const detailsParent = summary.closest('details') as HTMLDetailsElement;
        FilterCollapse.saveGroupConfig({
            name: detailsParent.dataset.key || '',
            show: !detailsParent.open,
        });
    }

    private static saveGroupConfig(options: FilterGroup) {
        const { name, show } = options;
        const config = FilterCollapse.config;
        const group = config.group.filter(group => group.name == name).pop();
        if (group) {
            group.show = show;
        } else {
            config.group.push({ name, show });
        }
        FilterCollapse.setConfigCookie(config);
    }

    private static toggleSidebar() {
        const isMobileFilter = window.innerWidth < FilterCollapse.breakpointMobileFilter;
        const bodyClass = document.body.classList;
        const filterIsOpened = bodyClass.contains('show-filter') || bodyClass.contains('show-filter-force');
        const action = filterIsOpened ? 'remove' : 'add';

        if (isMobileFilter && !filterIsOpened) {
            FilterCollapse.bodyScrollPositionMobile = window.scrollY;
        }

        document.body.classList[action]('show-filter');
        document.body.classList.remove('show-filter-force');

        if (isMobileFilter && filterIsOpened) {
            window.scrollTo(0, FilterCollapse.bodyScrollPositionMobile);
        }

        triggerWindowResize();
    }
}

interface FilterCollapseConfig {
    collapseSelector?: string;
    groupSelector?: string;
}

interface FilterConfig {
    group: FilterGroup[];
    show: boolean;
}

interface FilterGroup {
    name: string;
    show: boolean;
}

export default FilterCollapse;
