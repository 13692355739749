import { q } from 'bobjoll/ts/library/dom';
import { cookie } from 'Library/cookie';
import triggerManualGA4Events from 'Partials/events/ga4.init-manual-events';

const downloadLimitPage = () => {
    if (document.body.classList.contains('download-limit')) {
        gr.addEventListener('gr:logout', verifyDownloadLimitRedirect);
        gr.addEventListener('gr:login', verifyDownloadLimitRedirect);

        if (FEATURE_MONTHLY_DOWNLOAD_LIMIT_BY_COUNTRY) {
            q('.faq-paragraph a')?.addEventListener('click', () => {
                triggerManualGA4Events({
                    description: 'linkFaqsMonthly',
                    options: {
                        location: 'landing_download_limit',
                    },
                });
            });
        }
    }
}

const verifyDownloadLimitRedirect = () => {
    const CB_URL = cookie.getItem('CB_URL');
    window.location.href = CB_URL || BASE_URL;
};

downloadLimitPage();
