import ConditionAI from '../ConditionAI';
import ConditionClarifai from '../ConditionClarifai';
import conditionColor from '../ConditionColor';
import ConditionEditOnline from '../ConditionEditOnline';
import ConditionFileType from '../ConditionFileType';
import ConditionGraphicStyles from '../ConditionGraphicStyles';
import ConditionRemove from '../ConditionRemove';
import ConditionSearch from '../ConditionSearch';
import ConditionTabCounters from '../ConditionTabCounters';
import ConditionType from '../ConditionType';
import { FilterKeys, ValidFilterKey } from '../FilterInputs';
import FilterSearch from '../FilterSearch';

class FilterEvents {
    private readonly conditions = {
        type: (eventConfig: FilterEventConfig) => {
            new ConditionType(eventConfig);
            new ConditionClarifai(eventConfig);
            new ConditionGraphicStyles(eventConfig);
            new ConditionEditOnline(eventConfig);
            new ConditionFileType(eventConfig);
            new ConditionAI(eventConfig);
        },
        people: (eventConfig: FilterEventConfig) => {
            new ConditionClarifai(eventConfig);
            new ConditionFileType(eventConfig);
        },
        demographic: (eventConfig: FilterEventConfig) => {
            new ConditionClarifai(eventConfig);
            new ConditionFileType(eventConfig);
        },
        ai: (eventConfig: FilterEventConfig) => {
            new ConditionAI(eventConfig);
        },
        ai_model: (eventConfig: FilterEventConfig) => {
            new ConditionAI(eventConfig);
        },
        prompt: (eventConfig: FilterEventConfig) => {
            new ConditionAI(eventConfig);
        },
        style: (eventConfig: FilterEventConfig) => {
            new ConditionGraphicStyles(eventConfig);
            new ConditionFileType(eventConfig);
        },
        query: (eventConfig: FilterEventConfig) => {
            new ConditionSearch(eventConfig);
        },
        format: (eventConfig: FilterEventConfig) => {
            return new ConditionTabCounters(eventConfig).apply();
        },
        file_type: (eventConfig: FilterEventConfig) => {
            new ConditionFileType(eventConfig);
        },
        color: (eventConfig: FilterEventConfig) => {
            new conditionColor(eventConfig);
        },
        remove: (eventConfig: FilterEventConfig) => {
            new ConditionRemove(eventConfig);
        },
    };
    private filterKey: ValidFilterKey;
    private filterKeysStatus: FilterKeys;
    private optionItem: HTMLElement;
    private callback: Function | undefined;
    private resetFilters: boolean;
    private removeFilter: boolean;
    private newSearch: boolean;
    private concatUUIDSearch: boolean;

    constructor({
        callback,
        filterKey,
        filterKeysStatus,
        optionItem,
        resetFilters = true,
        removeFilter = false,
        newSearch = false,
        concatUUIDSearch = false,
    }: FilterEventConfig) {
        this.callback = callback;
        this.filterKey = filterKey;
        this.filterKeysStatus = filterKeysStatus;
        this.optionItem = optionItem;
        this.resetFilters = resetFilters;
        this.removeFilter = removeFilter;
        this.newSearch = newSearch;
        this.concatUUIDSearch = concatUUIDSearch;

        this.addEvent();
    }

    private get condition() {
        return this.conditions[this.removeFilter ? 'remove' : this.filterKey.toString()] || false;
    }

    private addEvent() {
        const isModelSearch = Boolean(this.optionItem.dataset.modelSearch) || false;
        const hasReturn =
            this.condition &&
            this.condition({
                filterKey: this.filterKey,
                filterKeysStatus: this.filterKeysStatus,
                optionItem: this.optionItem,
            });

        if (hasReturn && hasReturn.redirect) {
            window.location.href = hasReturn.redirect;
        } else {
            new FilterSearch({
                filterKeysStatus: this.filterKeysStatus,
                resetFilters: this.resetFilters,
                newSearch: this.newSearch,
                isModelSearch: isModelSearch,
                optionItemActivated: this.optionItem,
                filterKeyActivated: this.filterKey,
                concatUUIDSearch: this.concatUUIDSearch,
            }).goToSearchURL(this.callback);
        }
    }
}

export interface FilterEventConfig {
    callback?: Function;
    concatUUIDSearch?: boolean;
    filterKey: ValidFilterKey;
    filterKeysStatus: FilterKeys;
    newSearch?: boolean;
    optionItem: HTMLElement;
    removeFilter?: boolean;
    resetFilters?: boolean;
}

export default FilterEvents;
