import 'Variables';

import { delegate } from 'bobjoll/ts/library/dom';
import Copy from 'BobjollCopy';

delegate('.copy', 'click', function(this: HTMLElement, e: Event) {
    e.preventDefault();
    
    const selector = this.dataset.copy;

    if (selector) {
        this.classList.add('clicked');

        setTimeout(() => { this.classList.remove('clicked'); }, 5000);

        Copy(selector);
    }
});