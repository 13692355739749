import { q, qq, ww } from 'bobjoll/ts/library/dom';

import displaySection from '../displaySection';
import FilterEvents from '../FilterEvents';
import { FilterKeys, ValidFilterKey } from '../FilterInputs';
import getFilterIdList from '../getFilterIdList';
import mapFiltersToUpdate from '../mapFiltersToUpdate';

class FilterRemoveTag {
    private static showSectionsByKeyName = ['people', 'style'];
    private static defaultFilters = ['filter-sort-popular', 'filter-dates-any'];
    private static setOptionToDefaultInSelect = 'details[data-key="dates"] .dropdown__select li[data-value="any"]';
    private static exceptionsTagsApplied = {
        selection: {
            key: 'license',
            value: 'free',
        },
        essential: {
            key: 'license',
            value: 'essential',
        },
        premium: {
            key: 'license',
            value: 'premium',
        },
    };
    private static enabledFiltersKey: string[] = [];
    private static filterKeysStatus: FilterKeys;
    private tagsElements: HTMLElement[];
    private clearAllElements: HTMLElement[];

    constructor({
        filterKeysStatus = ww.Filters,
        tagsSelector = '.filter-sidebar--applied__content span i',
        clearAllSelector = '.filter-sidebar .filter-clear-all',
    }: FilterRemoveTagConfig = {}) {
        const clearAll = qq(clearAllSelector) as HTMLElement[];
        const tagsElements = qq(tagsSelector) as HTMLElement[];

        FilterRemoveTag.filterKeysStatus = filterKeysStatus;
        this.tagsElements = tagsElements;
        this.clearAllElements = clearAll;

        this.addEvents();
    }

    private static get enabledFiltersWithoutDuplicates() {
        return Object.keys(
            FilterRemoveTag.enabledFiltersKey.reduce(
                (previousValue, currentValue) => ((previousValue[currentValue] = true), previousValue),
                {},
            ),
        );
    }

    private static get enabledFiltersIds() {
        let filters: string[] = [];
        FilterRemoveTag.enabledFiltersWithoutDuplicates.forEach(key => {
            filters = [...filters, ...getFilterIdList(this.filterKeysStatus[key])];
        });
        return filters;
    }

    private static get allInputFiltersForClearAll() {
        const allFilters = FilterRemoveTag.filterKeysStatus && Object.keys(FilterRemoveTag.filterKeysStatus);
        const allButtonId: string[] = [];

        allFilters?.forEach((name: string) => {
            const options = FilterRemoveTag.filterKeysStatus[name];

            options &&
                Object.keys(options)?.forEach((name: string) => {
                    const optionItemProperties = options[name];
                    optionItemProperties.element.classList.remove('active');

                    if (optionItemProperties.isAllButton) {
                        allButtonId.push(optionItemProperties.id);
                    }
                });
        });

        FilterRemoveTag.defaultFilters.forEach(filterId => allButtonId.push(filterId));
        FilterRemoveTag.showSectionsByKeyName.forEach(keyName => displaySection(keyName));

        q(FilterRemoveTag.setOptionToDefaultInSelect)?.click();

        return allButtonId;
    }

    private addEvents() {
        this.tagsElements?.forEach((tagElement: HTMLElement) => {
            tagElement.addEventListener('click', function(this: HTMLElement) {
                FilterRemoveTag.removeFilter({
                    element: this,
                });
            });
        });
        this.clearAllElements?.forEach((clearElement: HTMLElement) => {
            clearElement.addEventListener('click', function(this: HTMLElement) {
                FilterRemoveTag.removeFilter({
                    element: this,
                    removeAll: true,
                });
            });
        });
    }

    private static removeFilter(options: FilterRemove) {
        FilterRemoveTag.addEnabledFilters();

        const { element, removeAll } = options;
        let currentKey = '';
        let disableFilters: string[] = [];
        let enableFilters: string[] = [];

        if (!removeAll) {
            const { key, value } = FilterRemoveTag.getPairKeyValue(element);
            const optionKeys = FilterRemoveTag.filterKeysStatus[key];
            if (!optionKeys) return;

            currentKey = key;
            disableFilters = getFilterIdList(optionKeys, value);
        } else {
            disableFilters = FilterRemoveTag.enabledFiltersIds;
            enableFilters = FilterRemoveTag.allInputFiltersForClearAll;
        }

        if (disableFilters.length === 0) return;

        mapFiltersToUpdate(disableFilters, false);
        mapFiltersToUpdate(enableFilters);

        new FilterEvents({
            filterKey: currentKey as ValidFilterKey,
            filterKeysStatus: FilterRemoveTag.filterKeysStatus,
            optionItem: element,
            removeFilter: !removeAll,
        });
    }

    private static addEnabledFilters() {
        const allCheckedInputs = qq('.filter-sidebar input:checked');
        allCheckedInputs?.forEach(input => {
            if (!input.dataset.all) {
                const hasKey = this.getPairKeyValue(input);
                hasKey && hasKey.key && FilterRemoveTag.enabledFiltersKey.push(hasKey.key);
            }
        });
    }

    private static getPairKeyValue(element: HTMLElement) {
        const { key: dataKey, option: dataValue } = element.dataset;
        let key = '';
        let value = '';

        if (dataKey && dataValue) {
            const exception = FilterRemoveTag.exceptionsTagsApplied[dataKey];

            key = exception ? exception.key : dataKey;
            value = exception ? exception.value : dataValue;
        }

        return { key, value };
    }
}

interface FilterRemoveTagConfig {
    filterKeysStatus?: FilterKeys;
    tagsSelector?: string;
    clearAllSelector?: string;
}

interface FilterRemove {
    element: HTMLElement;
    key?: string;
    removeAll?: boolean;
}

export default FilterRemoveTag;
