import { isSearchByImageEnabled } from 'Partials/search-by-image/image/searchimage.get-image';

import displaySection from '../displaySection';
import { FilterEventConfig } from '../FilterEvents';
import { FilterKeys, FilterProperties, ValidFilterKey } from '../FilterInputs';
import mapFiltersToUpdate from '../mapFiltersToUpdate';
import getFilterValue from '../getFilterValue';
import displayInput from '../displayInput';

class ConditionGraphicStyles {
    private readonly queryKeyName = 'query';
    private readonly typeKeyName = 'type';
    private readonly styleKeyName = 'style';
    private readonly styleKeysByCategory = {
        vector: ['watercolor', 'flat', 'cartoon', 'geometric', 'gradient', 'isometric', '3d', 'hand-drawn'],
        psd: [],
        photo: ['aesthetic'],
        icon: [],
        video: [],
    };
    private readonly showStyleSectionByCategory = {
        vector: true,
        photo: true,
    };
    private filterKeyActivated: ValidFilterKey;
    private filterKeysStatus: FilterKeys;
    private optionItemActivated: HTMLElement;

    constructor(eventConfig: FilterEventConfig) {
        this.filterKeyActivated = eventConfig.filterKey;
        this.filterKeysStatus = eventConfig.filterKeysStatus;
        this.optionItemActivated = eventConfig.optionItem;

        this.apply();
    }

    private get styleFilters(): FilterProperties[] {
        return this.filterKeysStatus[this.styleKeyName];
    }

    private get styleValueFilters(): string[] {
        return Object.keys(this.styleFilters);
    }

    private getInvalidStyleFiltersByCategory(type: string): string[] {
        const invalidStyleKey = this.styleValueFilters.filter(
            style => !this.styleKeysByCategory[type]?.includes(style)
        );

        return invalidStyleKey.map(key => `filter-style-${key}`);
    }

    private getValidStyleFiltersByCategory(type: string): string[] {
        const validStyleKey = this.styleValueFilters.filter(style =>
            this.styleKeysByCategory[type]?.includes(style),
        );

        return validStyleKey.map(key => `filter-style-${key}`);
    }

    private setStyleStatus(typeSelected: string) {
        const disableFilters = this.getInvalidStyleFiltersByCategory(typeSelected);
        const showStyleSection = this.showStyleSectionByCategory[typeSelected] || false;

        if (disableFilters) {
            mapFiltersToUpdate(disableFilters, false);
            displayInput(disableFilters, false);
        }

        displaySection(this.styleKeyName, showStyleSection);

        showStyleSection && displayInput(this.getValidStyleFiltersByCategory(typeSelected));
    }

    private setCategoryStatus(typeSelected: string) {
        const { style } = getFilterValue(this.styleKeyName, this.filterKeysStatus);

        if (style === '') return;

        if (!typeSelected) {
            const type = Object.keys(this.styleKeysByCategory).find(category =>
                this.styleKeysByCategory[category].includes(style)
            );
            mapFiltersToUpdate([`filter-type-${type}`], true);
        }
    }

    private apply() {
        const { query } = getFilterValue(this.queryKeyName);

        if (isSearchByImageEnabled() || !query) return;

        const fieldName = this.filterKeyActivated.toString();

        const typeSelected =
            fieldName === this.typeKeyName
                ? this.optionItemActivated.getAttribute('value') || ''
                : getFilterValue(this.typeKeyName, this.filterKeysStatus)?.type;
        const isStyleOptionSelected = fieldName === this.styleKeyName;

        return isStyleOptionSelected
            ? this.setCategoryStatus(typeSelected)
            : this.setStyleStatus(typeSelected);
    }
}

export default ConditionGraphicStyles;
