export const replaceQuery = function(data: { [name: string]: any }, option: any, query: string) {
    data[option].reduce(function(acc: Record<string, any>[], item: { [name: string]: any }) {
        const search = query.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        const re = new RegExp('(' + search.split(' ').join('|') + ')', 'gi');

        if (item.text) {
            item.text = item.text.toLowerCase();

            if (item.text.match(re)) {
                item.value = item.text;
                item.text = item.text.replace(re, '<b>$1</b>');
            }
        }

        if (item.name) {
            item.name = item.name.toLowerCase();

            if (item.name.match(re)) {
                item.value = item.name;
                item.name = item.name.replace(re, '<b>$1</b>');
            }
        }

        acc.push(item);

        return acc;
    }, []);
};
