import { FilterKeys, FilterProperties, ValidFilterKey } from '../FilterInputs';
import { pixelScriptPinterest } from 'Library/pixels/pixel.pinterest';
import { q, qq, ww } from 'bobjoll/ts/library/dom';
import { sessionStorage as Storage } from 'bobjoll/ts/library/storage';
import { STORAGE_NAME_SAVE_LAST_FILTERS } from '../constants';
import FilterEvents from '../FilterEvents';
import getFilterIdList from '../getFilterIdList';
import getFilterValue from '../getFilterValue';
import Sponsor from 'Partials/sponsor';
import Tracker from 'Library/tracker';
import { regularizeExcludeTerms } from '../../advanced-search/modal/advancedsearch.utils';

class SearchSubmit {
    private static queryKeyName = 'query';
    private static authorFormSelector = 'search-author';
    private static inputQuerySearchInForm = 'input[name="query"]';
    private static filterKeysStatus: FilterKeys;
    private searchElement: HTMLElement[];
    private static inputSearch: HTMLElement;
    private static inputValue: string = '';
    private static isAuthorSearch = false;

    constructor({
        filterKeysStatus = ww.Filters,
        searchFormSelector = `.search, #${SearchSubmit.authorFormSelector}`,
    }: SearchConfig = {}) {
        SearchSubmit.filterKeysStatus = filterKeysStatus;
        this.searchElement = qq(searchFormSelector) as HTMLElement[];

        this.addEvent();
    }

    private static get queryFilter(): FilterProperties[] {
        return SearchSubmit.filterKeysStatus[SearchSubmit.queryKeyName];
    }

    private static get format() {
        const { format } = getFilterValue('format', SearchSubmit.filterKeysStatus) || 'search';
        return format;
    }

    private addEvent() {
        if (this.searchElement.length === 0) return;

        this.searchElement.forEach(search => {
            ['submit', 'submit-ac-enter', 'submit-ac-click'].forEach(eventType =>
                search.addEventListener(eventType, this.handleSubmit),
            );
        });
    }

    private handleSubmit(this: HTMLFormElement, event: Event) {
        event.preventDefault();

        const input = SearchSubmit.getInput(this);
        const isAuthorSearch = document.body.dataset.hbsTemplate === 'author';

        if (!input || (!SearchSubmit.inputValue && !isAuthorSearch)) return;

        if (input.checkValidity()) {
            SearchSubmit.validations();
        }
    }

    private static getInput(formElement: HTMLFormElement) {
        const queryKeyId = getFilterIdList(SearchSubmit.queryFilter)[0];
        if (!queryKeyId) return;

        SearchSubmit.isAuthorSearch = formElement.id === SearchSubmit.authorFormSelector;

        const inputQueryFilter = document.getElementById(queryKeyId) as HTMLInputElement;
        const currentInput = q(
            SearchSubmit.isAuthorSearch ? 'input' : SearchSubmit.inputQuerySearchInForm,
            formElement,
        ) as HTMLInputElement;
        const excludeTerms = SearchSubmit.getExcludeTerms();

        SearchSubmit.inputSearch = currentInput;
        SearchSubmit.inputValue = `${currentInput.value} ${excludeTerms}` || '';

        if (inputQueryFilter) inputQueryFilter.value = SearchSubmit.inputValue;

        return currentInput;
    }

    private static validations() {
        if (this.inputValue && !SearchSubmit.isAuthorSearch) {
            this.printSponsorPopup();
        }
        this.doSearch();
    }

    private static printSponsorPopup() {
        const random = Math.random();

        if (Sponsor.adobeModalTestABenabled) {
            random <= ADOBE_MODAL_PROBABILITIES[0] && sessionStorage.setItem('show-adobe-modal', 'search');
        } else {
            random <= Sponsor.randomSponsorPopup && Sponsor.popup(this.inputValue, 'search');
        }
    }

    private static doSearch() {
        Storage.removeItem(STORAGE_NAME_SAVE_LAST_FILTERS.namespace, STORAGE_NAME_SAVE_LAST_FILTERS.key);

        new FilterEvents({
            callback: this.tracker,
            filterKey: SearchSubmit.queryKeyName as ValidFilterKey,
            filterKeysStatus: SearchSubmit.filterKeysStatus,
            optionItem: SearchSubmit.inputSearch,
            newSearch: true,
        });
    }

    private static tracker(filterEnabled: Record<string, string[]>) {
        pixelScriptPinterest.addEvent('search', {
            search_query: filterEnabled.query,
        });

        if (SearchSubmit.format === 'collections') {
            Tracker.trackGAEventNoCache(['collections', 'search', filterEnabled.query]);
        }
    }

    private static getExcludeTerms() {
        const excludeTerms = q('.exclude_terms_advanced_search') as HTMLElement;
        if (!excludeTerms) {
            return '';
        }

        const excludeTermsSplitted = excludeTerms?.innerHTML.split(':');

        if (excludeTermsSplitted[1]) {
            return regularizeExcludeTerms(excludeTermsSplitted[1].trim());
        }

        return '';
    }
}

interface SearchConfig {
    filterKeysStatus?: FilterKeys;
    searchFormSelector?: string;
}

export type FilterFormat = 'search' | 'collections' | 'author';

export default SearchSubmit;
