import { q, qq } from 'bobjoll/ts/library/dom';
import { setResourcesInCollection } from 'Collections';
import { numberNormalizer } from 'Library/helpers';
import { AdobePopupOrigin } from 'Library/trackerSend/trackerSend.adobestock';
import { initializeAds } from 'Partials/adServer/adServer.helpers';
import { Advertisement } from 'Partials/advertisement';
import Sponsor from 'Partials/sponsor';
import { view as View, ViewPrint as Page, ViewPrint } from 'Partials/view';
import Cache from 'Project/ts/library/cache';

class StaticPage {
    searchField: HTMLElement;
    active: Page;

    constructor() {
        if (View.template.match(/static/)) {
            const searchField = q('#search-value') as HTMLInputElement;

            View.templates['static'] = {
                history: true,
                loader: false,
                print: false,
                push: true,
                pushTracker: true,
                delay: false,
                lock: false,
                metas: true,
                setMetas: this.setMetas.bind(this),
            };

            this.setData();

            setResourcesInCollection();

            if (searchField) {
                this.searchField = searchField;

                qq('.pagination').forEach(pagination => {
                    [...qq('button', pagination), ...qq('a', pagination)].forEach(button =>
                        button.addEventListener('click', () => {
                            const direction = button.className.indexOf('prev') >= 0 ? 'prev' : 'next';
                            Sponsor.popup(searchField.value, `page-button-${direction}` as AdobePopupOrigin);
                        }),
                    );
                    qq('input', pagination).forEach(input =>
                        input.addEventListener('change', () => Sponsor.popup(searchField.value, 'page-input')),
                    );
                });
            }

            setTimeout(() => Lazyload.run(), 50);

            if (document.body.classList.contains('page-new')) {
                initializeAds();
            }

            const showcases = Showcase.getElements(false, '#main');
            showcases.forEach((showcase: HTMLElement) => Advertisement.set(showcase));
        }
    }

    public setMetas(data: ViewPrint) {
        document.title = data.getPathValue('xhr.title');
    }

    setData() {
        const showcase = q('#main .showcase');
        const paginationPage = document.getElementById('pagination-page') as HTMLInputElement | undefined;
        const paginationPages = document.getElementById('pagination-pages') as HTMLElement | undefined;

        this.active = {
            views: ['static'],
            key: window.location.href,
            url: window.location.href,
            location: window.location.href,
        };

        if (this.active.views && this.active.key && this.active.url && this.active.location) {
            if (showcase && typeof resource_keywords !== 'undefined') {
                const xhr = {
                    data: {
                        keywords: resource_keywords,
                        resources: Showcase.getData(showcase, this.active.key),
                    },
                    meta: {},
                    paging: {
                        page: 0,
                        total: 0,
                        previous: '',
                        previous_params: '',
                        next: '',
                        next_params: '',
                    },
                };

                // Paging
                if (paginationPage && paginationPages) {
                    const paginationButtons = <HTMLLinkElement[]>qq('.pagination__button a');
                    const paginationPrev = paginationButtons[1] ? paginationButtons[0] : undefined;
                    const paginationNext = paginationButtons[1] || paginationButtons[0];

                    xhr.paging.page = numberNormalizer(paginationPage.value);
                    xhr.paging.total = numberNormalizer(paginationPages.innerText);

                    if (paginationPrev) {
                        xhr.paging.previous = paginationPrev.href;
                        xhr.paging.previous_params = paginationPrev.dataset.filter || paginationPrev.href;
                    }

                    if (paginationNext) {
                        xhr.paging.next = paginationNext.href;
                        xhr.paging.next_params = paginationNext.dataset.filter || paginationNext.href;
                    }
                }

                Cache.set({ key: this.active.key, value: xhr, time: 3600 });

                showcase.dataset.cacheKey = this.active.key;
            }
        }
    }
}

export const Static = new StaticPage();
