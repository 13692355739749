var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "spr--fixed-height";
},"3":function(container,depth0,helpers,partials,data) {
    return "hide";
},"5":function(container,depth0,helpers,partials,data) {
    return "spr--internal";
},"7":function(container,depth0,helpers,partials,data) {
    return "";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<div id=\""
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data,"loc":{"start":{"line":4,"column":37},"end":{"line":4,"column":43}}}) : helper)))
    + "\"></div>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function";

  return "<div class=\"spr "
    + ((stack1 = (helpers.isTrue||(depth0 && depth0.isTrue)||alias2).call(alias1,(depth0 != null ? depth0.fixedHeight : depth0),{"name":"isTrue","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":16},"end":{"line":1,"column":79}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = (helpers.isTrue||(depth0 && depth0.isTrue)||alias2).call(alias1,(depth0 != null ? depth0.outerHTML : depth0),{"name":"isTrue","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":80},"end":{"line":1,"column":125}}})) != null ? stack1 : "")
    + "\"\n	data-id=\""
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":10},"end":{"line":2,"column":16}}}) : helper)))
    + "\">\n	<div class=\"container-fluid alignc\">\n		"
    + ((stack1 = (helpers.isTrue||(depth0 && depth0.isTrue)||alias2).call(alias1,(depth0 != null ? depth0.danger : depth0),{"name":"isTrue","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":4,"column":62}}})) != null ? stack1 : "")
    + "\n		"
    + ((stack1 = ((helper = (helper = helpers.outerHTML || (depth0 != null ? depth0.outerHTML : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"outerHTML","hash":{},"data":data,"loc":{"start":{"line":5,"column":2},"end":{"line":5,"column":17}}}) : helper))) != null ? stack1 : "")
    + "\n	</div>\n</div>";
},"useData":true});