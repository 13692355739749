export const SEARCH_URL = `${BASE_URL}search`;
export const MODEL_SEARCH_URL = `${BASE_URL}3d-model/search`;
export const STORAGE_NAME_FOR_URL_MOBILE = {
    key: 'apply-url-search',
    namespace: 'filters',
};
export const STORAGE_NAME_SAVE_LAST_FILTERS = {
    key: 'last-view',
    namespace: 'filters',
};
export const GET_REAL_KEYS_FROM_DROPDOWN_SORT_EXCEPTION = {
    sort: {
        popular: 'sort',
        recent: 'sort',
        '3m': 'dates',
        '6m': 'dates',
        y: 'dates',
    }
};
export const QUERY_PARAMS_EXCEPTIONS_FOR_LAST_FILTER_CLICKED = {
    license: {
        free: {
            key: 'selection',
            value: '1',
        },
        premium: {
            key: 'premium',
            value: '1',
        },
    },
    dates: {
        any: {
            key: '',
            value: '',
        },
    },
    demographic: {
        'any-people': {
            key: '',
            value: '',
        },
    },
};
export const QUERY_PARAMS_EXCEPTIONS = {
    license: {
        free: {
            key: 'selection',
            value: '1',
        },
        premium: {
            key: 'premium',
            value: '1',
        },
    },
    sort: {
        popular: {
            key: '',
            value: '',
        },
    },
    dates: {
        any: {
            key: '',
            value: '',
        },
    },
    demographic: {
        'any-people': {
            key: '',
            value: '',
        },
    },
    page: {
        '1': {
            key: '',
            value: '',
        },
    },
};
