import * as GA4Model from '../ga4.model';

const AUTOMATIC_GA4_EVENTS: GA4Model.GA4AutomaticEvents = {
    topMenuPrices: {
        applyClickEvent: true,
        applyOnloadEvent: true,
        element: '#go-premium-menu-link',
        microfunnel: {
            label: 'prices',
        },
        ga4Options: {
            location: 'header_menu',
            action: 'prices',
        },
        replaceCampaignParams: true,
    },
    footerPricing: {
        applyClickEvent: true,
        applyOnloadEvent: true,
        element: '#footer-plans-pricing-link',
        microfunnel: {
            label: 'pricefooter',
        },
        ga4Options: {
            location: 'footer_menu',
            action: 'prices',
        },
        replaceCampaignParams: true,
    },
    bannerAutopromoHeaderRegistered: {
        applyClickEvent: true,
        applyOnloadEvent: true,
        element: '[data-microfunnel="home"]',
        microfunnel: {
            label: 'autopromo',
        },
        ga4Options: {
            location: 'header_logged_home',
            action: 'go_premium',
        },
        replaceCampaignParams: true,
    },
    bannerAutopromoFilterByPremium: {
        applyClickEvent: true,
        applyOnloadEvent: true,
        element: '[data-microfunnel="filter-premium"]',
        microfunnel: {
            label: 'autopromo',
        },
        ga4Options: {
            location: 'header_search_results',
            action: 'go_premium',
        },
        replaceCampaignParams: true,
    },
    downloadLimitViewGoPremium: {
        applyClickEvent: true,
        applyOnloadEvent: true,
        element: '[data-microfunnel="download-limit"]',
        microfunnel: {
            label: 'downlimit',
        },
        ga4Options: {
            location: 'landing_download_limit',
            action: 'go_premium_registered',
            campaign_name: 'no_campaign',
            discount_type: 30,
            product_price: 'discount',
        },
    },
    modalAttributionBanner: {
        applyClickEvent: true,
        applyOnloadEvent: false,
        element: '[data-microfunnel="how-attribute"]',
        microfunnel: {
            label: 'howatrib-gopremium',
        },
        ga4Options: {
            location: 'banner_how_attribute',
            action: 'attribution_go_premium',
        },
        replaceCampaignParams: true,
    },
    modalAttributionBannerAfterDownload: {
        applyClickEvent: true,
        applyOnloadEvent: false,
        element: '[data-microfunnel="attribution"]',
        microfunnel: {
            label: 'attribution-gopremium',
        },
        ga4Options: {
            location: 'banner_attribute',
            action: 'attribution_go_premium',
        },
        replaceCampaignParams: true,
    },
    landingPaidPremiumButton: {
        applyClickEvent: true,
        element: '#go-premium-landing-paid',
        ga4Options: {
            location: 'landing_sem',
            action: 'go_premium',
        },
        replaceCampaignParams: true,
    },
    mockupBakerDownloadMacFromLanding: {
        applyClickEvent: true,
        applyOnloadEvent: false,
        element: '.download-mockup-baker-plugin-mac',
        ga4Options: {
            event_name: 'plugin_download',
            type: 'mac',
        },
        ignoreDefaultParams: true,
    },
    mockupBakerDownloadWindowsFromLanding: {
        applyClickEvent: true,
        applyOnloadEvent: false,
        element: '.download-mockup-baker-plugin-windows',
        ga4Options: {
            event_name: 'plugin_download',
            type: 'windows',
        },
        ignoreDefaultParams: true,
    },
    authenticPhotosCategoryLanding: {
        applyOnloadEvent: true,
        element: '.category-landing--authentic-photos',
        ga4Options: {
            event_name: 'page_view',
            page_location: window.location.href,
            content_group: 'landing',
            content_group2: 'na',
            content_type: 'authentic_photos',
            country: USER_COUNTRY_CODE,
            page_code: 200,
            page_lang: LANGUAGE,
            user_id: gr?.user?.id || '',
            user_type: gr?.user?.user_type_fr || 'anonymous',
        },
        ignoreDefaultParams: true,
    },
};

export default AUTOMATIC_GA4_EVENTS;
