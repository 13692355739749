import { qq } from 'bobjoll/ts/library/dom';
import delay from 'Project/ts/library/delay';
import { visibleScroll } from 'Project/ts/library/helpers';

type SliderScrollDirections = 'prev' | 'next';

export default class SliderScroll {
    private wrapper: HTMLElement;
    private children: HTMLElement[];
    private history: number[] = [];
    public active: boolean;

    constructor(element: HTMLElement, options: SliderScrollOptions) {
        this.wrapper = element;
        this.children = qq(options.slideSelector, element);
        this.active = visibleScroll(this.wrapper).horizontal;
        if (this.active) {
            this.wrapper.style.overflow = 'hidden';
            this.wrapper.classList.add('start');
        } else {
            this.wrapper.classList.add('carousel--invisible');
        }
    }

    public navigate(direction: SliderScrollDirections, callback?: Function) {
        let scrollTo = this.wrapper.scrollLeft;
        if ('next' === direction) {
            if (this.history.indexOf(this.wrapper.scrollLeft) < 0) {
                this.history.push(this.wrapper.scrollLeft);
            }
            const next = this.getNextPosition();
            scrollTo = next;
        }
        if ('prev' === direction) {
            scrollTo = this.history.pop() || 0;
            this.wrapper.classList.remove('end');
        }
        const speed = 150;
        this.scrollTo(scrollTo, speed);

        if (callback) {
            setTimeout(callback, speed);
        }

        setTimeout(() => this.updateState(), speed + 50);
    }

    private getNextPosition() {
        const { left: wrapperLeft, width: wrapperWidth } = this.wrapper.getBoundingClientRect();
        const scrollTo = this.children.reduce((position: number, element: HTMLElement) => {
            const { left: elementLeft, width: elementWidth } = element.getBoundingClientRect();
            if (elementLeft - wrapperLeft + elementWidth > wrapperWidth && !position) {
                position = elementLeft - wrapperLeft;
            }
            return position;
        }, 0);
        return scrollTo + this.wrapper.scrollLeft;
    }

    private async scrollTo(to: number, duration: number) {
        const difference: number = to - this.wrapper.scrollLeft;
        const perTick: number = (difference / duration) * 10;
        while (duration > 0) {
            await delay(10);
            this.wrapper.scrollLeft = this.wrapper.scrollLeft + perTick;
            duration -= 10;
        }
    }

    private updateState() {
        const scrollWidth = this.wrapper.scrollLeft + this.wrapper.clientWidth;
        this.wrapper.classList[scrollWidth === this.wrapper.scrollWidth ? 'add' : 'remove']('end');
        this.wrapper.classList[this.wrapper.scrollLeft == 0 ? 'add' : 'remove']('start');
    }
}

interface SliderScrollOptions {
    slideSelector: string;
}
