import { q } from 'bobjoll/ts/library/dom';
import { KEvent, KEventTarget } from 'bobjoll/ts/library/event';
import extend from 'bobjoll/ts/library/extend';
import { sendHistorySearchEventGA4 } from 'Partials/events/ga4.searches';
import { Search } from 'Partials/search/controller';
import { deleteAllUserSearches } from 'Partials/searchBar/searchBar.searchHistory';
import Tracker from 'Project/ts/library/tracker';
import { clearTimeout } from 'timers';

interface AutocompleteSourceFunction {
    (query: string): { [name: string]: any };
}

interface TemplateModule {
    default: HandlebarsTemplateDelegate;
}

interface AutocompleteInputElement extends HTMLInputElement {
    timer: any;
    keydownHandler: EventListener;
    keyupHandler: EventListener;
    focusHandler: EventListener;
    blurHandler: EventListener;
    container: HTMLElement;
    results?: HTMLElement[];
}

interface AutocompleteOptions {
    delay?: number;
    fields: HTMLInputElement | HTMLInputElement[];
    minChars?: number;
    source: string[] | AutocompleteSourceFunction;
    templateList?: () => Promise<TemplateModule>;
    templateWrapper?: Function;
}

interface AutocompleteSettings {
    delay: number;
    fields: HTMLInputElement | NodeListOf<HTMLInputElement>;
    minChars: number;
    source: string[] | AutocompleteSourceFunction;
    templateList: () => Promise<TemplateModule>;
    templateWrapper: Function;
}

export class Autocomplete extends KEventTarget {
    private cache: { query: string; value: any }[] = [];
    private cancelled = false;
    private complete = false;
    private fields: AutocompleteInputElement[];
    private index = -1;
    private settings: AutocompleteSettings;
    private sourceResponse: any;
    private timeout: any;
    private value = '';

    constructor(options: AutocompleteOptions) {
        super();
        const template = import('Partials/searchBar/searchBar.autocomplete.list.hbs');

        const initialize = async () => {
            const { default: templateWrapper } = await import('Partials/searchBar/searchBar.autocomplete.hbs');
            this.settings = extend(
                {
                    delay: 400,
                    minChars: 0,
                    templateWrapper,
                    templateList: () => template,
                },
                options,
            );
            this.fields = Array.prototype.slice.call(this.settings.fields);
            this.init();
        };

        initialize();
    }

    private init() {
        this.render();
        this.addEventListeners();
    }

    public hide() {
        clearTimeout(this.timeout);
        setTimeout(() => {
            this.fields.forEach(field => {
                delete field.results;
                field.container.innerHTML = '';
                field.container.classList.remove('autocomplete--show');
            });
        }, 100);
    }

    public cancel() {
        this.cancelled = true;
        this.hide();
    }

    private render() {
        this.fields.forEach(field => {
            field.insertAdjacentHTML('afterend', this.settings.templateWrapper());

            field.parentElement!.style.position = 'relative';

            field.container = field.nextSibling as HTMLElement;
            field.container.style.top = '100%';
            field.container.style.left = '0';
        });
    }

    private addEventListeners() {
        const self = this;

        this.fields.forEach(field => {
            /**
             * Keydown handler
             */
            field.keydownHandler = function(e: KeyboardEvent) {
                const form = field.closest('form');
                const key = window.event ? e.keyCode : e.which;

                try {
                    if (field.results && (38 == key || 40 == key) && self.index < field.results.length) {
                        e.preventDefault();

                        if (field.results[self.index]) {
                            field.results[self.index].classList.remove('selected');
                        }

                        if (40 === key) {
                            //down
                            self.index++;
                        }

                        if (38 === key) {
                            //up
                            self.index--;
                        }

                        if (field.results.length <= self.index) {
                            self.index = 0;
                        }

                        if (0 > self.index) {
                            self.index = field.results.length - 1;
                        }

                        if (field.results[self.index]) {
                            field.results[self.index].classList.add('selected');
                        }
                    }

                    if (27 === key) {
                        //esc
                        self.cancelled = true;

                        self.hide();
                    }

                    if (13 === key) {
                        //enter
                        e.preventDefault();
                        e.stopPropagation();

                        setTimeout(() => {
                            self.cancelled = true;

                            const selected = field.results ? field.results[self.index] || undefined : undefined;

                            if (selected) {
                                const value = selected.dataset.value;

                                if (value) {
                                    this.value = self.value = value;
                                }

                                self.dispatchEvent(
                                    new KEventSubmit(
                                        self.fields as HTMLInputElement[],
                                        selected.innerText,
                                        this.value,
                                        self.sourceResponse,
                                    ),
                                );

                                if (self.index >= 0) {
                                    const listPosition = self.index + 1;
                                    self.complete = true;
                                    Tracker.autocomplete('enter', listPosition, this.value);
                                }
                            }

                            self.hide();

                            if (form) {
                                let isAuthor = false;

                                if (selected) {
                                    isAuthor = 'author' === selected.dataset.format;
                                }

                                if (!isAuthor) {
                                    form.dispatchEvent(
                                        selected
                                            ? new Event('submit-ac-enter')
                                            : new Event('submit', { cancelable: true }),
                                    );
                                } else {
                                    const link = q('a', selected) as HTMLLinkElement;
                                    if (link) {
                                        self.complete = true;
                                        link.addEventListener('click', Autocomplete.eventHandlerClickSearchLink);
                                        link.dispatchEvent(new Event('click'));
                                    }
                                }
                            }
                        }, 50);
                    }
                } catch (err) {}
            };

            /**
             * Keyup handler
             */
            field.keyupHandler = function(e: KeyboardEvent) {
                if (this.value !== self.value) {
                    self.cancelled = false;

                    const key = window.event ? e.keyCode : e.which;

                    self.hide();

                    if (!key || ((key < 35 || key > 40) && key != 13 && key != 27)) {
                        const value = (self.value = this.value);

                        if (value.length >= self.settings.minChars) {
                            if ('number' === typeof this.timer) {
                                window.clearTimeout(this.timer);
                                delete this.timer;
                            }

                            this.timer = setTimeout(() => self.source(field, value), self.settings.delay);
                        }
                    }
                } else {
                    clearTimeout(self.timeout);
                }
            };

            /**
             * Blur handler
             */
            field.blurHandler = function() {
                field.removeEventListener('keydown', field.keydownHandler);
                field.removeEventListener('keyup', field.keyupHandler);
                field.removeEventListener('blur', field.blurHandler);
                setTimeout(() => self.hide(), 100);
            };

            /**
             * Focus hanlder
             */
            field.focusHandler = function() {
                self.value = this.value;

                field.addEventListener('keydown', field.keydownHandler);
                field.addEventListener('keyup', field.keyupHandler);
                field.addEventListener('blur', field.blurHandler);
            };
            field.addEventListener('focusin', field.focusHandler);
        });
    }

    private async source(field: AutocompleteInputElement, query: string) {
        let source: { [name: string]: any } | undefined;
        const self = this;
        const cache = this.cache
            .filter(object => {
                return query === object.query;
            })
            .pop();

        if (cache) {
            source = cache.value;
        } else {
            if ('function' === typeof this.settings.source) {
                source = await this.settings.source(query);
            }

            if (Array.isArray(this.settings.source)) {
                const re = new RegExp(query, 'i');

                source = this.settings.source.reduce(
                    function(acc: { [name: string]: any }, item: string) {
                        if (item.match(re)) {
                            acc.keywords.text = item;
                        }

                        return acc;
                    },
                    { keywords: {}, authors: {} },
                );
            }
        }

        this.sourceResponse = source;

        if (source && Object.keys(source).length && !this.cancelled) {
            if (!cache) {
                this.cache.unshift({ query: query, value: source });
            }

            if (10 < this.cache.length) {
                this.cache.pop();
            }

            if (source && source.authors) {
                if (
                    source &&
                    source.keywords &&
                    0 === source.keywords.length &&
                    source.authors &&
                    0 === source.authors.length
                ) {
                    return;
                }
            } else {
                if (source && source.keywords && 0 === source.keywords.length) {
                    return;
                }
            }

            this.settings.templateList().then(({ default: template }) => {
                field.container.innerHTML = template({
                    source,
                    AUTHOR_URL,
                });

                typeof Lazyload !== 'undefined' && Lazyload.run(field.container);

                this.index = -1;

                field.results = Array.prototype.slice.call(
                    field.container.getElementsByClassName('autocomplete__item'),
                ) as HTMLElement[];

                field.results.forEach((item, indexItem) => {
                    item.addEventListener('mousedown', () => {
                        const link = q('a', item) as HTMLLinkElement;
                        const form = field.closest('form');
                        self.dispatchEvent(
                            new KEventSubmit(
                                self.fields as HTMLInputElement[],
                                item.innerText,
                                item.dataset.value || '',
                                self.sourceResponse,
                            ),
                        );
                        field.value = this.value = item.dataset.value || item.innerText;
                        if (form) {
                            const isAuthor = 'author' === item.dataset.format;
                            if (!isAuthor) {
                                form.dispatchEvent(new Event('submit-ac-click'));
                            } else {
                                if (link) {
                                    link.addEventListener('click', Autocomplete.eventHandlerClickSearchLink);
                                    link.dispatchEvent(new Event('click'));
                                }
                            }
                            if (indexItem >= 0) {
                                const listPosition = indexItem + 1;
                                const isHistory = item.dataset.history === '1' ? true : false;
                                self.complete = true;
                                Tracker.autocomplete('click', listPosition, field.value, isHistory);
                            }
                        }
                    });
                });
                this.clearSearchHistoryEventHandler();
                sendHistorySearchEventGA4();
                field.container.classList.add('autocomplete--show');
            });
        } else {
            field.container.classList.remove('autocomplete--show');
        }
    }

    private static eventHandlerClickSearchLink(this: HTMLLinkElement, event: Event) {
        event.preventDefault();

        const params = this.dataset.filter;
        const location = this.href;

        if (params) {
            Search.new(location, params);
        }
    }

    public clearSearchHistoryEventHandler() {
        q('#clearlink')?.addEventListener('click', () => {
            deleteAllUserSearches();
            this.cache = [];
            this.hide();
        });
    }

    public itemSelected() {
        const selected = this.complete;
        this.complete = false;
        return selected;
    }

    public addEventListener(t: 'autocomplete:submit', listener: (ev: KEventSubmit) => any, useCapture?: boolean): void;
    public addEventListener(t: string, listener: (ev: KEvent) => any, useCapture = true): void {
        super.addEventListener(t, listener, useCapture);
    }
}

class KEventSubmit extends KEvent {
    constructor(inputs: HTMLInputElement[], name: string, value: string, source: any) {
        super();
        this.type = `autocomplete:submit`;
        this.extra = {
            inputs,
            name,
            source,
            value,
        };
    }
}
