import { sessionStorage } from 'bobjoll/ts/library/storage';
import { isTablet } from 'Project/ts/library/helpers/device';

import { STORAGE_NAME_FOR_URL_MOBILE } from '../constants';

const redirectToUrl = (url: string, newSearch = false) => {
    sessionStorage.setItem(STORAGE_NAME_FOR_URL_MOBILE.namespace, STORAGE_NAME_FOR_URL_MOBILE.key, url);
    if (!isTablet({ 
        checkIOS: true,
        screenWidth: true,
    }) || newSearch) window.location.href = url;
};

export default redirectToUrl;
