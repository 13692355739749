import { ww } from 'bobjoll/ts/library/dom';

import { getImpData } from './abandonedCart.api';
import { getImpEvents, showImpCoupon } from './abandonedCart.helpers';

(ww.AbandonedCartMiddleware as Partial<AbandonedCartMiddleware>[]) = [...(ww.AbandonedCartMiddleware || [])];

export const globalMiddleware: AbandonedCartMiddleware = {
    getData: retval => {
        let r = retval;
        for (const m of ww.AbandonedCartMiddleware) {
            if (m.getData) {
                r = m.getData(r);
            }
        }
        return r;
    },
    getEvents: retval => {
        let r = retval;
        for (const m of ww.AbandonedCartMiddleware) {
            if (m.getEvents) {
                r = m.getEvents(r);
            }
        }
        return r;
    },
    showCoupon: retval => {
        let r = retval;
        for (const m of ww.AbandonedCartMiddleware) {
            if (m.showCoupon) {
                r = m.showCoupon(r);
            }
        }
        return r;
    },
    postRender: couponElement => {
        for (const m of ww.AbandonedCartMiddleware) {
            if (m.postRender) {
                m.postRender(couponElement);
            }
        }
    },
};

interface AbandonedCartMiddleware {
    getData: (value: ReturnType<typeof getImpData>) => ReturnType<typeof getImpData>;
    getEvents: (value: ReturnType<typeof getImpEvents>) => ReturnType<typeof getImpEvents>;
    showCoupon: (value: ReturnType<typeof showImpCoupon>) => ReturnType<typeof showImpCoupon>;
    postRender: (coupon: HTMLElement) => void;
}
