import 'Variables';

import { view as View } from 'Partials/view';

import { Search } from './search/controller';

View.addView({
    name: 'showcase',
    groups: ['favorites'],
    template: {
        history: true,
        template: () => import('Project/ts/templates/parts/home-accordion-showcase.hbs'),
        insert: {
            method: 'innerHTML',
            element: '',
        },
        loader: true,
        print: true,
        push: false,
        pushTracker: true,
        delay: false,
        lock: true,
        metas: false,
        getXHR: Search.getData,
    },
});

function updateAccordionInsertElement(selector: string) {
    const templateSettings = View.templates['showcase'];

    if (templateSettings && templateSettings.insert) {
        templateSettings.insert.element = selector;
    }
}

export function eventHandlerHomeAccordionClickHandler(type: string, location: string, selector: string) {
    if ('' === type && '' === location) return;

    updateAccordionInsertElement(selector);

    View.newPage({
        location: location,
        url: `/xhr/search?sort=popular&type=${type}&format=popular&selection=1&page=1`,
        views: ['showcase'],
        global: {
            location: location,
        },
    });
}

window.addEventListener('printshowcase', function() {
    const showcases = Showcase.getElements(false, '#main');
    Showcase.setLayout({ showcases: showcases });
    setTimeout(() => Lazyload.run(), 50);
});
