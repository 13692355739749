import { QUERY_PARAMS_EXCEPTIONS } from '../constants';
import { FilterKeys, FilterProperties } from '../FilterInputs';

const getEnabledFilters = (filterKeysStatus?: FilterKeys, updateValue = false) => {
    const enabledFilters: Record<string, string[]> = {};

    if (!filterKeysStatus) return enabledFilters;

    Object.keys(filterKeysStatus).forEach((filterKey: string) => {
        enabledFilters[filterKey] = [];

        const optionKeyNames = Object.keys(filterKeysStatus[filterKey]);
        optionKeyNames.forEach((optionKeyName: string) => {
            const optionItemProperties = filterKeysStatus[filterKey][optionKeyName] as FilterProperties;

            if (optionItemProperties) {
                if (updateValue) {
                    optionItemProperties.checked = optionItemProperties.element?.checked || false;
                    if (optionItemProperties.isTextType)
                        optionItemProperties.value = optionItemProperties.element?.value || '';
                }

                const optionFromSelectSelected =
                    optionItemProperties.elementSelect && optionItemProperties.elementSelect.selected;
                const addFilterChecked = optionFromSelectSelected || filterIsChecked(optionItemProperties);

                if (addFilterChecked) {
                    addEnableFilterToArray({ enabledFilters, filterKey, optionItemProperties });
                }
            }
        });

        if (enabledFilters[filterKey].length === 0) delete enabledFilters[filterKey];
    });

    return enabledFilters;
};

const filterIsChecked = (optionItem: FilterProperties) => {
    return (
        (optionItem.element?.checked || (optionItem.isTextType && optionItem.value !== '')) && !optionItem.isAllButton
    );
};

const addEnableFilterToArray = (options: FilterEventActivated) => {
    const { filterKey, enabledFilters, optionItemProperties } = options;

    if (QUERY_PARAMS_EXCEPTIONS[filterKey] && QUERY_PARAMS_EXCEPTIONS[filterKey][optionItemProperties.value]) {
        setFilterExceptions(options);
        return;
    }

    enabledFilters[filterKey].push(optionItemProperties.value);
};

const setFilterExceptions = (options: FilterEventActivated) => {
    const { filterKey, enabledFilters, optionItemProperties } = options;

    Object.keys(QUERY_PARAMS_EXCEPTIONS[filterKey]).forEach((param: string) => {
        if (optionItemProperties.value === param) {
            const { key, value } = QUERY_PARAMS_EXCEPTIONS[filterKey][param];

            if (key) enabledFilters[key] = [value];
        }
    });
};

interface FilterEventActivated {
    filterKey: string;
    enabledFilters: Record<string, string[]>;
    optionItemProperties: FilterProperties;
}

export default getEnabledFilters;
