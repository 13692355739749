import { ww } from 'bobjoll/ts/library/dom';
import { FilterInputs } from 'Partials/filters-v2';
import getEnabledFilters from 'Partials/filters-v2/getEnabledFilters';
import getSearchURL from 'Partials/filters-v2/getSearchURL';
import { addQueryArgumentsToResources } from 'Partials/search/events';
import { view } from 'Partials/view';

ww.Filters = new FilterInputs().initialiceFilters();
ww.FiltersEnabled = getEnabledFilters(ww.Filters);

(async () => {
    const eventFilterInitialized = new CustomEvent('filter:initialized');
    const FilterComponent = await import('Partials/filters-v2');
    new FilterComponent.SearchSubmit();
    new FilterComponent.FilterTabCounters();
    new FilterComponent.FilterApplyMobile();
    new FilterComponent.FilterSortBy();
    new FilterComponent.FilterCollapse();
    new FilterComponent.SearchDropdown();
    new FilterComponent.SearchPagination();
    new FilterComponent.FilterRemoveTag();
    new FilterComponent.SearchVerticalLink();

    ww.FiltersSearchURL = getSearchURL(ww.FiltersEnabled);
    addQueryArgumentsToResources(ww.FiltersEnabled);

    await Promise.all([import('Partials/search'), import('Project/ts/ui/search-dropdown')]);

    const viewPages = Array.from(new Set([...view.getPages(), 'landing'])).filter(
        viewType => !viewType.match(/search|keyword|author|category/gi),
    );
    const viewType = document.body.dataset.hbsTemplate || '';

    if ((viewPages.indexOf(viewType) >= 0 || viewType === 'static') && viewType !== 'detail') {
        const print = new CustomEvent(`print${viewType}`, {
            detail: {
                delay: false,
                history: false,
                insert: false,
                loader: false,
                lock: false,
                prePrint: false,
                print: false,
                push: false,
                views: [view],
            },
        });

        window.dispatchEvent(print);
    }

    window.dispatchEvent(eventFilterInitialized);

    if (ww.FiltersEnabled?.ai && FEATURE_FILTER_AI) {
        (async () => {
            const hotjarSurveyName = ww.FiltersEnabled.ai[0] == 'only' ? 'onlyAIgenerated' : 'excludeAIgenerated';
            const { hotjarAIFilter } = await import('Partials/hotjar/hotjar.ai-filter');
            hotjarAIFilter(hotjarSurveyName);
        })();
    }

    if (FEATURE_IMAGE_SEARCH_BY_COUNTRY) {
        const { searchByImageInitialize } = await import('Partials/search-by-image/searchimage.initialize');
        const { searchSimilarInitialize } = await import('Partials/search-similar/searchsimilar.initialize');

        searchByImageInitialize();
        searchSimilarInitialize();
    }

    if (FEATURE_ADVANCED_SEARCH_BY_LANGUAGE) {
        const { advancedSearchInitialize } = await import('Partials/advanced-search/advancedsearch.initialize');
        advancedSearchInitialize();
    }
})();
